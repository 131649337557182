import { Box, Typography } from '@mui/material';
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { forceLogout } from "../../redux/actions/users";

import {
    withRouter
} from "react-router-dom";

// import backgroundLeft from '../assets/velizar-ivanov-xZjiUR7SWIQ-unsplash.jpg';
// import backgroundLeft from '../assets/annie-spratt-dWYU3i-mqEo-unsplash.jpg';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // account_type:0,
            // mobileMenu:false,
            // logoIcon:'',
            err: null
        };

        // this.onLogout = this.onLogout.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // onLogout(params) {
    //     console.log('onLogout');
    //     if(this.props.forceLogout().status === 200){
    //         this.props.history.push("/");
    //     }
    // }

    // toggleMenu(params){

    // }

    componentDidMount() {
        console.log('Landing');
    }

    render() {
        return (
            <Box sx={{ display: "flex", backgroundColor: "#c8ffc4" }}>
                <Box>
                    <Typography variant="h3">About</Typography>
                    <Typography variant="body1">
                        About Hasler                        
                    </Typography>
                </Box>
            </Box >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // forceLogout: data => dispatch(forceLogout(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(About));