import { Box, Typography } from '@mui/material';
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { forceLogout } from "../../redux/actions/users";

import {
    withRouter
} from "react-router-dom";

// import backgroundLeft from '../assets/velizar-ivanov-xZjiUR7SWIQ-unsplash.jpg';
// import backgroundLeft from '../assets/annie-spratt-dWYU3i-mqEo-unsplash.jpg';

class TermsAndConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // account_type:0,
            // mobileMenu:false,
            // logoIcon:'',
            err: null
        };

        // this.onLogout = this.onLogout.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // onLogout(params) {
    //     console.log('onLogout');
    //     if(this.props.forceLogout().status === 200){
    //         this.props.history.push("/");
    //     }
    // }

    // toggleMenu(params){

    // }

    componentDidMount() {
        console.log('Landing');
    }

    render() {
        return (
            <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
                    <Typography variant="h4" sx={{marginTop:"20px", xs: { fontSize: "14px" }}}>Terms and Conditions</Typography>
                    <Typography variant="body1" sx={{ marginTop: "20px" }}>
                        Hasler
                        <br /><br />
                        These Terms and Conditions ("Agreement") constitute a legal agreement between you ("User," "you," or "your") and Hasler Project Management Tools ("Hasler," "we," "us," or "our") regarding your use of the Hasler ("Service"). By accessing or using the Service, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, please refrain from using the Service.
                        <br /><br />
                        **1. Acceptable Use**
                        <br /><br />
                        1.1 You agree to use the Service in compliance with all applicable laws and regulations.
                        <br /><br />
                        1.2 You are responsible for maintaining the security and confidentiality of your account information, including login credentials. Any actions taken using your account will be deemed your responsibility.
                        <br /><br />
                        1.3 You shall not use the Service to engage in any malicious, fraudulent, or harmful activities, including but not limited to hacking, distributing malware, or any other unauthorized activities.
                        <br /><br />
                        **2. Subscription and Payment**
                        <br /><br />
                        2.1 The use of the Service requires a subscription fee, the details of which are outlined on our website. By subscribing, you agree to pay the specified fees according to the chosen billing cycle.
                        <br /><br />
                        2.2 Subscription fees are non-refundable. Hasler does not provide refunds or credits for any partial months or unused periods of service.
                        <br /><br />
                        2.3 Hasler reserves the right to modify subscription fees upon providing notice to users at least 30 days prior to any such change.
                        <br /><br />
                        2.4 Failure to pay subscription fees may result in suspension or termination of your account.
                        <br /><br />
                        **3. Intellectual Property**
                        <br /><br />
                        3.1 All intellectual property rights, including copyrights, trademarks, and patents, related to the Service and its content are owned by Hasler or its licensors.
                        <br /><br />
                        3.2 You are granted a non-exclusive, non-transferable, revocable license to use the Service solely for its intended purpose during the term of your subscription.
                        <br /><br />
                        3.3 You shall not modify, reproduce, distribute, create derivative works, reverse engineer, or attempt to extract the source code of the Service.
                        <br /><br />
                        **4. Data Privacy**
                        <br /><br />
                        4.1 Hasler collects and processes personal information in accordance with its Privacy Policy. By using the Service, you consent to the collection and processing of your data as described in the Privacy Policy.
                        <br /><br />
                        4.2 You are responsible for ensuring that any data you upload or store on the Service complies with applicable data protection laws. Hasler shall not be liable for any data breaches or non-compliance with data protection laws resulting from your use of the Service.
                        <br /><br />
                        **5. Termination**
                        <br /><br />
                        5.1 Either party may terminate this Agreement for any reason by providing written notice to the other party.
                        <br /><br />
                        5.2 Hasler reserves the right to suspend or terminate your access to the Service without notice if you violate any of these Terms and Conditions.
                        <br /><br />
                        5.3 Upon termination, your access to the Service will be revoked, and you shall cease using the Service and any related materials.
                        <br /><br />
                        **6. Disclaimer of Warranties**
                        <br /><br />
                        6.1 The Service is provided "as is" without any warranties, express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
                        <br /><br />
                        **7. Limitation of Liability**
                        <br /><br />
                        7.1 To the maximum extent permitted by law, Hasler shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the use of the Service.
                        <br /><br />
                        **8. Governing Law and Dispute Resolution**
                        <br /><br />
                        8.1 This Agreement shall be governed by and construed in accordance with the laws of Indonesia. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in Indonesia.
                        <br /><br />
                        **9. Modifications**
                        <br /><br />
                        9.1 Hasler reserves the right to modify these Terms and Conditions at any time. Changes will be effective upon posting on the Hasler website or notifying users through the Service. Your continued use of the Service after such changes constitutes your acceptance of the modified terms.
                        <br /><br />
                        By using the Hasler, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use the Service.
                        <br /><br />
                        Last Updated: 1 August 2023
                        <br /><br />
                        For any inquiries regarding these Terms and Conditions, please contact us <a href="mailto:dev@dengansenanghati.com" alt="contact mail">here</a>.
                    </Typography>
                </Box>
            </Box >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // forceLogout: data => dispatch(forceLogout(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions));