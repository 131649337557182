import React, { Component } from 'react';
import { connect } from "react-redux";

import {
    //Link,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// import { 
//     InputLabel,
//     Stack,
//     TextField 
// } from '@mui/material';

class RequestOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: null
        };

        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // toggleMenu(params){ }

    // componentDidMount() {
    //     console.log('RequestOrganization mounted');
    // }

    render() {
        return (
            <Modal
                open={this.props.openModalRequest}
                onClose={this.props.handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal" sx={{}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Join Request
                    </Typography>
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestOrganization));