import React, { Component } from 'react';
import { connect } from "react-redux";

import { getAddress } from "../../../redux/actions/organizations";

import {
    NavLink,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

class ListProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
            err: null
        };
    }

    componentDidMount() {
        this.props.getAddress({ idorganization: this.props.organizationData.id }).then((res) => {
            console.log(res);
            if (res.code === 200) {
                this.setState({ addresses: res.data });
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    render() {
        return (
            <Box className="products-container" sx={{ padding: "10px" }}>
                <Box sx={{ marginTop: "10px", marginBottom: "20px" }}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className='title' sx={{ fontSize: "18px", fontWeight: "800" }}>Organization Addresses</Typography>
                        </Grid>
                        {this.state.addresses.length === 0 ?
                            <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Typography className='title' sx={{ color: "#000 !important", fontSize: "18px", fontWeight: "800" }} component={NavLink} to={'./add-address'}>Add Address</Typography>
                            </Grid> : ""}
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        {this.state.addresses.map((item) => {
                            return (
                                <Grid item xs={12} key={item.id}>
                                    <Paper>
                                        <Box sx={{ padding: "5px", minHeight: "120px" }}>
                                            <Box>
                                                <Typography sx={{ marginTop: "5px", marginBottom: "5px", display: "block", color: "#121212" }}>{item.name}</Typography>
                                                <Typography sx={{ marginTop: "5px", marginBottom: "5px", display: "block", color: "#121212" }}>{item.recipient_name} {item.phone}</Typography>
                                            </Box>
                                            <>
                                                <Box sx={{ minHeight: "50px" }}>
                                                    <Typography sx={{ fontSize: "14px" }}>{item.address}</Typography>
                                                    <Typography sx={{ fontSize: "14px" }}>{item.suburb}, {item.area}</Typography>
                                                    <Typography sx={{ fontSize: "14px" }}>{item.city}, {item.province}</Typography>
                                                    <Typography sx={{ fontSize: "14px" }}>{item.postalcode}</Typography>
                                                </Box>
                                                <Button variant="contained" sx={{ marginTop: "10px", marginBottom: "5px" }}>Edit Address</Button>
                                            </>
                                        </Box>
                                    </Paper>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAddress: data => dispatch(getAddress(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListProduct));