import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    //Link,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { InputLabel, MenuItem, Select, TextField } from '@mui/material';

import MemberOrganization from '../../components/modal/memberOrganization';

import { updateProject, getProjectMember, removeProjectMember } from "../../redux/actions/projects";

class PanelSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataProject: {},
            openModal: false,
            members: [],
            err: null
        };

        this.loadMember = this.loadMember.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleAdded = this.handleAdded.bind(this);
        this.archiveProject = this.archiveProject.bind(this);
        this.handleRemoveUser = this.handleRemoveUser.bind(this);
        this.handleChangeProject = this.handleChangeProject.bind(this);
    }

    archiveProject(e) {
        console.log("archiveProject");
        this.setState({ dataProject: { ...this.state.dataProject, status: 1 } }, () => {
            this.props.updateProject({
                ...this.state.dataProject,
                uuid: this.props.match.params.slug,
                url: this.props.organizationData.url,
            }).then((res) => {
                window.location = "/" + this.props.organizationData.url + "/home"
                // this.props.history.replace("/" + this.props.organizationData.url + "/home");
                // window.location = "/" + this.props.organizationData.url + "/home"
            }).catch((err) => {
                console.log(err);
            })
        });
    }

    handleChangeProject(e) {
        e.preventDefault();
        console.log("handleChangeProject");
        console.log(this.state.dataProject);

        this.props.updateProject({
            ...this.state.dataProject,
            uuid: this.props.match.params.slug,
            url: this.props.organizationData.url,
        }).then((res) => {
            console.log(res);
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        })
    }

    handleRemoveUser(e) {
        this.props.removeProjectMember({
            url: this.props.organizationData.url,
            uuid: this.props.match.params.slug,
            iduser: e.currentTarget.getAttribute("data-iduser"),
        }).then((res) => {
            this.loadMember();
        }).catch((err) => {
            console.log(err);
        })
    }

    handleAdded(e) {
        this.loadMember();
    }

    handleClose(params) {
        this.setState({ openModal: false })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.loadMember();
        }
    }

    loadMember() {
        this.props.getProjectMember({
            url: this.props.organizationData.url,
            uuid: this.props.match.params.slug
        }).then((res) => {
            this.setState({ members: res.data.users });
        }).catch((err) => {
            console.log(err);
        })
    }

    componentDidMount() {
        this.loadMember();

        // console.log(this.props.data);
        this.setState({ dataProject: this.props.data })

        // setTimeout(() => {
        //     // window.location = "/" + this.props.organizationData.url + "/home"
        //     //     this.props.history.replace("/"+this.props.organizationData.url+"/home");
        // }, 3000);
    }

    render() {
        return (
            <Box sx={{ padding: "10px", height: "calc(100vh - 170px)", overflowY: "scroll" }}>
                {/* height: calc(100vh - 170px); */}
                {/* overflow-y: scroll; */}
                <Typography sx={{ fontWeight: "800", fontSize: "17px", color: "#414141", marginBottom: "10px" }}>Setting</Typography>
                <Box>
                    <Box component={"form"} onSubmit={this.handleChangeProject}>
                        <InputLabel sx={{ fontSize: "13px", marginBottom: "5px" }}>Name</InputLabel>
                        <TextField value={this.state.dataProject.name || ""} onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ dataProject: { ...this.state.dataProject, name: e.target.value } });
                        }}
                            fullWidth placeholder='Name' size='small' inputProps={{ style: { fontSize: "13px" } }} />

                        <InputLabel sx={{ fontSize: "13px", marginBottom: "5px", marginTop: "15px" }}>Description</InputLabel>
                        <TextField value={this.state.dataProject.description || ""} onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ dataProject: { ...this.state.dataProject, description: e.target.value } });
                        }} fullWidth placeholder='Name' size='small' multiline rows={3} inputProps={{ style: { fontSize: "13px" } }} />

                        <InputLabel sx={{ fontSize: "13px", marginBottom: "5px", marginTop: "15px" }}>Project Type</InputLabel>
                        <Select displayEmpty value={this.state.dataProject.type || ""} onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ dataProject: { ...this.state.dataProject, type: e.target.value } });
                        }} size='small' sx={{
                            fontSize: "13px",
                            minWidth: "140px",
                            ".MuiSelect-select": {
                                display: "flex",
                                alignItems: "center"
                            }
                        }}>
                            <MenuItem value={1} sx={{ fontSize: "13px" }}>Public</MenuItem>
                            <MenuItem value={2} sx={{ fontSize: "13px" }}>Private</MenuItem>
                        </Select>

                        <Box sx={{ marginTop: "15px" }}>
                            <Button sx={{ minWidth: "140px" }} type="submit" size='small' color='primary' variant='contained' disableElevation>Save</Button>
                        </Box>
                    </Box>

                    <hr style={{
                        border: "none",
                        borderBottom: "1px solid rgb(195 195 195)",
                        display: "block",
                        marginTop: "20px",
                        marginBottom: "20px",
                    }} />

                    <Box sx={{ marginBottom: "5px", marginTop: "15px", display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                        <InputLabel sx={{ fontSize: "13px" }}>Members</InputLabel>
                        {(this.props.userDataRoles === "admin" || this.props.userDataRoles === "moderator") ?
                            <Button sx={{ fontSize: "11px" }} disableElevation size="small" color="secondary" variant="contained"
                                onClick={() => {
                                    this.setState({ openModal: true })
                                }}
                            >Add Members</Button> : ""}
                    </Box>

                    {this.state.members.length !== 0 ?
                        <Box>
                            {this.state.members?.map((item) => {
                                return (
                                    <Box key={item.id} sx={{ height: "25px", backgroundColor: "#e3e3e3", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "5px", padding: "10px", gap: "5px", borderRadius: "5px" }}>
                                        <Box sx={{ padding: "5px", display: "block" }}>
                                            {/* <Box sx={{ padding: "5px" }}> */}
                                            <Typography sx={{ fontSize: "14px", fontWeight: "800", lineHeight: "14px", marginBottom: "5px", marginTop: "10px" }}>{item.fullname}</Typography>
                                            <Typography sx={{ fontSize: "12px", lineHeight: "12px", marginBottom: "10px" }}>{item?.project_user_roles?.roles}</Typography>
                                            {/* </Box> */}
                                        </Box>
                                        <Box>
                                            {this.props.userData.roles[0].name === "admin" ? <>
                                                {this.props.userData.id !== item.id ?
                                                    <Button
                                                        sx={{ fontSize: "11px" }}
                                                        size="small"
                                                        color="secondary"
                                                        variant="outlined"
                                                        data-iduser={item.id}
                                                        onClick={this.handleRemoveUser}
                                                    >Remove</Button>
                                                    : ""}
                                            </> : ""}
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                        : <Box sx={{ textAlign: "center", padding: "15px", fontSize: "14px", backgroundColor: "#d6d6d6", color: "#5f5f5f", borderRadius: "5px" }}>No member found</Box>}
                </Box>

                {this.state.openModal ?
                    <MemberOrganization members={this.state.members} openModal={this.state.openModal} handleCloseModal={this.handleClose} handleAdded={this.handleAdded} />
                    : ""}

                <hr style={{
                    border: "none",
                    borderBottom: "1px solid rgb(195 195 195)",
                    display: "block",
                    marginTop: "20px",
                    marginBottom: "20px",
                }} />

                <Box sx={{ marginBottom: "20px" }}>
                    <InputLabel sx={{ fontSize: "13px", marginBottom: "5px", marginTop: "15px" }}>Danger Area</InputLabel>
                    <Button sx={{ minWidth: "140px" }} size='small' color='error' variant='contained' disableElevation
                        onClick={(e) => {
                            let prompt = window.prompt("If you want to archive this project, type \nasyhadualla ilaha illallah\nin the section below :)")
                            console.log(prompt);
                            if (prompt.toLocaleLowerCase() === "asyhadualla ilaha illallah") {
                                this.archiveProject();
                            }else{
                                alert("Please try again");
                            }
                        }}
                    >Archive Project</Button>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        removeProjectMember: userData => dispatch(removeProjectMember(userData)),
        getProjectMember: userData => dispatch(getProjectMember(userData)),
        updateProject: userData => dispatch(updateProject(userData)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelSetting));