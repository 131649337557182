import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Link,
    Redirect,
    withRouter,
} from "react-router-dom";

import { login, signinWithGoogle } from '../redux/actions/users'

// import Box from '@material-ui/core/Box';
// import Button from '@material-ui/core/Button';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

// import jwt_decode from "jwt-decode";
import { GoogleLogin } from '@react-oauth/google';

import '../styles/login.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isLoading: false,
            err: ''
        };
        this.onLogin = this.onLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCredentialResponse = this.handleCredentialResponse.bind(this);
    }

    handleCredentialResponse(e) {
        console.log("handleCredentialResponse");
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    onLogin(event) {
        this.setState({ isLoading: true, err: '' });
        event.preventDefault();
        const { email, password } = this.state;
        this.props.login({ email, password }).then((res) => {
            console.log(res);
            if (res.code === 200) {
                // this.props.myBand().then((res)=>{
                //     this.props.history.push(this.state.from);
                // });
            } else {
                this.setState({ isLoading: false, err: res.message });
            }
        })
    }

    render() {
        const { email, password, err, isLoading } = this.state;
        return (
            <Box className='login-container fullheight'>
                {(this.props.isLogin !== true) ? '' : <Redirect to="/selectorganization"></Redirect>}
                <Box className='login-paper verticalalign'>
                    <h1 style={{ marginTop: "15px" }}>Login</h1>
                    <Box sx={{ marginTop: "10px", marginBottom: "15px" }}>
                        <GoogleLogin
                            text="continue_with"
                            locale="en"
                            onSuccess={credentialResponse => {
                                this.props.signinWithGoogle({ credential: credentialResponse.credential }).then((res) => {
                                    console.log(res);
                                }).catch((err) => {
                                    console.log(err);
                                })
                            }}
                            onError={() => {
                                alert("Login with Google Failed");
                                console.log('Login Failed');
                            }}
                        />
                    </Box>

                    <Divider sx={{ width: "240px", marginBottom: "15px" }}>or</Divider>

                    <form onSubmit={this.onLogin}>
                        <Box className="inputWrapper">
                            <label htmlFor="email">Email: </label>
                            <input autoComplete="email" required id="email" type="email" placeholder="email@email.com" value={email} onChange={this.handleChange}></input>
                        </Box>
                        <Box className="inputWrapper">
                            <label htmlFor="password">Password: </label>
                            <input autoComplete="current-password" required id="password" type="password" placeholder="password" value={password} onChange={this.handleChange}></input>
                        </Box>
                        <Box className="error-message" sx={{ fontSize: "14px", marginBottom: "12px", textAlign: "center", color: "#f00" }}>
                            {(err !== '') ? err : ''}
                        </Box>
                        {(isLoading === false) ? <Box className="inputWrapper" sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "10px"
                        }}
                        >
                            <Button type="submit" className="login-btn" variant="contained" color="primary">Login</Button>
                        </Box> : <Box className="loading-message">Please Wait</Box>}
                    </form>
                    <Link style={{ cursor: "pointer", fontSize: "14px", marginTop: "10px" }} to="./register">Doesn't have an account? Register Here</Link>
                    <Link style={{ cursor: "pointer", fontSize: "14px", marginTop: "10px", marginBottom: "10px" }} to="./forgotpassword">Forgot Password</Link>
                </Box >
            </Box >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: userData => dispatch(login(userData)),
        signinWithGoogle: userData => dispatch(signinWithGoogle(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));