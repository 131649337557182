import React, { Component } from 'react';

import {
    withRouter,
    // Redirect
} from "react-router-dom";

import {
    NavLink,
} from "react-router-dom";

class ListOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizations:[],
            err:null
        };

        // this.onLogout = this.onLogout.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // onLogout(params) {
    //     console.log('onLogout');
    //     if(this.props.forceLogout().status === 200){
    //         this.props.history.push("/");
    //     }
    // }

    // toggleMenu(params){

    // }

    componentDidMount(){
        console.log('organization mounted');
        console.log(this.props.match.url);
        console.log(this.props.organizations);

        // const url = this.props.match.params.url;
        // console.log(url);
    }

    render() {
        return (
            <div className="select-organization-container">
                {/* DEBUG DUMMY */}
                {/* <div className='select-organization-container-item'>
                    <div className='menu left-item'><span className='title'>Dummy Org</span></div>
                    <div className='menu right-item'><NavLink to={"/dummyorg/home"}>Open Dashboard</NavLink></div>
                </div>
                <div className='select-organization-container-item'>
                    <div className='menu left-item'><span className='title'>Dummy 401</span></div>
                    <div className='menu right-item'><NavLink to={"/seblakbasahmonyojkt/home"}>Open Dashboard</NavLink></div>
                </div> */}
                {this.props.organizations.map((item)=>{
                    // console.log(item);
                    return(
                        <div key={item.id} className='select-organization-container-item'>
                            <div className='menu left-item'><span className='title'>{item.name}</span></div>
                            <div className='menu right-item'><NavLink to={item.url+"/home"}>Open Dashboard</NavLink></div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default withRouter((ListOrganization));