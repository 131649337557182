const initialState = {
    isLogin: false,
    url: null,
    userData: null,
    userDataRoles: null,
    organizationData: null,
    dashboardData: null,
    salesData: null,
    notification: null,
    notificationCount: 0
};

function rootReducer(state = initialState, action) {
    if (action.type === 'SET_LOGIN') {
        window.localStorage.setItem('app_tt', action.payload.access_token)
        return Object.assign({}, state, {
            isLogin: true,
            userData: action.payload
        });
    } else if (action.type === 'SET_LOGOUT') {
        window.localStorage.removeItem('app_tt')
        return Object.assign({}, state, {
            isLogin: false,
            url: null,
            userData: null,
            organizationData: null,
            dashboardData: null,
            salesData: null
        });
    } else if (action.type === 'ORGANIZATION_ROLES') {
        return Object.assign({}, state, {
            userDataRoles: action.payload.data
        });
    } else if (action.type === 'RESET_ORGANIZATION') {
        return Object.assign({}, state, {
            organizationData: null,
            userDataRoles: null
        });
    } else if (action.type === 'SET_ORGANIZATION') {
        return Object.assign({}, state, {
            organizationData: action.payload.data
        });
    } else if (action.type === 'APPEND_PROJECT') {
        let tmpProject = state.organizationData.projects;
        tmpProject.push(action.payload.data);
        let tmpOrganizationData = state.organizationData;
        tmpOrganizationData.projects = tmpProject;

        return Object.assign({}, state, {
            organizationData: tmpOrganizationData
        });
    } else if (action.type === 'DASHBOARD_LOADED') {
        return Object.assign({}, state, {
            dashboardData: action.payload.data
        });
    } else if (action.type === 'SALES_LOADED') {
        return Object.assign({}, state, {
            salesData: action.payload.data
        });
    } else if (action.type === 'NOTIFICATION_LOADED') {
        let notificationCountTmp = 0
        for(let i = 0; i< action.payload.data.length;i++){
            if(action.payload.data[i].is_read === false){
                notificationCountTmp += 1;
            }
        }

        return Object.assign({}, state, {
            notification: action.payload.data,
            notificationCount: notificationCountTmp
        });
    } else if (action.type === 'NOTIFICATION_UPDATED') {
        let tempArr = state.notification;
        let idx = tempArr.findIndex(d => d._id === action.ids[0])
        tempArr[idx].is_read = true;

        let notificationCountTmp = 0
        for(let i = 0; i< tempArr.length;i++){
            if(tempArr[i].is_read === false){
                notificationCountTmp += 1;
            }
        }
        
        return Object.assign({}, state, {
            notification: tempArr,// state.notification.concat(action.payload.data)
            notificationCount: notificationCountTmp
        });
    } else {
        return state;
    }

    // bandAlbum: action.payload.data.data//state.bandAlbum.concat(action.payload.data.data)

    //   else if (action.type === 'MYBAND') {
    //     return Object.assign({}, state, {
    //       //userBand
    //       bandData:action.payload.data.bands//state.userBand.concat(action.payload.data.bands)
    //     });
    //   }else if (action.type === 'LOADED_BAND') {
    //     return Object.assign({}, state, {
    //       bandData: action.payload.data.bands//state.userBand.concat(action.payload.data.bands)
    //     });
    //   }else if (action.type === 'LOADED_BAND_ALBUM') {
    //     return Object.assign({}, state, {
    //       bandAlbum: action.payload.data.data//state.bandAlbum.concat(action.payload.data.data)//state.userBand.concat(action.payload.data.bands)
    //     });
    //   }else if (action.type === 'LOADED_BAND_CONTENT') {
    //     return Object.assign({}, state, {
    //       bandContent: action.payload.data.data//state.bandAlbum.concat(action.payload.data.data)//state.userBand.concat(action.payload.data.bands)
    //     });
    //   }else if (action.type === 'CREATE_BAND') {
    //     return Object.assign({}, state, {
    //       //userBand
    //       bandData: action.payload.data.data//state.userBand.concat(action.payload.data.data)
    //     });
    //   }else if (action.type === 'LOGIN') {
    //     return Object.assign({}, state, {
    //       isLogin: 'false'
    //     });
    //   }else if (action.type === 'UPLOAD_PROGRESS') {
    //     return Object.assign({}, state, {
    //       uploadData: {isUpload:true,loaded:action.payload.loaded,total:action.payload.total}
    //     });
    //   }else if (action.type === 'UPLOAD_COMPLETE') {
    //     return Object.assign({}, state, {
    //       uploadData: {isUpload:false,loaded:0,total:0}
    //     });
    //   }else if(action.type === 'SET_PUSHER'){
    //     return Object.assign({}, state, {
    //       pusherChannel: action.payload
    //     });
    //   }
}

export default rootReducer;