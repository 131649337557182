// import QRCode from "react-qr-code";
// <QRCode size={512} value="https://forms.gle/SbQa9YQuSsdwKmEo8"/>

import React, { Component } from 'react';
import { connect } from "react-redux";

import PropTypes from 'prop-types';

import { getDetailProject, getProjectMember } from "../../redux/actions/projects";
import { getOrganizationMembers } from "../../redux/actions/organizations";
import {
    createSection,
    updateSection,
    deleteSection,
    createTask,
    updateTask,
    reorderCard,
    reorderTask,
    deleteTask
} from "../../redux/actions/tasks";

import Pusher from 'pusher-js';

import {
    withRouter
} from "react-router-dom";

import Board from 'react-trello'

import NewCardFormInput from '../../components/input/newCardFormInput';
import NewLaneFormInput from '../../components/input/newLaneFormInput';

import AddCardDrawer from '../../components/drawer/addCardDrawer';
import AddLaneDrawer from '../../components/drawer/addLaneDrawer';

import LaneHeader from '../../components/lane/laneHeader';

// import PanelReport from '../../components/panel/report';
import PanelSetting from '../../components/panel/setting';
import PanelActivity from '../../components/panel/activity';
// import PanelTimeline from '../../components/panel/timeline';

import {
    Box,
    Stack,
    Avatar,
    Checkbox,
    Typography,
    Tabs,
    Tab,
} from '@mui/material';

import {
    deepOrange,
    deepPurple
} from '@mui/material/colors';

import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import StarsIcon from '@mui/icons-material/Stars';
import PublicIcon from '@mui/icons-material/Public';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import BugReportIcon from '@mui/icons-material/BugReport';
import AttachmentIcon from '@mui/icons-material/Attachment';

import { format, compareAsc, intervalToDuration, formatDistanceToNow } from 'date-fns';

const sanitizeHTML = (html) => {
    return html ? html.replace(/<\/?p>/g, '') : '';
};

class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pusher: null,
            channel: null,

            members: [],
            tabIndex: 1,
            addCardLink: [],

            openTaskDrawer: false,
            taskLaneId: null,
            openCardForm: false,
            taskEditMode: false,
            taskEditId: null,

            openLaneDrawer: false,
            laneEditId: null,

            data: null,
            projectData: null,
            boardData: null,

            eventBus: null,
            loadedData: false,
            err: null
        };

        this.loadData = this.loadData.bind(this);

        this.orderProjects = this.orderProjects.bind(this);
        this.orderSections = this.orderSections.bind(this);
        this.getSectionLength = this.getSectionLength.bind(this);
        this.orderLocalTask = this.orderLocalTask.bind(this);
        this.createLocalTask = this.createLocalTask.bind(this);
        this.createLocalSection = this.createLocalSection.bind(this);

        this.openEditCard = this.openEditCard.bind(this);
        this.updateLocalTask = this.updateLocalTask.bind(this);

        this.openEditLane = this.openEditLane.bind(this);
        this.updateLocalLane = this.updateLocalLane.bind(this);
        this.deleteLocalLane = this.deleteLocalLane.bind(this);

        this.closeNewCardForm = this.closeNewCardForm.bind(this);
        this.addNewCardForm = this.addNewCardForm.bind(this);

        this.getTypeProject = this.getTypeProject.bind(this);
        this.getInititalName = this.getInititalName.bind(this);

        this.pusherHandle = this.pusherHandle.bind(this);

        this.cyrb53 = this.cyrb53.bind(this);
        this.MakeSenseColor = this.MakeSenseColor.bind(this);
    }

    cyrb53(str, seed = 0) {
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
        return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    };

    MakeSenseColor(str) {
        // hash the string and convert to hex
        let hash = this.cyrb53(str).toString(16);

        // just use the last 6 characters and prepend character to indicate color
        return '#' + hash.slice(-6);
    }

    getInititalName(e) {
        return e[0];
    }

    getTypeProject(type) {
        let icon = <PublicIcon sx={{ fontSize: "16px" }} />
        if (type !== 1) {
            icon = <LockIcon sx={{ fontSize: "16px" }} />
        }

        return icon
    }

    deleteLocalLane(id) {
        let conf = window.confirm("All task inside lane, also will be delete");
        if (conf) {
            this.props.deleteSection({
                url: this.props.organizationData.url,
                uuid: this.props.match.params.slug,
                id: id,
            }).then((res) => {
                if (res.code === 200) {
                    this.loadData();
                } else {
                    alert("Delete Lane Error");
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    openEditLane(e) {
        this.setState({ laneEditId: e }, function () {
            this.setState({ openLaneDrawer: true })
        });
    }

    updateLocalLane(data, d) {
        this.props.updateSection({
            url: this.props.organizationData.url,
            uuid: this.props.match.params.slug,
            id: data.id,
            name: data.name,
            description: data.description,
            type: data.type
        }).then((res) => {
            if (res.code === 200) {
                this.setState({
                    openLaneDrawer: false,
                    laneEditId: null,
                }, function () {
                    this.loadData();
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    openEditCard(e, f) {
        this.setState({ taskEditId: e, taskLaneId: f, taskEditMode: true }, function () {
            this.setState({ openTaskDrawer: true })
        });
    }

    updateLocalTask(data, subtask = null, files, labels = null) {
        // console.log(files);
        // console.log(labels);

        this.props.updateTask({
            id: data.id,
            reporter: data.reporter,
            assignee: data.assignee,
            name: data.name,
            description: data.description,
            start_date: data.start_date,
            due_date: data.due_date,
            priority: data.priority,
            sort_order: data.sort_order,
            score: data.score,
            status: data.status,
            type: data.type,
            subtask: subtask,
            labels: labels,
            files: files,
        }).then((res) => {
            // console.log(res.data);

            this.state.eventBus.publish({
                type: 'UPDATE_CARD',
                laneId: res.data.idsection.toString(),
                card: {
                    id: res.data.id.toString(),
                    title: res.data.name,
                    name: res.data.name,
                    description: res.data.description,
                    status: res.data.status,
                    type: res.data.type,
                    priority: res.data.priority,
                    tasks: res.data.subtasks,
                    task_type: res.data.task_type,
                    task_priority: res.data.task_priority,
                    sort_order: res.data.sort_order,
                    score: res.data.score,
                    due_date: res.data.due_date
                }
            })

            //new
            this.setState({ taskEditId: null, taskLaneId: null, taskEditMode: false, openTaskDrawer: false }, function () {
                this.loadData()
            })
        }).catch((err) => {
            console.log(err);
        })
    }

    addNewCardForm(id) {
        this.setState({ taskLaneId: id, taskEditMode: false }, function () {
            this.setState({ openTaskDrawer: true });
        });
    }

    closeNewCardForm() {
        this.setState({
            openTaskDrawer: false,
            openCardForm: false,
            taskLaneId: null,
            taskEditId: null,
            taskEditMode: false
        });
    }

    getTypeIcon(e) {
        let icon;
        if (e === 1) {
            icon = <InfoIcon sx={{ fontSize: "14px", top: "2px", position: "relative" }} />
        } else if (e === 2) {
            icon = <FlashOnIcon sx={{ fontSize: "14px", top: "2px", position: "relative" }} />
        } else if (e === 3) {
            icon = <StarsIcon sx={{ fontSize: "14px", top: "2px", position: "relative" }} />
        } else if (e === 4) {
            icon = <BugReportIcon sx={{ fontSize: "14px", top: "2px", position: "relative" }} />
        }

        return icon
    }

    getLabelPriority(e) {
        return (
            <Typography className="task-priority-chip" sx={{ backgroundColor: e.color, cursor: "grab", fontSize: "14px" }}>
                {e.name}
            </Typography>
        )
    }

    formatStartDate(e) {
        let tgl = format(new Date(e), "dd MMM");
        // let later = false;
        // let compare = compareAsc(
        //     new Date(e), new Date()
        // )
        // if (compare === -1) {
        //     later = true;
        // }

        let interval = intervalToDuration({
            start: new Date(e),
            end: new Date()
        })

        if (interval.days > 2) {
            tgl = <Typography className="card-date" title="Due Date" sx={{ cursor: "grab", fontSize: "12px", color: "#7e7e7e" }}>
                Start {format(new Date(e), "dd MMM")}
            </Typography>
        } else {
            tgl = <Typography className="card-date" title="Due Date" sx={{ cursor: "grab", fontSize: "12px", color: "#7e7e7e" }}>
                Start {formatDistanceToNow(new Date(e), { addSuffix: true })}
            </Typography>
        }

        // if (interval.days > 2) {
        //     tgl = format(new Date(e), "dd MMM");
        // } else {
        //     tgl = formatDistanceToNow(new Date(e))
        // }

        return tgl
    }

    formatDueDate(e) {
        let tgl = format(new Date(e), "dd MMM");
        let later = false;
        let compare = compareAsc(
            new Date(e), new Date()
        )
        if (compare === -1) {
            later = true;
        }

        let interval = intervalToDuration({
            start: new Date(),
            end: new Date(e)
        })

        if (interval.days > 2) {
            tgl = <Typography className={"card-date " + (later ? "late" : "")} title="Due Date" sx={{ cursor: "grab", fontSize: "12px", color: "#7e7e7e" }}>
                End {format(new Date(e), "dd MMM")}
            </Typography>
        } else {
            tgl = <Typography className={"card-date " + (later ? "late" : "")} title="Due Date" sx={{ cursor: "grab", fontSize: "12px", color: "#7e7e7e" }}>
                {(later ? "Ended" : "End")} {formatDistanceToNow(new Date(e), { addSuffix: true })}
            </Typography>
        }

        return tgl
    }

    getSectionLength(e, f) {
        let boardDetail = f.lanes.find(x => x.id === e);
        return boardDetail.cards;
    }

    createLocalSection(data) {
        data.order = this.state.boardData.lanes.length;
        this.props.createSection({
            url: this.props.organizationData.url,
            uuid: this.props.match.params.slug,
            data: data
        }).then((res) => {
            this.loadData();
        }).catch((err) => {
            console.log(err);
        })
    }

    createLocalTask(data, laneId, subtask = null) {
        let boardDetail = this.getSectionLength(laneId, this.state.boardData);
        data.length = boardDetail.length;
        this.props.createTask({
            idorganization: this.state.data.idorganization,
            idproject: this.state.data.id,
            idsection: parseInt(laneId),
            name: data.name,
            assignee: data.assignee || null,
            reporter: data.reporter || null,
            description: data.description,
            order: data.length,
            start_date: data.start_date,
            due_date: data.due_date,
            type: data.type,
            priority: data.priority,
            subtask: subtask !== null ? subtask : undefined
        }).then((res) => {
            console.log(res.data);

            this.state.eventBus.publish({ type: 'REMOVE_CARD', laneId: laneId, cardId: data.id })
            this.state.eventBus.publish({
                type: 'ADD_CARD',
                laneId: laneId,
                card: {
                    id: res.data.id,
                    attachments: res.data.attachments,
                    label: res.data.label,
                    title: res.data.name,
                    name: res.data.name,
                    description: res.data.description,
                    status: res.data.status,
                    type: res.data.type,
                    assignee: res.data.assignee_user,
                    reporter: res.data.reporter_user,
                    priority: res.data.priority,
                    tasks: res.data.subtasks || [],
                    task_type: res.data.task_type,
                    task_priority: res.data.task_priority,
                    sort_order: res.data.sort_order,
                    score: res.data.score,
                    due_date: res.data.due_date,
                    comments: res.data.comments
                }
            })
            this.setState({ taskEditId: null, taskLaneId: null, openTaskDrawer: false, openCardForm: false });
        }).catch((err) => {
            console.log(err);
        })
    }

    orderLocalTask(id, from, to, order) {
        this.props.updateTask({ id: parseInt(id), idproject: this.state.data.id, idsection: parseInt(to), reorder: true }).then((res) => {
            // console.log("updateTask");
            // console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    async orderProjects(id) {
        let orderedLane = await this.state.boardData.lanes.map((item, index) => {
            return (
                {
                    id: item.id,
                    order: index
                }
            )
        })

        this.props.reorderCard({ url: this.props.organizationData.url, uuid: this.props.match.params.slug, data: orderedLane }).then((res) => {
            // console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    async orderSections(id) {
        let newArray = [];
        await id.map((item) => {
            let boardDetail = this.state.boardData.lanes.find(x => x.id === item);
            return (
                boardDetail.cards.map((item, index) => {
                    newArray.push({
                        id: parseInt(item.id),
                        order: index
                    })

                    return ({
                        id: parseInt(item.id),
                        order: index
                    })
                })
            )
        })

        this.props.reorderTask({ url: this.props.organizationData.url, uuid: this.props.match.params.slug, data: newArray }).then((res) => {
            // console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    loadData() {
        this.props.getDetailProject({ url: this.props.organizationData.url, uuid: this.props.match.params.slug }).then(async (res) => {
            if (res.code !== 200) {
                alert(res.message);
                this.props.history.replace("../../home");
            } else {
                let rawSection = res.data;
                let mapped = [];

                // console.log(rawSection);

                await Promise.all(
                    await rawSection.sections.map(async (section) => {
                        let tmpCards = await section.tasks.map((item) => (
                            {
                                id: item.id.toString(),
                                attachments: item.attachments,
                                title: item.name,
                                description: item.description || "",
                                label: item.label,
                                start_date: item.start_date,
                                due_date: item.due_date,
                                type: item.type,
                                assignee: item.assignee_user,
                                reporter: item.reporter_user,
                                tasks: item.subtasks,
                                task_type: item.task_type,
                                priority: item.priority,
                                task_priority: item.task_priority,
                                status: item.status,
                                score: item.score,
                                comments: item.comments,
                                metadata: {
                                    id: item.id.toString(),
                                    title: item.name,
                                }
                            }
                        )
                    )

                        return (
                            mapped.push({
                                id: section.id.toString(),
                                title: section.name,
                                label: section.type.toString(),
                                currentPage: 1,
                                cards: tmpCards
                            })
                        )
                    })
                )

                let users = [];
                if (rawSection.type === 2) {
                    users = await this.props.getProjectMember({ url: this.props.organizationData.url, uuid: this.props.match.params.slug })
                } else {
                    users = await this.props.getOrganizationMembers({ id: this.props.organizationData.id })
                }

                users = users.data.users.map((item) => { return ({ ...item, label: item.fullname, value: item.id }) })
                users.sort(function (a, b) {
                    if (a.fullname < b.fullname) { return -1; }
                    if (a.fullname > b.fullname) { return 1; }
                    return 0;
                })

                this.setState({ data: res.data, members: users, boardData: { lanes: mapped } }, function () {
                    this.setState({ loadedData: true })
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    pusherHandle(data) {
        console.log("pusherHandle");
        console.log(data);
        if (data.userId !== this.props.userData.id) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();

        const pusher = new Pusher("fe7141a6fd954dd984ba", {
            cluster: "ap1",
        });

        // console.log('project_'+this.props.match.params.slug);
        this.setState({ pusher: pusher }, function () {
            const channel = this.state.pusher.subscribe('project');
            this.setState({ channel: channel }, function () {
                this.state.channel.bind(this.props.match.params.slug, this.pusherHandle);
            })
        })
    }

    componentWillUnmount() {
        this.state.pusher.unsubscribe('project');
        this.state.channel.unbind(this.props.match.params.slug);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.slug !== this.props.match.params.slug) {
            this.loadData();
            this.setState({ tabIndex: 1 }, function () {
                this.state.channel.unbind(prevProps.match.params.slug);
                this.state.channel.bind(this.props.match.params.slug, this.pusherHandle);
            })
        }
    }

    getFibonacciColor = (score) => {
        switch(score) {
            case 1:
            case 2:
                return 'green';
            case 3:
            case 5:
                return 'yellow';
            case 8:
            case 13:
                return 'orange';
            default:
                return 'red';
        }
    }

    render() {
        return (
            <Box className="panel home-panel">
                <Box className="board-header">
                    <Box><Typography sx={{ fontSize: "20px", display: "flex", alignItems: "center", gap: "5px" }}>{this.getTypeProject(this.state.data?.type)} {this.state.data?.name || "Board"}</Typography></Box>
                </Box>
                <Box sx={{ overflowX: "hidden", width: "calc(100vw - 220px)" }}>
                    <Tabs
                        sx={{ borderBottom: "1px solid #cfcfcf" }}
                        variant='fullWidth'
                        value={this.state.tabIndex}
                        aria-label="wrapped label tabs example"
                        onChange={(e, newValue) => {
                            this.setState({ tabIndex: newValue })
                        }}
                    >
                        <Tab value={1} label="Boards" />
                        <Tab value={2} label="Timeline" />
                        <Tab value={3} label="Report" />
                        <Tab value={4} label="Activity" />
                        <Tab value={5} label="Setting" />
                    </Tabs>
                    <TabPanel sx={{ backgroundColor: "#fbfbfb", boxShadow: "inset 0 1px 6px 1px #51515154" }} value={this.state.tabIndex} index={1}>
                        <Box sx={{ overflowX: "scroll", width: "calc(100vw - 220px)" }}>
                            {this.state.loadedData ?
                                <Board className="board-container" draggable canAddLanes editable
                                    eventBusHandle={(handle) => {
                                        this.setState({ eventBus: handle });
                                    }} data={this.state.boardData}

                                    style={{
                                        backgroundColor: '#eee'
                                    }}
                                    laneStyle={{
                                        maxWidth: "270px",
                                        maxHeight: "calc(100vh - 210px)",
                                        scrollBehavior: "smooth",
                                        // 100px
                                    }}
                                    cardStyle={{
                                        fontFamily: "arial",
                                        maxWidth: "250px"
                                    }}

                                    components={{
                                        LaneHeader: (e, f, g) => {
                                            let ctx = this;
                                            return (
                                                <LaneHeader e={e} f={f} g={g} ctx={ctx} handleDeleteButton={this.deleteLocalLane} openEditLane={this.openEditLane} />
                                            )
                                        },
                                        Card: (e, f) => {
                                            let ctx = this;
                                            return (
                                                <Box className="card-body" sx={{ position: "relative", padding: "5px", backgroundColor: "#ffffff", marginBottom: "5px", borderRadius: "5px" }}>
                                                    <Box className="card-header-button-delete" sx={{ position: "absolute", top: "5px", right: "5px" }}>
                                                        <Typography title="Remove card" name={e.id} data-id={e.id} sx={{ fontSize: "12px", cursor: "pointer" }} onClick={(evt) => {
                                                            evt.preventDefault();

                                                            let conf = window.confirm("Delete Task?");
                                                            if (conf) {
                                                                ctx.props.deleteTask({ id: evt.target.getAttribute('data-id') }).then((res) => {
                                                                    if (res.code === 200) {
                                                                        e.onDelete();
                                                                    }
                                                                }).catch((err) => {
                                                                    console.log(err);
                                                                })
                                                            }
                                                        }}>Delete</Typography>
                                                    </Box>

                                                    {e.label?.length > 0 ? <Box className="label-icon-container">{
                                                        e.label.map((item) => {
                                                            return (
                                                                <Box className="label-icon" sx={{ 
                                                                    backgroundColor: this.MakeSenseColor(item.name),
                                                                    textShadow: "1px 1px 2px " + this.MakeSenseColor(item.name),
                                                                    border: "1px solid " + this.MakeSenseColor(item.name)
                                                                }} key={item.id}>{item.name}</Box>
                                                            )
                                                        })
                                                    }</Box> : ""}

                                                    <Typography className="card-title-label" title={e.title} sx={{ cursor: "pointer", fontSize: "15px", marginTop: "5px", marginBottom: "5px", lineHeight: "16px", fontWeight: "800", width: "calc(100% - 40px)" }} onClick={e.onClick}>
                                                        {this.getTypeIcon(e.type)} {e.title}
                                                    </Typography>

                                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                        {e.start_date ? <>{this.formatStartDate(e.start_date)}</> : ""}
                                                        {e.due_date ? <>{this.formatDueDate(e.due_date)} </> : ""}
                                                    </Box>

                                                    {e.description ?
                                                        <Typography title="Task Description"
                                                            sx={{
                                                                maxWidth: "250px",
                                                                minHeight: "21px",
                                                                cursor: "grab",
                                                                fontSize: "14px",
                                                                marginTop: "5px",
                                                                marginBottom: "5px",
                                                                whiteSpace: "break-spaces",
                                                                overflowWrap: "break-word",
                                                                lineHeight: "20px",
                                                                display: "-webkit-box",
                                                                WebkitBoxOrient: "vertical",
                                                                WebkitLineClamp: 3,
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis"
                                                            }}
                                                            onClick={e.onClick}
                                                            dangerouslySetInnerHTML={{
                                                                __html: sanitizeHTML(e.description)
                                                            }}
                                                        />
                                                        : 
                                                        ""
                                                    }

                                                    {e.tasks?.length !== 0 ?
                                                        <Box onClick={e.onClick} sx={{ cursor: "pointer", marginTop: "10px", marginBottom: "12px" }}>
                                                            {e.tasks?.map((item) => {
                                                                return (
                                                                    <Box key={item.id} sx={{ display: "block", backgroundColor: "#f2f2f2", padding: "5px", borderRadius: "5px" }}>
                                                                        <Box>
                                                                            <Typography sx={{ fontSize: "14px", fontWeight: "900", marginBottom: "2px", marginTop: "2px" }}>{item.name}</Typography>
                                                                        </Box>
                                                                        {item.items?.map((item) => {
                                                                            return (
                                                                                <Box key={item.id} sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                                                                    <Checkbox disabled sx={{ padding: "0px", marginRight: "5px" }} size='small' checked={Boolean(item.status)} />
                                                                                    <Typography sx={{ fontSize: "12px" }}>{item.name}</Typography>
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                    </Box>
                                                                )
                                                            })}
                                                        </Box> : ""}

                                                    {e.task_priority ?
                                                        <Box onClick={e.onClick} sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            flexDirection: "row",
                                                            flexWrap: "nowrap",
                                                            marginTop: "10px",
                                                            marginBottom: "10px"
                                                        }}>
                                                            {this.getLabelPriority(e.task_priority)}
                                                        </Box> : ""}

                                                    {e?.attachments?.length > 0 ?
                                                        <Box sx={{ cursor: "pointer", marginTop: "10px", display: "flex", alignItems: "center", flexDirection: "row", flexWrap: "nowrap", gap: "5px" }} onClick={e.onClick}>
                                                            <AttachmentIcon sx={{ fontSize: "19px", color: "#676767" }} /> <Typography sx={{ fontSize: "14px" }}>{e.attachments.length > 1 ? e.attachments.length + " Attachments" : e.attachments.length + " Attachment"}</Typography>
                                                        </Box> : ""}

                                                    {e.assignee !== null || e.reporter !== null ?
                                                        <Box onClick={e.onClick} className="task-box-avatar" sx={{ marginTop: "10px", marginBottom: "5px", cursor: "pointer" }}>
                                                            <Stack direction="row" spacing="2px">
                                                                {e.assignee ?
                                                                    <Avatar title={e.assignee?.fullname} sx={{ width: 24, height: 24, bgcolor: deepPurple[500], fontSize: "12px" }}>{this.getInititalName(e.assignee?.fullname)}</Avatar> : ""}
                                                                {e.reporter ?
                                                                    <Avatar title={e.reporter?.fullname} sx={{ width: 24, height: 24, bgcolor: deepOrange[500], fontSize: "12px" }}>{this.getInititalName(e.reporter?.fullname)}</Avatar> : ""}
                                                            </Stack>
                                                        </Box> : ""}

                                                    {e.score !== null && (
                                                        <Typography 
                                                            fontSize={14} 
                                                            style={{ 
                                                                padding: '6px', 
                                                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', 
                                                                display: 'inline-block',
                                                                margin: '5px'
                                                            }}
                                                        >
                                                            <span>Story Point: <strong>{e.score}</strong></span>
                                                        </Typography>
                                                    )}
                                                </Box>
                                            )
                                        },
                                        NewLaneForm: (e) => {
                                            return (
                                                <NewLaneFormInput e={e} />
                                            )
                                        },
                                        NewCardForm: (e, f) => {
                                            return (
                                                <NewCardFormInput id="new-card-id" e={e} openCardForm={this.state.openCardForm} handleCloseNewCardForm={this.closeNewCardForm} />
                                            )
                                        },
                                        AddCardLink: (e) => {
                                            let ctx = this;
                                            return (
                                                <>{
                                                    this.state.openCardForm ? "" :
                                                        <Box title="Create new task" className="add-new-card-link" onClick={(evt) => {
                                                            evt.preventDefault();

                                                            // evt.currentTarget.parentElement.scrollIntoView({ behavior: 'smooth' });
                                                            evt.currentTarget.parentElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

                                                            ctx.setState({ openCardForm: true })
                                                            e.onClick();
                                                        }} sx={{ fontSize: "14px", cursor: "pointer" }}>+ Add new Card</Box>
                                                }</>
                                            )
                                        }
                                    }}

                                    onCardMoveAcrossLanes={(d, e, f, o, h) => {
                                        if (d === e) {
                                            this.orderSections([d]);
                                        } else {
                                            this.orderSections([d, e]);
                                        }
                                        this.orderLocalTask(f, d, e, o);
                                    }}

                                    handleLaneDragEnd={(a, b, c) => {
                                        this.orderProjects([a, b])
                                    }}

                                    onLaneAdd={(card) => {
                                        this.createLocalSection(card);
                                    }}

                                    onCardAdd={(card, laneId, j, g) => {
                                        this.createLocalTask(card, laneId);
                                    }}

                                    onCardClick={(idc, card, idl) => {
                                        this.openEditCard(idc, idl);
                                    }}

                                    onDataChange={(data) => {
                                        this.setState({ boardData: data });
                                    }}
                                /> : ""}
                        </Box>
                    </TabPanel>
                    <TabPanel sx={{ backgroundColor: "#fbfbfb", boxShadow: "inset 0 1px 6px 1px #51515154" }} value={this.state.tabIndex} index={2}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "calc(100vh - 150px)" }}>
                            Coming Soon
                        </Box>
                    </TabPanel>
                    <TabPanel sx={{ backgroundColor: "#fbfbfb", boxShadow: "inset 0 1px 6px 1px #51515154" }} value={this.state.tabIndex} index={3}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "calc(100vh - 150px)" }}>
                            Coming Soon
                        </Box>
                    </TabPanel>
                    <TabPanel sx={{ backgroundColor: "#fbfbfb", boxShadow: "inset 0 1px 6px 1px #51515154" }} value={this.state.tabIndex} index={4}>
                        {this.state.data ?
                            <PanelActivity data={this.state.data} /> : ""}
                    </TabPanel>
                    <TabPanel sx={{ backgroundColor: "#fbfbfb", boxShadow: "inset 0 1px 6px 1px #51515154" }} value={this.state.tabIndex} index={5}>
                        {this.state.data ?
                            <PanelSetting data={this.state.data} /> : ""}
                    </TabPanel>
                </Box >

                <AddLaneDrawer
                    laneEditId={this.state.laneEditId}
                    openLaneDrawer={this.state.openLaneDrawer}
                    handleSaveButton={this.updateLocalLane}
                    onClose={() => {
                        this.setState({ laneEditId: null, openLaneDrawer: false })
                    }}
                />
                <AddCardDrawer
                    data={this.state.data}
                    members={this.state.members}
                    taskLaneId={this.state.taskLaneId}
                    taskEditMode={this.state.taskEditMode}
                    taskEditId={this.state.taskEditId}
                    openTaskDrawer={this.state.openTaskDrawer}
                    handleCloseNewCardForm={this.closeNewCardForm}
                    createLocalTask={this.createLocalTask}
                    handleSaveButton={this.updateLocalTask}
                    onClose={() => {
                        this.setState({ taskEditId: null, taskLaneId: null, taskEditMode: false, openTaskDrawer: false })
                    }}
                />
            </Box >
        )
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </Box>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        getProjectMember: data => dispatch(getProjectMember(data)),
        getOrganizationMembers: data => dispatch(getOrganizationMembers(data)),
        getDetailProject: data => dispatch(getDetailProject(data)),
        createSection: data => dispatch(createSection(data)),
        updateSection: data => dispatch(updateSection(data)),
        deleteSection: data => dispatch(deleteSection(data)),
        createTask: data => dispatch(createTask(data)),
        updateTask: data => dispatch(updateTask(data)),
        reorderCard: data => dispatch(reorderCard(data)),
        reorderTask: data => dispatch(reorderTask(data)),
        deleteTask: data => dispatch(deleteTask(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Setting));