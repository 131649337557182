import axios from 'axios';
import { BASE_URL, BASE_VERSION } from "../config";

export const createOrganization = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {

    let data = {}
    data.url = payload.url;
    data.name = payload.name;
    data.description = payload.description;
    data.phone = payload.phone;
    data.website = payload.website;
    data.instagram = payload.instagram;

    return (axios.post(BASE_URL + BASE_VERSION + 'organization', data, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const acceptOrganization = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {

    let data = {}
    data.id = payload.id;

    return (axios.post(BASE_URL + BASE_VERSION + 'organization/members/' + payload.url + "/accept", data, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const getOrganizationInvitation = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'organization/members/invitation', { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const inviteOrganization = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'organization/members/' + payload.url + '/invite', { email: payload.email }, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const approveOrganization = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    let data = {
      iduser: payload.iduser,
    }

    return (axios.post(BASE_URL + BASE_VERSION + 'organization/members/' + payload.url + "/approve", data, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const searchOrganization = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'organization/search/' + payload.keyword, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const joinOrganization = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'organization/join/' + payload.url, null, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const getOrganizationMembers = (payload) => {  
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'organization/members/' + payload.id, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const getOrganizationRequest = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'organization/members/request/' + payload.url, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const getOrganization = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'organization/' + payload.url, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        if (payload.code === 200) {
          dispatch({ type: 'SET_ORGANIZATION', payload });
        }
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const getMyOrganization = (...payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'organization', { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'RESET_ORGANIZATION', payload });
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const getOrganizationRoles = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'organization/roles/' + payload.url, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const setOrganizationRole = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'organization/roles/' + payload.url, { iduser: payload.iduser, role: payload.role }, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const getAllRules = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'organization/rules/' + payload.url, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        // dispatch({ type: 'DASHBOARD_LOADED', payload });     
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const createRules = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'organization/rules', { idorganization: payload.idorganization, name: payload.name }, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const getMyOrganizationRoles = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'organization/myroles/' + payload.url, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'ORGANIZATION_ROLES', payload });
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const addAddress = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'organization/address', {
      idorganization: payload.idorganization,
      name: payload.name,
      recipient_name: payload.recipient_name,
      phone: payload.phone,
      address: payload.address,
      notes: payload.notes,
      idprovince: payload.idprovince,
      province: payload.province,
      idcity: payload.idcity,
      city: payload.city,
      idsuburb: payload.idsuburb,
      suburb: payload.suburb,
      idarea: payload.idarea,
      area: payload.area,
      address_2: payload.address_2,
      postalcode: payload.postalcode,
    }, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const getAddress = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'organization/address/' + payload.idorganization, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}