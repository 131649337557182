import React, { Component } from 'react';
import { connect } from "react-redux";

// import { forceLogout } from "../../../redux/actions/users";

import { getAllProduct } from "../../../redux/actions/products";
import { formatMoney, fetchVariation, fetchVariationProductPrice } from "../../../utils/format";

import {
    NavLink,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Skeleton } from '@mui/material';

class ListProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products:[],
            err:null
        };

        // this.onLogout = this.onLogout.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // onLogout(params) {
    //     console.log('onLogout');
    //     if(this.props.forceLogout().status === 200){
    //         this.props.history.push("/");
    //     }
    // }

    // toggleMenu(params){

    // }

    componentDidMount(){
        console.log('header mounted');
        console.log(this.props.organizationData.url);

        this.props.getAllProduct({url:this.props.organizationData.url}).then((res)=>{
            console.log(res);
            if(res.code === 200){
                this.setState({products:res.data});
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    render() {
        return (
            <Box className="products-container" sx={{padding:"10px"}}>
                <Box sx={{marginTop:"10px", marginBottom:"20px"}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className='title' sx={{fontSize:"18px", fontWeight:"800"}}>New Products</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display:"flex", justifyContent:"flex-end"}}>
                            <Typography className='title' sx={{color:"#000", fontSize:"18px", fontWeight:"800"}} component={NavLink} to={'./carts'}>Your Cart (0)</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        {this.state.products.map((item)=>{
                            return(
                                <Grid item xs={3} key={item.sku}>
                                    <Paper>
                                        <Skeleton variant="rectangular" sx={{width:"100%", height:"120px", aspectRatio:1}} />
                                        <Box sx={{padding:"5px", minHeight:"120px"}}>
                                            <Box>
                                                <Typography sx={{marginTop:"5px", marginBottom:"5px", display:"block", color:"#121212"}} component={NavLink} to={"./product/"+item.slug}>{item.name}</Typography>
                                            </Box>
                                            {(item.is_variation)?
                                            <>
                                                <Box sx={{minHeight:"50px"}}>
                                                    <Typography sx={{fontSize:"14px"}}>{fetchVariationProductPrice(item.variation)}</Typography>
                                                    <Typography sx={{fontSize:"14px"}}>{fetchVariation(item.variation)}</Typography>
                                                </Box>
                                                <Box sx={{display: "flex",alignItems: "center",flexDirection: "row",justifyContent: "space-between"}}>
                                                    <Button variant="contained" size="small" component={NavLink} to={"./product/"+item.slug}>Select Varian</Button>
                                                    <IconButton color="primary" aria-label="upload picture" component="label"><FavoriteIcon /></IconButton>
                                                </Box>
                                            </>:
                                            <>
                                                <Box sx={{minHeight:"50px"}}>
                                                    {(item.is_sale)?
                                                    <Typography sx={{fontSize:"14px", textDecoration:"line-through"}}>{formatMoney(item.price)}</Typography>:""}
                                                    <Typography sx={{fontSize:"14px"}}>{formatMoney(item.final_price)}</Typography>
                                                </Box>
                                                <Box sx={{display: "flex",alignItems: "center",flexDirection: "row",justifyContent: "space-between"}}>
                                                    <Button variant="contained" size="small" component={NavLink} to={"./product/"+item.slug}>Add to Cart</Button>
                                                    <IconButton color="primary" aria-label="upload picture" component="label"><FavoriteIcon /></IconButton>
                                                </Box>
                                            </>
                                            }
                                        </Box>
                                    </Paper>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllProduct: data => dispatch(getAllProduct(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListProduct));