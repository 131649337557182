import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    //Link,
    withRouter
} from "react-router-dom";

import { createOrganization } from "../../redux/actions/organizations";

import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import {
    // Stack,
    // TextField,
    // InputLabel,
} from '@mui/material';

class ModalAttachment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            name: "",
            description: "",
            phone: "",
            website: "",
            instagram: "",
            err: null
        };

        // this.handleChange = this.handleChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    // handleChange(e) {
    //     this.setState({
    //         [e.target.name]: e.target.name === "url" ? e.target.value.toLowerCase().replace(/\s/g, '') : e.target.value
    //     });
    // }

    handleSubmit(e) {
        e.preventDefault();
        console.log("Close");

        // this.props.createOrganization({
        //     url: this.state.url,
        //     name: this.state.name,
        //     description: this.state.description,
        //     phone: this.state.phone,
        //     website: this.state.website,
        //     instagram: this.state.instagram
        // }).then((res) => {
        //     console.log(res);
        //     if (res.code === 201) {
        //         this.setState({
        //             url: "",
        //             name: "",
        //             description: "",
        //             phone: "",
        //             website: "",
        //             instagram: ""
        //         }, function () {
        //             this.props.handleCreateSuccess()
        //         })
        //     } else {
        //         alert(res.message);
        //     }
        // }).catch((err) => {
        //     console.log(err);
        // })
    }

    componentDidMount() {
        console.log(this.props.previewData);
    }

    render() {
        return (
            <Modal
                open={this.props.openModal}
                onClose={this.props.handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-preview">
                    {/* <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: "10px" }}>
                        Preview Attachment
                    </Typography> */}
                    <img alt="preview attachment" src={this.props.previewData.fullurl} />
                    {/* <Box sx={{ display: "block" }} component="form" onSubmit={this.handleSubmit}> */}
                        {/* <img src={this.props.previewData.fullurl} /> */}
                        {/* <InputLabel htmlFor="url" sx={{ fontSize: "13px" }}>Organization url</InputLabel>
                        <TextField autoFocus onChange={this.handleChange} value={this.state.url} sx={{ fontSize: "13px" }} inputProps={{ style: { fontSize: "13px" } }} id="url" name="url" required size="small" fullWidth type="text" placeholder='myorganizatition' helperText="Your organization url, without space" />

                        <InputLabel htmlFor="name" sx={{ fontSize: "13px", marginTop: "10px" }}>Name</InputLabel>
                        <TextField onChange={this.handleChange} value={this.state.name} sx={{ fontSize: "13px" }} inputProps={{ style: { fontSize: "13px" } }} id="name" name="name" required size="small" fullWidth type="text" placeholder='My Organization' />

                        <InputLabel htmlFor="description" sx={{ fontSize: "13px", marginTop: "10px" }}>Description</InputLabel>
                        <TextField onChange={this.handleChange} value={this.state.description} sx={{ fontSize: "13px" }} inputProps={{ style: { fontSize: "13px" } }} id="description" name="description" size="small" fullWidth type="text" multiline rows={3} placeholder='My Organization Description' />

                        <InputLabel htmlFor="phone" sx={{ fontSize: "13px", marginTop: "10px" }}>Phone</InputLabel>
                        <TextField onChange={this.handleChange} value={this.state.phone} sx={{ fontSize: "13px" }} inputProps={{ style: { fontSize: "13px" } }} id="phone" name="phone" required size="small" fullWidth type="text" placeholder='+62821209861256' />

                        <InputLabel htmlFor="website" sx={{ fontSize: "13px", marginTop: "10px" }}>Website</InputLabel>
                        <TextField onChange={this.handleChange} value={this.state.website} sx={{ fontSize: "13px" }} inputProps={{ style: { fontSize: "13px" } }} id="website" name="website" size="small" fullWidth type="text" placeholder='https://www.myorganization.com' />

                        <InputLabel htmlFor="instagram" sx={{ fontSize: "13px", marginTop: "10px" }}>Instagram</InputLabel>
                        <TextField onChange={this.handleChange} value={this.state.instagram} sx={{ fontSize: "13px" }} inputProps={{ style: { fontSize: "13px" } }} id="instagram" name="instagram" size="small" fullWidth type="text" placeholder='myorganization' /> */}

                        {/* <Button disabled title='Sorry this feature not available yet' sx={{ display: "block", marginTop: "20px" }} disableElevation variant="contained">Create Organization</Button> */}
                        {/* <Button className='submit' label="Submit" variant="contained" type="submit" sx={{ display: "block", marginTop: "20px" }}>Create Organization</Button> */}
                    {/* </Box> */}
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createOrganization: data => dispatch(createOrganization(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalAttachment));