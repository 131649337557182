import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function RichTextComponent(props) {
  const {value, handleInput } = props;

  return(
    <ReactQuill
      value={value}
      onChange={handleInput}
      theme="snow"
    />
  )
}