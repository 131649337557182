import React, { Component } from 'react';
import { connect } from "react-redux";

// import { forceLogout } from "../../../redux/actions/users";

import {
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import { Skeleton } from '@mui/material';

class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: null
        };

        // this.onLogout = this.onLogout.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // onLogout(params) {
    //     console.log('onLogout');
    //     if(this.props.forceLogout().status === 200){
    //         this.props.history.push("/");
    //     }
    // }

    // toggleMenu(params){

    // }

    componentDidMount() {
        console.log('header mounted');
    }

    render() {
        return (
            <Box className="products-container" sx={{ padding: "10px" }}>
                <Box sx={{ marginTop: "10px", marginBottom: "20px" }}>
                    <Typography className='title' sx={{ fontSize: "20px", fontWeight: "800", textAlign: "center" }}>Orders</Typography>
                </Box>

                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                                <Grid container sx={{backgroundColor:"#e2e2e2", padding:"10px", borderRadius:"10px"}}>
                                    <Grid item xs={9}>
                                        <Typography>Product Name</Typography>
                                        <Typography sx={{ fontSize: "14px" }}>Rp100.000</Typography>
                                    </Grid>
                                    <Grid item xs={3} sx={{display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                        <Button variant="contained" size="medium">On Processing</Button>
                                    </Grid>
                                </Grid>
                        </Grid>

                        <Grid item xs={12}>
                                <Grid container sx={{backgroundColor:"#e2e2e2", padding:"10px", borderRadius:"10px"}}>
                                    <Grid item xs={9}>
                                        <Typography>Product Name</Typography>
                                        <Typography sx={{ fontSize: "14px" }}>Rp100.000</Typography>
                                    </Grid>
                                    <Grid item xs={3} sx={{display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                        <Button variant="contained" size="medium">On Processing</Button>
                                    </Grid>
                                </Grid>
                        </Grid>

                        <Grid item xs={12}>
                                <Grid container sx={{backgroundColor:"#e2e2e2", padding:"10px", borderRadius:"10px"}}>
                                    <Grid item xs={9}>
                                        <Typography>Product Name</Typography>
                                        <Typography sx={{ fontSize: "14px" }}>Rp100.000</Typography>
                                    </Grid>
                                    <Grid item xs={3} sx={{display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                        <Button variant="contained" size="medium">On Processing</Button>
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // forceLogout: data => dispatch(forceLogout(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));