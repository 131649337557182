import React, { Component } from 'react';
import { connect } from "react-redux";

import { getOrganizationRequest, approveOrganization } from "../../../redux/actions/organizations";

import {
    withRouter
} from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
// import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';

import { Box, Paper, Typography } from '@mui/material';

class AllRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenuAction: false,
            anchorEl: null,
            members: [],
            err: null
        };

        this.loadData = this.loadData.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleApprove = this.handleApprove.bind(this);
        this.handleReject = this.handleReject.bind(this);
    }

    toggleMenu(params) {
        this.setState({ openMenuAction: true, anchorEl: params.currentTarget })
    }

    handleApprove(e) {
        console.log("handleApprove");
        console.log(e.currentTarget.getAttribute('data-iduser'));
        console.log(e.currentTarget.getAttribute('data-idinvitation'));

        this.props.approveOrganization({ url: this.props.organizationData.url, iduser: parseInt(e.currentTarget.getAttribute('data-iduser')) }).then((res) => {
            console.log(res);
            if (res.code === 200) {
                this.loadData();
            } else {
                alert(res.message);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    handleReject(e) {
        console.log("handleReject");
        console.log(e.currentTarget.getAttribute('data-iduser'));
        console.log(e.currentTarget.getAttribute('data-idinvitation'));
    }

    loadData() {
        this.props.getOrganizationRequest({ url: this.props.organizationData.url }).then((res) => {
            this.setState({ members: res.data });
        }).catch((err) => {
            console.log(err);
        })
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <Box className="box-container">
                <Paper sx={{ width: "100%", mb: 2 }}>
                    {this.state.members.length !== 0 ?
                        <>
                            <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Box><Typography sx={{ fontSize: "20px", fontWeight: "800" }}>All Request</Typography></Box>
                            </Box>
                            <TableContainer>
                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                                    <TableHead>
                                        <TableRow hover role="checkbox" tabIndex={-1} className="header-all-product-table">
                                            <TableCell component="th" align="center"><span style={{ color: "#fff" }}>No.</span></TableCell>
                                            <TableCell component="th" align="left"><span style={{ color: "#fff" }}>Name</span></TableCell>
                                            <TableCell component="th" align="left"><span style={{ color: "#fff" }}>Email</span></TableCell>
                                            <TableCell component="th" align="center"><span style={{ color: "#fff" }}>Role</span></TableCell>
                                            <TableCell component="th" align="center"><span style={{ color: "#fff" }}>Action</span></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody className='body-all-product-table'>
                                        {this.state.members.map((item, index) => {
                                            let odd = "odd";
                                            if (index % 2 === 0) {
                                                odd = "even"
                                            }

                                            return (
                                                <TableRow className={odd} key={item.id}>
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="left">{item.invitations.fullname}</TableCell>
                                                    <TableCell align="left">{item.invitations.email}</TableCell>
                                                    <TableCell align="center">Waiting</TableCell>
                                                    <TableCell align="center">
                                                        <Box>
                                                            <Button
                                                                id="demo-positioned-button"
                                                                aria-controls={this.state.openMenuAction ? "demo-positioned-menu" : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={this.state.openMenuAction ? "true" : undefined}
                                                                onClick={this.toggleMenu}
                                                                variant="contained"
                                                                label="Action"
                                                                size="small"
                                                            >
                                                                Action
                                                            </Button>

                                                            <Menu
                                                                id="demo-positioned-menu"
                                                                aria-labelledby="demo-positioned-button"
                                                                anchorEl={this.state.anchorEl}
                                                                open={this.state.openMenuAction}
                                                                onClose={() => {
                                                                    this.setState({ openMenuAction: false, anchorEl: null })
                                                                }}
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >
                                                                <MenuItem data-idinvitation={item.id} data-iduser={item.invitations.id} name={item.id} onClick={this.handleApprove}>Approve</MenuItem>
                                                                {/* <MenuItem data-idinvitation={item.id} data-iduser={item.invitations.id} name={item.id} onClick={this.handleReject}>Reject</MenuItem> */}
                                                            </Menu>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={this.state.members.length}
                                rowsPerPage={10}
                                page={0}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={() => {
                                    console.log("page change")
                                }}
                                onRowsPerPageChange={() => {
                                    console.log("rows perpage change")
                                }}
                            /></>
                        : <Box sx={{ padding: "20px", textAlign: "center" }}>No Request Found</Box>}
                </Paper>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOrganizationRequest: data => dispatch(getOrganizationRequest(data)),
        approveOrganization: data => dispatch(approveOrganization(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllRequest));