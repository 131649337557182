import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Link,
    Redirect,
    withRouter,
} from "react-router-dom";

import { forgotpassword } from '../redux/actions/users'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import '../styles/login.css';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "Please Wait",
            email: "",
            isLoading: false,
            err: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ isLoading: true, err: null });
        const { email } = this.state;

        this.props.forgotpassword({ email }).then((res) => {
            console.log(res);
            if (res.code === 200) {
                this.setState({ err: null, notes: "Reset password success, link sent to your email" });
            } else {
                this.setState({ isLoading: false, err: res.message });
            }
        }).catch((err) => {
            console.log(err);
            this.setState({ isLoading: false, err: "Something Error" });
        })
    }

    render() {
        const { email, err, isLoading, notes } = this.state;
        return (
            <Box className='login-container fullheight'>
                {(this.props.isLogin !== true) ? '' : <Redirect to="/selectorganization"></Redirect>}
                <Box className='login-paper verticalalign'>
                    <h1>Forgot Password</h1>
                    <Box component={"form"} onSubmit={this.handleSubmit}>
                        <span className="inputWrapper">
                            <label htmlFor="email">Email: </label>
                            <input style={{ textAlign: "center" }} autoComplete="email" required id="email" type="email" placeholder="email@email.com" value={email} onChange={this.handleChange}></input>
                        </span>
                        <span className="error-message">
                            {(err !== null) ? <>{err}</> : ""}
                        </span>
                        {(isLoading === false) ? <span className="inputWrapper">
                            <Button type="submit" className="login-btn" variant="contained" color="primary">Reset Password</Button>
                        </span> : <span className="loading-message">{notes}</span>}
                    </Box>
                    <Link style={{ cursor: "pointer", fontSize: "14px", marginTop: "10px" }} to="./login">Back to Login</Link>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        forgotpassword: data => dispatch(forgotpassword(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));