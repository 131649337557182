import React, { Component } from 'react';
import { connect } from "react-redux";

// import { forceLogout } from "../../redux/actions/users";

import {
    withRouter
} from "react-router-dom";

import {
    Box,
    // Grid,
    // Drawer,
    // Button,
    // Select,
    // Checkbox,
    // MenuItem,
    // TextField,
    Typography,
    // InputLabel,
    // Autocomplete,
    // FormControlLabel,
} from '@mui/material';

import {
    format
} from 'date-fns';

class FeedsComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: null
        };

        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // toggleMenu(){ }

    // componentDidMount() {
    //     console.log('header mounted');
    // }

    render() {
        return (
            <Box sx={{ marginTop: "10px" }}>
                <Typography>Comments</Typography>
                {this.props.comments.length !== 0 ? <>
                    {this.props.comments.map((item) => {
                        return (
                            <Box key={item.id} className="comment-item">
                                <Box className="comment-item-user">{item.user.fullname}</Box>
                                <Box sx={{ whiteSpace: "break-spaces", fontSize: "14px", marginTop: "5px", display: "block" }}>{item.description}</Box>
                                <Box sx={{ marginTop: "5px", fontSize: "12px" }}>
                                    {format(new Date(item.created_at), "dd MMM yyyy - kk:mm:ss")}
                                </Box>
                            </Box>
                        )
                    })}</> : <Box className="nocomment-found">No Comment Found</Box>}
            </Box>


        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // forceLogout: data => dispatch(forceLogout(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedsComment));