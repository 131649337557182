import React, { Component } from 'react';
import { connect } from "react-redux";

import { getMyActivity } from "../../redux/actions/users";

import {
    withRouter
} from "react-router-dom";

import {
    Box,
    // Card,
    // Chip,
    // Button,
    // Select,
    // Checkbox,
    // MenuItem,
    // TextField,
    Typography,
    // FormControlLabel
} from '@mui/material';

import { format } from "date-fns";

class Activity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: [],
            formattedActivities: [],
            err: null
        };

        // this.formatList = this.formatList.bind(this);
    }

    // formatList(){ }

    componentDidMount() {
        this.props.getMyActivity({ id: this.props.organizationData.id }).then((res) => {
            // console.log(this.props.organizationData.id);
            if (res.code === 200) {
                // this.setState({ activities: res.data }, function () {
                let lateDate = "";
                let groupDateArr = [];
                let tmpArr = [];
                for (let i = 0; i < res.data.length; i++) {
                    if (i === 0) {
                        tmpArr.push(res.data[i]);
                        lateDate = format(new Date(res.data[i].date), "dd MMM yyyy");
                    } else {
                        if (lateDate !== format(new Date(res.data[i].date), "dd MMM yyyy")) {
                            groupDateArr.push(tmpArr);
                            tmpArr = []
                            lateDate = format(new Date(res.data[i].date), "dd MMM yyyy");
                        } else {
                            tmpArr.push(res.data[i]);
                        }
                    }
                }

                if (tmpArr.length !== 0) {
                    if (groupDateArr.length === 0) {
                        groupDateArr.push(tmpArr);
                    }
                }

                this.setState({ formattedActivities: groupDateArr })
                // });
            } else {
                alert("Error fetch activities")
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    render() {
        return (
            <Box className="panel home-panel">
                <Box sx={{ padding: "10px" }}>
                    <Typography sx={{ fontSize: "20px", fontWeight: "800" }}>Activities</Typography>
                    {this.state.formattedActivities.length !== 0 ? <>
                        {this.state.formattedActivities.map((item, index) => {
                            if (item.length !== 0) {
                                return (
                                    <Box key={index}>
                                        <Box sx={{ fontSize: "18px", fontWeight: "800", marginTop: "10px", marginBottom: "10px" }}>{format(new Date(item[0].date), "EEEE, dd MMM yyyy")}</Box>
                                        <Box>
                                            {item.map((child) => {
                                                return (
                                                    <Box key={child._id} sx={{ height: "30px", display: "flex", alignItems: "center" }}>
                                                        {format(new Date(child.date), "HH:mm")} - {child.description}
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                )
                            } else {
                                return "";
                            }
                        })}</> : <Box sx={{ marginTop: "10px" }}>No Activities</Box>}
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMyActivity: data => dispatch(getMyActivity(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activity));