import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getDetailSection } from "../../redux/actions/tasks"

import {
    Box,
    Drawer,
    Button,
    // Avatar,
    // Stack,
    Select,
    // Checkbox,
    MenuItem,
    TextField,
    Typography,
    // FormControlLabel
} from '@mui/material';

// import {
//     format,
//     // intervalToDuration,
//     // formatDistanceToNow
// } from 'date-fns';

class AddLaneDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tmpCardForm: {},
            err: null
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        if (e.target.name !== "name") {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    [e.target.name]: e.target.value
                }
            });
        } else {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    [e.target.name]: e.target.value,
                    title: e.target.value,
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.openLaneDrawer !== this.props.openLaneDrawer) {
            if (this.props.openLaneDrawer !== false) {
                this.props.getDetailSection({ id: this.props.laneEditId }).then((res) => {
                    console.log(res.data);
                    this.setState({
                        tmpCardForm: res.data
                    })
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }

    render() {
        return (
            <Drawer
                anchor="right"
                open={this.props.openLaneDrawer}
                onClose={(e) => {
                    this.props.onClose()
                }}
            >
                <Box
                    role="presentation"
                    sx={{
                        width: "500px",
                        padding: "10px",
                        borderRadius: "5px"
                    }}>
                    <Box sx={{ position: "relative" }}>
                        <Typography sx={{ marginBottom: "10px" }}>
                            Edit Lane
                        </Typography>
                    </Box>
                    <Box>
                        <TextField autoFocus value={this.state.tmpCardForm?.name || ""} onChange={this.handleChange} name="name" inputProps={{ style: { fontSize: "13px", outline: 0 } }} sx={{ display: "block", marginBottom: "5px", outline: "none" }} fullWidth size='small' placeholder="Name" />
                        <Select
                            inputProps={{ style: { fontSize: "13px", outline: 0 } }}
                            sx={{ fontSize: "13px", marginBottom: "5px" }}
                            size="small"
                            name="type"
                            value={this.state.tmpCardForm?.type || 1}
                            displayEmpty
                            onChange={this.handleChange}
                        >
                            <MenuItem sx={{ fontSize: "13px" }} value={1}>To Do</MenuItem>
                            <MenuItem sx={{ fontSize: "13px" }} value={2}>Doing</MenuItem>
                            <MenuItem sx={{ fontSize: "13px" }} value={3}>Done</MenuItem>
                            <MenuItem sx={{ fontSize: "13px" }} value={4}>Information</MenuItem>
                            <MenuItem sx={{ fontSize: "13px" }} value={5}>Goal</MenuItem>
                            <MenuItem sx={{ fontSize: "13px" }} value={6}>Archive</MenuItem>
                        </Select>
                        <TextField value={this.state.tmpCardForm?.description || ""} onChange={this.handleChange} multiline rows={5} name="description" inputProps={{ style: { fontSize: "13px", outline: 0 } }} sx={{ display: "block", marginBottom: "5px", outline: "none" }} fullWidth size='small' placeholder="Description" />
                    </Box>
                    <Box>
                        <Button variant="contained" size="small" sx={{ marginRight: "5px", marginTop: "10px" }} onClick={() => {
                            if (this.state.tmpCardForm?.name !== undefined) {
                                this.props.handleSaveButton(this.state.tmpCardForm, this.props.laneEditId)
                            } else {
                                alert("Name cannot be empty");
                            }
                        }}>Update Lane</Button>

                        <Button variant="contained" size="small" color="secondary" sx={{ marginRight: "5px", marginTop: "10px" }} onClick={(evt) => {
                            evt.preventDefault();
                            this.props.onClose();
                        }}>Cancel</Button>
                    </Box>
                </Box>
            </Drawer>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDetailSection: userData => dispatch(getDetailSection(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddLaneDrawer));