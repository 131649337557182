import React, { Component } from 'react';
import { connect } from "react-redux";

import { calculateRate } from "../../redux/actions/addresses";
import { getAddress as getOrganizationAddress } from "../../redux/actions/organizations";
import { getAddress, getCart } from "../../redux/actions/users";
import { formatMoney } from "../../utils/format";

import {
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, Select, MenuItem, Radio, RadioGroup } from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAddress: null,
            addresses: [],
            organizationAddress: null,
            shippings: [],
            payment_methods: [],
            idshipping: null,
            idpaymentmethod: null,
            typeshipping: 'regular',
            selectedShipping: null,
            use_insurance:false,
            must_use_insurance: false,
            value: 100000,
            weight: 1,
            width: 30,
            length: 30,
            height: 10,
            cart: null,
            insurance_fee: 0,
            shippingcost: 0,
            total_gross: 0,
            admin_fee: 0,
            subtotal: 0,
            total: 0,
            err: null
        };

        this.onSelectChange = this.onSelectChange.bind(this);
        this.onPricingChange = this.onPricingChange.bind(this);
        this.fetchShipping = this.fetchShipping.bind(this);
    }

    fetchShipping() {
        let myIdx = this.state.addresses.findIndex(x => x.id === this.state.selectedAddress);
        let address = this.state.addresses[myIdx];

        this.props.calculateRate({
            type: this.state.typeshipping, //"instant", "regular" , "express", "trucking" , "same-day"
            value: this.state.total,
            suburb_destination: address.idsuburb,
            area_destination: address.idarea,
            suburb_origin: this.state.organizationAddress.idsuburb,
            area_origin: this.state.organizationAddress.idarea,
            weight: this.state.weight,
            width: this.state.width,
            length: this.state.length,
            height: this.state.height,
        }).then((res) => {
            console.log(res);
            console.log(res.data.data.pricings);
            this.setState({ shippings: res.data.data.pricings });
        }).catch((err) => {
            console.log(err);
        })
    }

    onPricingChange(e) {
        let trgt = e.target.name;
        let vlue = e.target.value;

        this.setState({ [e.target.name]: e.target.value }, async function () {
            if (trgt === "typeshipping") {
                this.setState({ idshipping: null }, function () {
                    this.fetchShipping();
                });
            } else {
                let idxShipping = -1;
                for (let i = 0; i < this.state.shippings.length; i++) {
                    console.log(this.state.shippings[i].rate.id);
                    if (this.state.shippings[i].rate.id === parseInt(vlue)) {
                        idxShipping = i
                    }
                }
                let selectedShipping = this.state.shippings[idxShipping];
                this.setState({ 
                    selectedShipping: selectedShipping,
                    insurance_fee: selectedShipping.insurance_fee,
                    must_use_insurance: selectedShipping.must_use_insurance,
                    shippingcost: selectedShipping.base_price
                });
            }
        });
    }

    onSelectChange(e) {
        this.setState({ selectedAddress: e.target.value, idshipping: null }, function () {
            this.fetchShipping();
        });
    }

    componentDidMount() {
        this.props.getCart({ url: this.props.organizationData.url }).then(async (res) => {
            let totalTmp = 0;
            await Promise.all(
                res.data.products.map((item) => {
                    totalTmp += (item.products_detail.final_price * item.quantity)
                    console.log(item.products_detail.final_price)
                    return totalTmp;
                })
            )
            this.setState({ total: totalTmp, cart: res.data });

            this.props.getAddress().then(async (res) => {
                if (res.code === 200) {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].is_primary) {
                            console.log(res.data[i].id);
                            this.setState({ selectedAddress: res.data[i].id });
                        }
                    }
                    this.setState({ addresses: res.data });
                }
            }).catch((err) => {
                console.log(err);
            });

            this.props.getOrganizationAddress({ idorganization: this.props.organizationData.id }).then((res) => {
                if (res.data.length !== 0) {
                    this.setState({ organizationAddress: res.data[0] }, function () {
                        if (this.state.selectedAddress !== null) {
                            this.fetchShipping();
                        }
                    })
                } else {
                    alert("Organization doesn't have an address");
                }
            }).catch((err) => {
                console.log(err);
            })

        }).catch((err) => {
            console.log(err);
        })
    }

    render() {
        return (
            <Box className="products-container" sx={{ padding: "10px" }}>
                <Box sx={{ marginTop: "10px", marginBottom: "20px" }}>
                    <Typography className='title' sx={{ fontSize: "20px", fontWeight: "800", textAlign: "center" }}>Checkout</Typography>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ display: "block" }}>
                            <Typography sx={{padding:"0px", marginBottom:"10px"}}>Cart Items</Typography>
                            {this.state.cart?.products?.map((item, index) => {
                                return (
                                    <Grid item xs={12} key={index} style={{ display: "block", marginBottom:"10px" }}>
                                        <Paper sx={{padding:"10px"}}>
                                            <Typography>
                                                {item.products_detail.name}
                                                {(item.products_detail.is_variation) ? <> - {item.products_detail?.variant?.attribute}</> : ""}
                                            </Typography>
                                            <Typography>{formatMoney(item.products_detail.final_price)} x {item.quantity} = {formatMoney(item.products_detail.final_price * item.quantity)}</Typography>
                                        </Paper>
                                    </Grid>
                                )
                            })}
                            <Box sx={{padding:"10px"}}>
                                <Typography sx={{fontSize:"18px", fontWeight:"800"}}>Total Items : {formatMoney(this.state.total)}</Typography>
                            </Box>
                            <hr />
                        </Grid>
                        <Grid item xs={12} style={{ display: "block" }}>
                            <Typography sx={{padding:"0px", marginBottom:"10px"}}>Send from</Typography>
                            <Box>
                                {this.state.organizationAddress?.province}<br />
                                {this.state.organizationAddress?.city}
                                {this.state.organizationAddress?.name}
                                {this.state.organizationAddress?.address}
                            </Box>
                        </Grid>
                        <Grid item xs={12} style={{ display: "block" }}>
                            <FormControl>
                                <p>
                                    Send To<br/>
                                    <span style={{color:"#919191"}}>Select Address</span>
                                </p>
                                <Select
                                    size='small'
                                    displayEmpty
                                    value={this.state.selectedAddress || ""}
                                    onChange={this.onSelectChange}
                                    name="address"
                                >
                                    <MenuItem value="">Select Address</MenuItem>
                                    {this.state.addresses.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ display: "block" }}>
                            <p>Select Shipping Type</p>
                            <Select
                                size='small'
                                displayEmpty
                                value={this.state.typeshipping || ""}
                                onChange={this.onPricingChange}
                                name="typeshipping"
                                disabled={this.state.selectedAddress !== null ? false : true}
                            >
                                <MenuItem value="">Select Type</MenuItem>
                                <MenuItem value="instant">Instant</MenuItem>
                                <MenuItem value="express">Express</MenuItem>
                                <MenuItem value="regular">Regular</MenuItem>
                                <MenuItem value="trucking">Trucking</MenuItem>
                                <MenuItem value="same-day">Sameday</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} style={{ display: "block" }}>
                            <p>Select Shipping</p>
                            <RadioGroup
                                value={this.state.idshipping || ""}
                                onChange={this.onPricingChange}
                                name="idshipping"
                            >
                                {this.state.shippings.length === 0 ? <Box sx={{ backgroundColor: "#313131", color: "#fff", width: "fit-content", padding: "10px", borderRadius: "5px" }}>No Shipping Available</Box> : ""}                               
                                {this.state.shippings.map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            disabled={this.state.selectedAddress !== null ? false : true}
                                            className={"radio-address"}
                                            value={item.rate.id}
                                            control={<Radio />}
                                            label={
                                                <Box className="label-logistic-item">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={8}>
                                                            <Typography>{item.logistic.name} - {formatMoney(item.total_price)}</Typography>
                                                            <Typography>{item.rate.name} | {item.max_day === item.min_day ? <>{item.max_day} Hari</> : <>{item.min_day} - {item.max_day} Hari</>}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                            <img alt={item.logistic.name+" address logo"} src={item.logistic.logo_url} height="30px" width="auto" />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            }
                                        />
                                    )
                                })}
                            </RadioGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox value={this.state.use_insurance} onChange={(e, f)=>{
                                    this.setState({use_insurance:f}, function(){
                                        console.log(this.state.use_insurance);
                                    })
                                }}/>} label="Use Insurance" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} style={{ display: "block" }}>
                            <p>Select Payment</p>
                            <RadioGroup
                                // value={this.state.idpaymentmethod || ""}
                                // onChange={this.onPricingChange}
                                name="idpaymentmethod"
                            >
                                <FormControlLabel value="qris" className={"radio-payment-method"} control={<Radio />}
                                    label={
                                        <Box className="label-payment_method-item">
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Typography>QRIS - Fee Rp6.500</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    QRIS
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                />
                                <FormControlLabel value="bca" className={"radio-payment-method"} control={<Radio />}
                                    label={
                                        <Box className="label-payment_method-item">
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Typography>BCA VA - Fee Rp6.500</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    BCA
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                />
                                <FormControlLabel value="mandiri" className={"radio-payment-method"} control={<Radio />}
                                    label={
                                        <Box className="label-payment_method-item">
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Typography>Mandiri VA - Fee Rp6.500</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    Mandiri
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                />
                                <FormControlLabel value="bri" className={"radio-payment-method"} control={<Radio />}
                                    label={
                                        <Box className="label-payment_method-item">
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Typography>BRI VA - Fee Rp6.500</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    BRI
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                />
                                <FormControlLabel value="indomaret" className={"radio-payment-method"} control={<Radio />}
                                    label={
                                        <Box className="label-payment_method-item">
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Typography>Indomaret - Fee Rp6.500</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    Indomaret
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                />
                                <FormControlLabel value="alfamart" className={"radio-payment-method"} control={<Radio />}
                                    label={
                                        <Box className="label-payment_method-item">
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Typography>Alfamart - Fee Rp6.500</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                    Alfamart
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                />
                            </RadioGroup>
                        </Grid>                        
                    </Grid>
                </Box>
                <Box sx={{ marginTop: "20px", textAlign: "center", maxWidth:"400px", display: "block", marginLeft: "auto", marginRight: "auto"}}>
                    <Grid container>
                        <Grid item xs={6} sx={{textAlign:"left", padding:"10px"}}>
                            <Typography>Total Cart :</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:"right", padding:"10px"}}>
                            <Typography>{formatMoney(this.state.total)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sx={{textAlign:"left", padding:"10px"}}>
                            <Typography>Shipping Cost :</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:"right", padding:"10px"}}>
                            <Typography>{formatMoney(this.state.shippingcost)}</Typography>
                        </Grid>
                    </Grid>
                    {this.state.use_insurance?
                    <Grid container>
                        <Grid item xs={6} sx={{textAlign:"left", padding:"10px"}}>
                            <Typography>Insurance Fee :</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:"right", padding:"10px"}}>
                            <Typography>{formatMoney(this.state.insurance_fee)}</Typography>
                        </Grid>
                    </Grid>:""}
                    <Grid container>
                        <Grid item xs={6} sx={{textAlign:"left", padding:"10px"}}>
                            <Typography>Admin Fee :</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:"right", padding:"10px"}}>
                            <Typography>{formatMoney(this.state.admin_fee)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sx={{textAlign:"left", padding:"10px"}}>
                            <Typography>Total :</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:"right", padding:"10px"}}>
                            <Typography>{formatMoney(this.state.total + this.state.shippingcost + this.state.admin_fee + (this.state.use_insurance?this.state.insurance_fee:0))}</Typography>
                        </Grid>
                    </Grid>
                    <Button variant="contained" size="medium" sx={{marginTop:"10px"}}>Checkout</Button>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCart: data => dispatch(getCart(data)),
        getAddress: data => dispatch(getAddress(data)),
        calculateRate: data => dispatch(calculateRate(data)),
        getOrganizationAddress: data => dispatch(getOrganizationAddress(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));