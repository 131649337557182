import React, { Component } from 'react';
import { connect } from "react-redux";

import { forceLogout } from "../redux/actions/users";

import {
    Link,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // account_type: 0,
            // mobileMenu: false,
            // logoIcon: '',
            err: null
        };

        this.onLogout = this.onLogout.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    onLogout(params) {
        // console.log('onLogout');
        if (this.props.forceLogout().status === 200) {
            this.props.history.push("/");
        }
    }

    toggleMenu(params) {

    }

    // componentDidMount(){
    //     console.log('header mounted');
    //     console.log(this.props.organizationData);
    // }

    // componentDidUpdate(){
    //     console.log('header updated');
    //     console.log(this.props.organizationData);
    // }

    render() {
        return (
            <Box className='main-header'>
                <Box className='logo-wrapper'>
                    {(this.props.isLogin !== true) ? <Link to='/'>Hasler</Link> : <>{this.props.organizationData?.name || "Hasler"}</>}
                </Box>
                <Box className='nav-link'>
                    <ul>
                        {/* {(this.props.isLogin === false) ? "" : <li><Link onClick={(e) => {
                            console.log("Open Notifications");
                        }} to='#'>Notifications</Link></li>} */}
                        {(this.props.isLogin === false) ? "" : <li><Link to='/selectorganization'>Organization</Link></li>}
                        {(this.props.isLogin === false) ? <li><Link to='/register'>Register</Link></li> : ""}
                        {(this.props.isLogin === false) ? <li><Link to='/login'>Login</Link></li> : <li><Link to='#' onClick={this.onLogout}>Logout</Link></li>}
                        {/* <li>Home</Link></li> */}
                        {/* <li><Link to='/dashboard'>Dashboard</Link></li> */}
                        {/* <li><Link to='/about'>About</Link></li> */}
                    </ul>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        forceLogout: data => dispatch(forceLogout(data))
        //logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));