import { Box, Typography } from '@mui/material';
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { forceLogout } from "../../redux/actions/users";

import {
    withRouter
} from "react-router-dom";

// import backgroundLeft from '../assets/velizar-ivanov-xZjiUR7SWIQ-unsplash.jpg';
// import backgroundLeft from '../assets/annie-spratt-dWYU3i-mqEo-unsplash.jpg';

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // account_type:0,
            // mobileMenu:false,
            // logoIcon:'',
            err: null
        };

        // this.onLogout = this.onLogout.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // onLogout(params) {
    //     console.log('onLogout');
    //     if(this.props.forceLogout().status === 200){
    //         this.props.history.push("/");
    //     }
    // }

    // toggleMenu(params){

    // }

    // componentDidMount() {
    //     console.log('Landing');
    // }

    render() {
        return (
            <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
                    <Typography variant="h4" sx={{ marginTop: "20px", xs: { fontSize: "14px" } }}>Privacy Policy</Typography>
                    <Typography variant="body1" sx={{ marginTop: "20px" }}>
                        Hasler
                        <br /><br />
                        Effective Date: 1 August 2023
                        <br /><br />
                        At Hasler, we are committed to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide when using our SaaS project management tools ("Services"). By accessing or using our Services, you consent to the practices described in this Privacy Policy.
                        <br /><br />
                        1. Information We Collect:
                        <br />
                        We collect various types of information to provide and improve our Services. The information we collect may include:
                        <br /><br />
                        Personal Information: When you sign up for an account, we may collect personal information such as your name, email address, and contact details.
                        <br /><br />
                        Project Data: We collect information related to the projects you create and manage using our Services. This may include project names, descriptions, tasks, files, and communication within the platform.
                        <br /><br />
                        Usage Data: We collect data about your interactions with our Services, including log data, device information, IP address, browser type, and pages visited.
                        <br /><br />
                        2. How We Use Your Information:
                        <br />
                        We use the collected information for various purposes, including but not limited to:
                        <br /><br />
                        Providing and maintaining our Services.
                        Improving, personalizing, and developing new features.
                        Sending you notifications, updates, and relevant information about your account and projects.
                        Communicating with you regarding customer support, inquiries, and feedback.
                        Monitoring and analyzing usage patterns to enhance user experience and security.
                        <br /><br />
                        3. How We Share Your Information:
                        <br />
                        We may share your information in the following ways:
                        <br /><br />
                        Service Providers: We may engage third-party service providers to assist us in delivering our Services, such as hosting, analytics, and customer support.
                        <br /><br />
                        Legal Requirements: We may disclose your information in response to legal obligations, court orders, or government requests.
                        <br /><br />
                        Protection of Rights: We may share information when necessary to protect our rights, privacy, safety, or the rights, privacy, or safety of others.
                        <br /><br />
                        Business Transfers: If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.
                        <br /><br />
                        4. Data Security:
                        <br />
                        We are committed to ensuring the security of your information. We implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, or destruction.
                        <br /><br />
                        5. Your Choices:
                        <br />
                        You can access and update certain personal information through your account settings.
                        You can choose to unsubscribe from marketing communications at any time by following the provided instructions.
                        You have the right to request access to and deletion of your personal information, subject to legal requirements.
                        <br /><br />
                        6. Children's Privacy:
                        <br />
                        Our Services are not intended for individuals under the age of [13/16/18]. We do not knowingly collect personal information from children without parental consent. If you believe we have collected information from a child, please contact us immediately.
                        <br /><br />
                        7. Changes to Privacy Policy:
                        <br />
                        We may update our Privacy Policy from time to time to reflect changes in our practices and Services. We will notify you of any significant changes and obtain your consent if required by applicable laws.
                        <br /><br />
                        8. Contact Us:
                        <br />
                        If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us <a href="mailto:dev@dengansenanghati.com" alt="contact mail">here</a>.
                        <br /><br />
                        By using our Hasler SaaS Project Management Tools, you agree to the terms outlined in this Privacy Policy.
                        <br /><br />
                    </Typography>
                </Box>
            </Box >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // forceLogout: data => dispatch(forceLogout(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy));