import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import App from './App'
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="106294465444-2c906sqb40a1vgir0euov784jp8ohlrm.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Provider>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();

// Client ID
// 106294465444-2c906sqb40a1vgir0euov784jp8ohlrm.apps.googleusercontent.com

// Client secret
// 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