import React, { Component } from 'react';
import { connect } from "react-redux";

import {
    // NavLink,
    Redirect,
    withRouter,
} from "react-router-dom";

import { searchOrganization, joinOrganization, getMyOrganization, getOrganizationInvitation } from '../redux/actions/organizations'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import ListOrganization from '../components/organization/listOrganization';

import CreateOrganization from '../components/modal/createOrganization';
import RequestOrganization from '../components/modal/requestOrganization';
import SearchOrganization from '../components/modal/searchOrganization';
import InvitationOrganization from '../components/modal/invitationOrganization';

import { CoachMark } from 'react-coach-mark';
import { Stack } from '@mui/material';

class SelectOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            tutorial: false,
            openModalRequest: false,
            openModalInvitation: false,
            openModalSearch: false,
            openModalCreate: false,
            organizations: [],
            invitations: [],
            err: null
        };

        this.refSearch = React.createRef(null);
        this.refCreate = React.createRef(null);
        this.refContainer = React.createRef(null);

        this.handleCreateSuccess = this.handleCreateSuccess.bind(this);
        this.handleCloseInvitationModal = this.handleCloseInvitationModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.loadData = this.loadData.bind(this);
        this.nextStep = this.nextStep.bind(this);
    }

    nextStep() {
        if (this.state.step === 3) {
            localStorage.setItem("tutorial", true);
        }

        this.setState((prevState, props) => ({
            step: prevState.step + 1
        }));
    }

    handleCreateSuccess() {
        this.setState({ openModalCreate: false }, function () {
            this.loadData()
        });
    }

    handleCloseInvitationModal() {
        this.setState({ openModalInvitation: false }, function () {
            // console.log("Load data new!");
            this.loadData();
        });
    }

    handleCloseModal() {
        this.setState({ openModalRequest: false, openModalInvitation: false, openModalCreate: false, openModalSearch: false })
    }

    loadData() {
        this.props.getMyOrganization({}).then((res) => {
            if (res.data.length !== 0) {
                this.setState({ organizations: res.data });
            }

            this.props.getOrganizationInvitation({}).then((res) => {
                this.setState({ invitations: res.data });
            }).catch((err) => {
                console.log(err);
            })
        }).catch((err) => {
            console.log(err);
        })
    }

    componentDidMount() {
        this.loadData()
        // console.log(localStorage.getItem("tutorial"));
        if (localStorage.getItem("tutorial") === null) {
            this.setState({ tutorial: true })
        }

        // this.setState({
        //     coach: this.ICoachProps = {
        //         activate: true,
        //         component: <div>Any Component You Want</div>,
        //         reference: this.ref1,
        //         tooltip: { position: 'bottom' }
        //     }
        // });
        // [{
        //     activate: true,
        //     component: <div>I can do this </div>,
        //     reference: this.ref1,
        //     tooltip: { position: 'bottom' }
        // },
        // {
        //     activate: false,
        //     component: <div>I can be long.</div>,
        //     reference: this.ref2,
        //     tooltip: { position: 'top' }
        // }]
        // })

        // this.coachMarkConfig = new ICoachProps({
        //     activate: true,
        //     component: <div>Any Component You Want</div>,
        //     reference: this.ref1,
        //     tooltip: { position: 'bottom' }
        // })

        // this.setState({
        //     coachMarkConfig: {
        //         activate: true,
        //         component: <div>Any Component You Want</div>,
        //         reference: this.ref1,
        //         tooltip: { position: 'bottom' }
        //     }
        // })

        // ICoachProps = {
        //     activate: true,
        //     component: <div>Any Component You Want</div>,
        //     reference: this.ref1,
        //     tooltip: { position: 'bottom' }
        // }
    }

    render() {
        const { step, organizations } = this.state;

        return (
            <Box className='selectorganization-container fullheight'>
                {(this.props.isLogin !== true) ? <Redirect to="/login"></Redirect> : ""}
                <Box className='select-organization-section verticalalign' ref={this.refContainer}>
                    <Typography className='title-container' ref={this.ref1}>Select Organization</Typography>
                    <ListOrganization organizations={organizations} />
                    {organizations.length === 0 ?
                        <Box className="select-organization-container">
                            <Box className='select-organization-container-item'>
                                <Box className='menu left-item'><Typography className='title'>No Organization Joined</Typography></Box>
                            </Box>
                        </Box> : ""}
                </Box>
                <Box className='noselect select-organization-section verticalalign' ref={this.refSearch} sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                        e.preventDefault()
                        this.setState({ openModalSearch: true });
                    }}
                >
                    <h3 className='noselect'>Search Organization</h3>
                </Box>
                <Box className='noselect select-organization-section verticalalign' ref={this.refCreate} sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                        e.preventDefault()
                        this.setState({ openModalCreate: true });
                    }}
                >
                    <h3>Create Organization</h3>
                </Box>

                {this.state.invitations.length !== 0 ?
                    <Box className='noselect select-organization-section verticalalign' sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault()
                            this.setState({ openModalInvitation: true });
                        }}
                    >
                        <h3>Invitations ({this.state.invitations.length})</h3>
                    </Box> : ""}

                {/* handleSearchOrg={this.handleSearchOrg}  */}
                <SearchOrganization openModalSearch={this.state.openModalSearch} handleCloseModal={this.handleCloseModal} />
                <CreateOrganization openModalCreate={this.state.openModalCreate} handleCloseModal={this.handleCloseModal} handleCreateSuccess={this.handleCreateSuccess} />
                <RequestOrganization openModalRequest={this.state.openModalRequest} handleCloseModal={this.handleCloseModal} />
                {this.state.invitations.length !== 0 ?
                    <InvitationOrganization invitations={this.state.invitations} openModalInvitation={this.state.openModalInvitation} handleCloseInvitationModal={this.handleCloseInvitationModal} />
                    : ""}

                {this.state.tutorial === true ?
                    <Dialog onClose={() => {
                        this.setState({ tutorial: false });
                    }} open={this.state.tutorial}>
                        <Box sx={{ padding: "10px" }}>
                            <Typography variant='h5' sx={{ marginBottom: "15px", textAlign: "center" }}>
                                Welcome!
                            </Typography>
                            <Typography sx={{ textAlign: "center" }}>
                                Here, there is few steps to start using Hasler
                            </Typography>
                            <Stack gap={2} direction={"row"} justifyContent="center" alignItems="center" sx={{ marginTop: "20px" }}>
                                <Button color="secondary" variant="contained" onClick={(e) => { this.setState({ tutorial: false }) }}>Skip Tutorial</Button>
                                <Button color="primary" variant="contained" onClick={(e) => { this.setState({ tutorial: false, step: 1 }) }}>Start Tutorial</Button>
                            </Stack>
                        </Box>
                    </Dialog>
                    : ""}

                <CoachMark activate={step === 1} component={<div>Search url organization untuk request bergabung<Button onClick={this.nextStep}>Selanjutnya</Button></div>} reference={this.refSearch} tooltip={{ position: 'bottom' }} />
                <CoachMark activate={step === 2} component={<div>Create organization untuk membuat organization, dan mengelola project.<Button onClick={this.nextStep}>Selanjutnya</Button></div>} reference={this.refCreate} tooltip={{ position: 'top' }} />
                <CoachMark activate={step === 3} component={<div>List organization akan muncul di sini ketika kamu sudah bergabung<Button onClick={this.nextStep}>Tutup</Button></div>} reference={this.refContainer} tooltip={{ position: 'bottom' }} />
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // login: data => dispatch(login(data)),
        joinOrganization: data => dispatch(joinOrganization(data)),
        searchOrganization: data => dispatch(searchOrganization(data)),
        getMyOrganization: data => dispatch(getMyOrganization(data)),
        getOrganizationInvitation: data => dispatch(getOrganizationInvitation(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectOrganization));