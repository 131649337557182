import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Link,
    Redirect,
    withRouter,
} from "react-router-dom";

import { setpassword } from '../redux/actions/users'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import '../styles/login.css';

class SetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "Please Wait",
            code: "",
            email: "",
            password: "",
            c_password: "",
            isLoading: false,
            err: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ isLoading: true, err: null });
        const { code, emem, password, c_password } = this.state;

        if (password !== c_password) {
            this.setState({ isLoading: false, err: "Password don't match" });
        } else {
            this.props.setpassword({ email: emem, code: code, password: password, c_password: c_password }).then((res) => {
                console.log(res);
                if (res.code === 200) {
                    this.setState({ err: null, notes: "Reset password success" });
                } else {
                    this.setState({ isLoading: false, err: res.message });
                }
            }).catch((err) => {
                console.log(err);
                this.setState({ isLoading: false, err: "Something Error" });
            })
        }
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        let tktk = searchParams.get("tk");
        let emem = searchParams.get("email");

        if (tktk !== null) {
            console.log(tktk);
            this.setState({ emem: emem, code: tktk });
        } else {
            this.props.history.push("/login");
        }
    }

    render() {
        const { password, c_password, err, isLoading, notes } = this.state;
        return (
            <Box className='login-container fullheight'>
                {(this.props.isLogin !== true) ? '' : <Redirect to="/selectorganization"></Redirect>}
                <Box className='login-paper verticalalign'>
                    <h1>Set New Password</h1>
                    <Box component={"form"} onSubmit={this.handleSubmit}>
                        <span className="inputWrapper">
                            <label htmlFor="password">Password: </label>
                            <input required id="password" type="password" placeholder="newpassword" value={password} onChange={this.handleChange}></input>
                        </span>
                        <span className="inputWrapper">
                            <label htmlFor="c_password">Confirm Password: </label>
                            <input required id="c_password" type="password" placeholder="newpassword" value={c_password} onChange={this.handleChange}></input>
                        </span>
                        <span className="error-message">
                            {(err !== null) ? <>{err}</> : ""}
                        </span>
                        {(isLoading === false) ? <span className="inputWrapper">
                            <Button type="submit" className="login-btn" variant="contained" color="primary">Set Password</Button>
                        </span> : <span className="loading-message">{notes}</span>}
                    </Box>
                    <Link style={{ cursor: "pointer", fontSize: "14px", marginTop: "10px" }} to="./login">Back to Login</Link>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setpassword: data => dispatch(setpassword(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SetPassword));