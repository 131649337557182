import React, { Component } from 'react';
import { connect } from "react-redux";

// import { forceLogout } from "../../redux/actions/users";
import { deleteAttachment } from "../../../redux/actions/tasks"

import {
    withRouter
} from "react-router-dom";

import {
    Box,
    // Grid,
    // Drawer,
    // Button,
    // Select,
    // Checkbox,
    // MenuItem,
    // TextField,
    Typography,
    InputLabel,
    // Input,
    // Autocomplete,
    // FormControlLabel,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import ModalAttachment from '../../modal/modalAttachments';

class Images extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // account_type:0,
            // mobileMenu:false,
            // logoIcon:'',
            previewData: null,
            openModal: false,
            attachments: [],
            tmpFiles: [],
            err: null
        };

        this.handleDownloadAttachment = this.handleDownloadAttachment.bind(this);
        this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
        this.handlePreviewAttachment = this.handlePreviewAttachment.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.handleDeleteTmp = this.handleDeleteTmp.bind(this);
        this.getIconFile = this.getIconFile.bind(this);
        this.parseMimeType = this.parseMimeType.bind(this);
    }

    handlePreviewAttachment(e) {
        console.log("Preview");
        this.setState({ previewData: e }, function () {
            this.setState({ openModal: true })
        });
    }

    handleDownloadAttachment(e) {
        let urlItem = e.currentTarget.getAttribute("data-fullurl");
        console.log(urlItem);
        window.open(urlItem, '_blank', 'noreferrer')
    }

    handleDeleteAttachment(e) {
        let idItem = parseInt(e.currentTarget.getAttribute("data-id"));
        // let urlItem = parseInt(e.currentTarget.getAttribute("data-url"));

        console.log(idItem);
        console.log(this.state.attachments);

        var confirm = window.confirm("Are you sure?");
        if (confirm) {
            this.props.deleteAttachment({ id: idItem }).then((res) => {
                console.log(res);

                var attachmentsArr = this.state.attachments;
                console.log(attachmentsArr);
                var idx = attachmentsArr.findIndex(d => d.id === idItem)

                console.log(idx);
                console.log(attachmentsArr);
                attachmentsArr.splice(idx, 1);
                console.log(attachmentsArr);
                this.setState({ attachments: attachmentsArr })

                alert("Success Delete Item");
            }).catch((err) => {
                alert("Something Error");
            })
        }
    }

    parseMimeType(mime) {
        let type = "file";
        if (mime.includes("heic")) {
            type = "file";
        } else if (mime.includes("photoshop")) {
            type = "file";
        } else if (mime.includes("svg") || mime.includes("corel")) {
            type = "file";
        } else if (mime.includes("image")) {
            type = "image";
        } else if (mime.includes("pdf")) {
            type = "pdf";
        } else if (mime.includes("audio")) {
            type = "audio";
        } else if (mime.includes("zip")) {
            type = "zip";
        }

        return type;
    }

    getIconFile(type) {
        let icon = "";
        switch (type) {
            case "pdf":
                icon = <PictureAsPdfIcon />
                break;
            case "audio":
                icon = <AudioFileIcon />
                break;
            case "zip":
                icon = <FolderZipIcon />
                break;
            case "file":
                icon = <DescriptionIcon />
                break;
            default:
                icon = <InsertDriveFileIcon />
                break;
        }

        return icon;
    }

    handleDeleteTmp(e) {
        let tmpArr = this.state.tmpFiles;
        tmpArr.splice(parseInt(e.currentTarget.getAttribute("data-id")), 1);
        this.setState({ tmpFiles: tmpArr }, function () {
            this.props.handleFilesChange(this.state.tmpFiles);
        });
    }

    handleChangeFile(e) {
        let tmpArr = this.state.tmpFiles;
        for (let i = 0; i < e.target.files.length; i++) {
            let type = "file";
            if (e.target.files[i].type.includes("heic")) {
                type = "file";
            } else if (e.target.files[i].type.includes("photoshop")) {
                type = "file";
            } else if (e.target.files[i].type.includes("svg") || e.target.files[i].type.includes("corel")) {
                type = "file";
            } else if (e.target.files[i].type.includes("image")) {
                type = "image";
            } else if (e.target.files[i].type.includes("pdf")) {
                type = "pdf";
            } else if (e.target.files[i].type.includes("audio")) {
                type = "audio";
            } else if (e.target.files[i].type.includes("zip")) {
                type = "zip";
            }

            tmpArr.push({
                id: tmpArr.length,
                simpleType: type,
                name: e.target.files[i].name,
                type: e.target.files[i].type,
                src: URL.createObjectURL(e.target.files[i]),
                file: e.target.files[i]
            })
        }

        this.setState({ tmpFiles: tmpArr }, function () {
            this.props.handleFilesChange(this.state.tmpFiles);
        })
    }

    componentDidMount() {
        this.setState({ attachments: this.props.attachments });
    }

    render() {
        return (
            <Box className="task-images-container" sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <InputLabel className='form-label' htmlFor="input-transparent">Attachments</InputLabel>
                <Box className="images-thumbs-row">
                    {this.state.attachments.map((item) => {
                        return (
                            <Box key={item.name} className="btn-detail-attachment-container">
                                <Box className="btn-detail-image image-thumbnail">
                                    <Box data-url={item.url} data-id={item.id} className="btn-delete-attachment" onClick={this.handleDeleteAttachment} ><DeleteIcon fontSize="small" className='btn-delete-attachment-icon' /></Box>
                                    <Box data-fullurl={item.fullurl} className="btn-download-attachment" onClick={this.handleDownloadAttachment} ><CloudDownloadIcon fontSize="small" className='btn-download-attachment-icon' /></Box>
                                    {(this.parseMimeType(item.mimetype) !== "image") ?
                                        <Box>{this.getIconFile(this.parseMimeType(item.mimetype))}</Box> : <>
                                            <Box data-fullurl={item.fullurl} className="btn-preview-attachment" onClick={(e) => { this.handlePreviewAttachment(item) }} >
                                                <VisibilityIcon fontSize="small" className='btn-download-attachment-icon' />
                                            </Box><img alt={item.name + " attachement image"} src={item.fullurl} /></>}
                                </Box>
                                <Typography >{item.name}</Typography>
                            </Box>
                        )
                    })}
                    {this.state.tmpFiles.map((item, idx) => {
                        return (
                            <Box key={item.name} className="btn-detail-attachment-container">
                                <Box data-id={idx} className="btn-delete-attachment" onClick={this.handleDeleteTmp} ><DeleteIcon fontSize="small" className='btn-delete-attachment-icon' /></Box>
                                <Box className="btn-detail-image image-thumbnail">
                                    {(item.simpleType !== "image") ?
                                        <Box>{this.getIconFile(item.simpleType)}</Box> : <img alt={item.name + " attachement image"} src={item.src} />}
                                </Box>
                                <Typography >{item.name}</Typography>
                            </Box>
                        )
                    })}
                    <input multiple={true} type='file' id="attachments-upload" style={{ display: "none" }} onChange={this.handleChangeFile} />
                    <InputLabel htmlFor='attachments-upload' className="btn-add-images">Add Attachments</InputLabel>
                </Box>
                {this.state.openModal ? <ModalAttachment previewData={this.state.previewData} openModal={this.state.openModal} handleCloseModal={() => {
                    this.setState({ openModal: false })
                }} /> : ""}
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteAttachment: user => dispatch(deleteAttachment(user)),
        // forceLogout: data => dispatch(forceLogout(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Images));