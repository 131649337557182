import React, { Component } from 'react';
import { connect } from "react-redux";
import { getMyNotification, readNotification, readAllNotification } from "../../redux/actions/users";
import { withRouter } from "react-router-dom";
import { Box, Typography, Button } from '@mui/material';
import { format } from "date-fns";

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            err: null
        };

        this.handleReadAll = this.handleReadAll.bind(this);
    }

    componentDidMount() {
        this.props.getMyNotification({ id: this.props.organizationData.id }).then((res) => {
            if (res.code === 200) {
                this.setState({ notifications: res.data });
            } else {
                alert("Something Error get Notification");
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    handleReadAll() {
        this.props.readAllNotification().then((res) => {
            if (res.code === 200) {
                const updatedNotifications = this.state.notifications.map(notification => ({
                    ...notification,
                    is_read: true
                }));
                this.setState({ notifications: updatedNotifications }, () => {
                    window.location.reload();
                });
            } else {
                alert("Something Error marking all notifications as read");
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    render() {
        return (
            <Box className="panel home-panel">
                <Box sx={{ padding: "10px" }}>
                    <Typography sx={{ fontSize: "20px", fontWeight: "800", marginBottom: "8px" }}>Notifications</Typography>
                    <Button onClick={this.handleReadAll} variant="contained" color="primary" sx={{ marginBottom: "10px" }}>
                        Read All Notifications
                    </Button>
                    {this.state.notifications.length !== 0 ? (
                        <>
                            {this.state.notifications.map((item) => (
                                <Box key={item._id} className="notification-bar" onClick={(e) => {
                                    this.props.readNotification({ ids: [item._id] }).then((res) => {
                                        let tempArr = this.state.notifications;
                                        let idx = tempArr.findIndex(d => d._id === item._id);
                                        tempArr[idx].is_read = true;

                                        this.setState({ notifications: tempArr }, function () {
                                            if (item.projectDetail !== null) {
                                                this.props.history.push("/" + item.organizationDetail.url + "/p/" + item.projectDetail?.uuid + "/board");
                                            }
                                        });
                                    }).catch((err) => {
                                        console.log(err);
                                    });
                                }}>
                                    <Box sx={{ padding: "2px", paddingLeft: "5px", paddingRight: "5px", fontSize: "12px", backgroundColor: "#eaeaea", width: "fit-content", borderRadius: "5px" }}
                                        onClick={(e) => {
                                            // console.log(item.projectDetail?.uuid);
                                        }}
                                    >{item.projectDetail?.name || "Organization"}</Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {item.is_read ? "" : <Box className="unread"></Box>}
                                        <Typography sx={{ fontSize: "14px", fontWeight: item.is_read ? "normal" : "700" }}>{item.description}</Typography>
                                    </Box>
                                    <Typography sx={{ fontSize: "12px", color: "#4f4f4f" }}>{format(new Date(item.date), "EEEE, dd MMM yyyy hh:mm:ss")}</Typography>
                                </Box>
                            ))}
                        </>
                    ) : (
                        <Box><Typography>No New Notifications</Typography></Box>
                    )}
                </Box>
            </Box>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        readNotification: data => dispatch(readNotification(data)),
        getMyNotification: data => dispatch(getMyNotification(data)),
        readAllNotification: () => dispatch(readAllNotification())
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification));
