import React, { Component } from 'react';
import { connect } from "react-redux";

import { forceLogout } from "../redux/actions/users";

//import $ from 'jquery';

import {
    NavLink,
    withRouter
} from "react-router-dom";

import Divider from '@mui/material/Divider';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // notifications: [],
            // notificationCount: 0,
            projects: [],
            account_type: null,
            err: null
        };

        this.getTypeProject = this.getTypeProject.bind(this);
    }

    getTypeProject(type) {
        let icon = <PublicIcon sx={{ fontSize: "14px" }} />
        if (type !== 1) {
            icon = <LockIcon sx={{ fontSize: "14px" }} />
        }

        return icon
    }

    componentDidMount() {
        let tmpProjects = this.props.organizationData.projects;
        tmpProjects.sort((a, b) => b.sort_order - a.sort_order);
        this.setState({
            account_type: this.props.organizationData.roles.roles,
            projects: tmpProjects
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(prevProps.notification);
        // console.log(this.props.notification);

        // console.log(prevState);
        // console.log(this.props.notification);

        // if (prevProps.notification !== this.props.notification) {
        //     let notificationCount = [];
        //     this.props.notification.map((item) => {
        //         if (item.is_read !== true) {
        //             notificationCount.push(item);
        //         }
        //         return item
        //     })
        //     this.setState({ notificationCount: notificationCount.length, notifications: this.props.notification });
        // }
    }

    render() {
        const url = this.props.match.params.url;
        const { account_type } = this.state;

        var menuText = "";
        if (account_type === "admin") {
            // || account_type === "moderator"
            //admin
            menuText = <ul>
                <li><NavLink activeClassName="active" to={"/" + url + "/home"}>Home</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/mytask"}>My Task</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/activity"}>My Activity</NavLink></li>
                {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                    {item.is_read ? "" : <Box className="unread"></Box>}
                </Box> */}
                <li><NavLink activeClassName="active" to={"/" + url + "/notification"}>{this.props.notificationCount !== 0 ? <><Box className="unread"></Box> Notifications ({this.props.notificationCount})</> : <>Notifications</>}</NavLink></li>
                {/* {this.props.notifications.length !== 0 ? "(" + this.props.notifications.length + ")" : ""} */}
                {/* <li><NavLink activeClassName="active" to={"/"+url+"/board"}>Boards</NavLink></li> */}
                {/* <li><NavLink activeClassName="active" to={"/"+url+"/sprint"}>Sprints</NavLink></li> */}
                <li><NavLink activeClassName="active" to={"/" + url + "/knowledge"}>Knowledge</NavLink></li>
                <li className='menu-divider'><Divider /></li>
                <li>
                    <Accordion defaultExpanded={true} className='menu-primary' elevation={0} style={{ borderRadius: "0px", backgroundColor: 'transparent' }}>
                        <AccordionSummary
                            className='menu-primary-summary'
                            style={{ minHeight: "0px", padding: "8px 12px" }}
                            expandIcon={<ExpandMoreIcon className="expandButton" style={{ margin: "0px", padding: "0px", color: "#fff" }} />}
                            aria-controls="menu-products-content"
                            id="menu-products-header"
                        >
                            <Typography style={{ color: "#fff" }}>Projects</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            className='menu-primary-content'
                        >
                            {this.state.projects.map((item) => {
                                return (
                                    <NavLink style={{ display: "flex", alignItems: "center", gap: "7px" }} key={item.uuid} activeClassName="active" to={"/" + url + "/p/" + item.uuid + "/board"}>{this.getTypeProject(item.type)} {item.name}</NavLink>
                                )
                            })}
                        </AccordionDetails>
                    </Accordion>
                </li>
                <li><NavLink className="link-add-project" activeClassName="active" to={"/" + url + "/add-project"}>+ Add Project</NavLink></li>
                <li className='menu-divider'><Divider /></li>
                <li>
                    <Accordion className='menu-primary' elevation={0} style={{ borderRadius: "0px", backgroundColor: 'transparent' }}>
                        <AccordionSummary
                            className='menu-primary-summary'
                            style={{ minHeight: "0px", padding: "8px 12px" }}
                            expandIcon={<ExpandMoreIcon className="expandButton" style={{ margin: "0px", padding: "0px", color: "#fff" }} />}
                            aria-controls="menu-products-content"
                            id="menu-products-header"
                        >
                            <Typography style={{ color: "#fff" }}>Members</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='menu-primary-content'>
                            <NavLink activeClassName="active" to={"/" + url + "/all-members"}>All Members</NavLink>
                            <NavLink activeClassName="active" to={"/" + url + "/waiting-members"}>Requests</NavLink>
                            {/* <NavLink activeClassName="active" to={"/" + url + "/activity-members"}>Members Activities</NavLink> */}
                        </AccordionDetails>
                    </Accordion>
                </li>
                {/* <li><NavLink activeClassName="active" to={"/" + url + "/setting"}>Settings</NavLink></li> */}
            </ul>
        } else {
            //reseller
            menuText = <ul>
                <li><NavLink activeClassName="active" to={"/" + url + "/home"}>Home</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/mytask"}>My Task</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/activity"}>My Activity</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/notification"}>{this.props.notificationCount !== 0 ? <><Box className="unread"></Box> Notifications ({this.props.notificationCount})</> : <>Notifications</>}</NavLink></li>
                {/* <li><NavLink activeClassName="active" to={"/"+url+"/board"}>Boards</NavLink></li> */}
                {/* <li><NavLink activeClassName="active" to={"/"+url+"/sprint"}>Sprints</NavLink></li> */}
                <li className='menu-divider'><Divider /></li>
                <li>
                    <Accordion defaultExpanded={true} className='menu-primary' elevation={0} style={{ borderRadius: "0px", backgroundColor: 'transparent' }}>
                        <AccordionSummary
                            className='menu-primary-summary'
                            style={{ minHeight: "0px", padding: "8px 12px" }}
                            expandIcon={<ExpandMoreIcon className="expandButton" style={{ margin: "0px", padding: "0px", color: "#fff" }} />}
                            aria-controls="menu-products-content"
                            id="menu-products-header"
                        >
                            <Typography style={{ color: "#fff" }}>Projects</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='menu-primary-content'>
                            {this.state.projects.map((item) => {
                                if (item.type === 1) {
                                    return (
                                        <NavLink style={{ display: "flex", alignItems: "center", gap: "7px" }} key={item.uuid} activeClassName="active" to={"/" + url + "/p/" + item.uuid + "/board"}>{this.getTypeProject(item.type)} {item.name}</NavLink>
                                    )
                                } else {
                                    let isJoinedProject = item.users.findIndex(user => user.id === this.props.userData.id)
                                    if (isJoinedProject !== -1) {
                                        return (
                                            <NavLink style={{ display: "flex", alignItems: "center", gap: "7px" }} key={item.uuid} activeClassName="active" to={"/" + url + "/p/" + item.uuid + "/board"}>{this.getTypeProject(item.type)} {item.name}</NavLink>
                                        )
                                    } else {
                                        return "";
                                    }
                                }
                            })}
                        </AccordionDetails>
                    </Accordion>
                </li>
                {/* <li><NavLink className="link-add-project" activeClassName="active" to={"/" + url + "/add-project"}>+ Add Project</NavLink></li> */}
                <li className='menu-divider'><Divider /></li>
                <li>
                    <Accordion className='menu-primary' elevation={0} style={{ borderRadius: "0px", backgroundColor: 'transparent' }}>
                        <AccordionSummary
                            className='menu-primary-summary'
                            style={{ minHeight: "0px", padding: "8px 12px" }}
                            expandIcon={<ExpandMoreIcon className="expandButton" style={{ margin: "0px", padding: "0px", color: "#fff" }} />}
                            aria-controls="menu-products-content"
                            id="menu-products-header"
                        >
                            <Typography style={{ color: "#fff" }}>Members</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='menu-primary-content'>
                            <NavLink activeClassName="active" to={"/" + url + "/all-members"}>All Members</NavLink>
                            {/* <NavLink activeClassName="active" to={"/" + url + "/activity-members"}>Members Activity</NavLink> */}
                            {/* <NavLink activeClassName="active" to={"/" + url + "/waiting-members"}>Requests</NavLink> */}
                        </AccordionDetails>
                    </Accordion>
                </li>
                <li><NavLink activeClassName="active" to={"/" + url + "/setting"}>Settings</NavLink></li>
                {/* <li><NavLink activeClassName="active" to={"/" + url + "/home"}>Home</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/carts"}>Cart</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/products"}>Products</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/orders"}>Orders</NavLink></li>
                <li className='menu-divider'><Divider /></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/feeds"}>Feeds</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/forums"}>Forums</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/mediacenter"}>Media Center</NavLink></li>
                <li className='menu-divider'><Divider /></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/profiles"}>Profile</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/address"}>Address</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/settings"}>Settings</NavLink></li>
                <li><NavLink activeClassName="active" to={"/" + url + "/logout"}>Logout</NavLink></li> */}
            </ul>
        }

        return (
            <Box className="side-menu">
                <Box className={'roles-widget ' + account_type}>Roles User {account_type}</Box>
                {menuText}
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        forceLogout: data => dispatch(forceLogout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideMenu));