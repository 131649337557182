import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getDetailTask, addComment } from "../../redux/actions/tasks";
import { getProjectLabel, addProjectLabel } from "../../redux/actions/projects";

import InputSubtask from "../input/inputSubTask";

import FeedsComment from "./activities/feedsComment";
import InputComment from "./activities/inputComment";

import Attachments from "./attachments/files";
// import Links from './attachments/links';
// import Cover from './attachments/cover';

import CreatableSelect from "react-select/creatable";
// import { colourOptions } from '../data';

import {
  Box,
  Grid,
  Drawer,
  Button,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  // Autocomplete,
  FormControlLabel,
} from "@mui/material";

import { format } from "date-fns";

import InfoIcon from "@mui/icons-material/Info";
import StarsIcon from "@mui/icons-material/Stars";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import BugReportIcon from "@mui/icons-material/BugReport";
import RichTextComponent from "../input/richText";

class AddCardDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelArr: [
        // { value: 1, label: "huhuy" },
        // { value: 2, label: "ahuy" },
      ],
      labelSelected: "",
      // [
      //     { value: 1, label: "ahuy" }
      // ],
      loadedData: false,
      tmpCardForm: {},
      tmpFiles: [],
      comments: [],
      attachments: [],
      tmpAddSubtask: [],
      err: null,
    };

    this.addLabel = this.addLabel.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.addComment = this.addComment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
    this.handleFilesChange = this.handleFilesChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleDateChangeStartDate = this.handleDateChangeStartDate.bind(this);
    this.handleDateChangeDueDate = this.handleDateChangeDueDate.bind(this);

    this.cyrb53 = this.cyrb53.bind(this);
    this.MakeSenseColor = this.MakeSenseColor.bind(this);
    // this.handleUpdateAttachments = this.handleUpdateAttachments.bind(this);
  }

  // borrowed from https://stackoverflow.com/a/52171480/1358308
  cyrb53(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 =
      Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
      Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 =
      Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
      Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  }

  MakeSenseColor(str) {
    // hash the string and convert to hex
    let hash = this.cyrb53(str).toString(16);

    // just use the last 6 characters and prepend character to indicate color
    return "#" + hash.slice(-6);
  }

  getLabel() {
    // console.log(this.MakeSenseColor("anu"));
    // console.log(this.MakeSenseColor("rekor"));
    // console.log(this.MakeSenseColor("gila"));

    this.props
      .getProjectLabel({ id: this.props.data.id })
      .then(async (res) => {
        console.log(res);
        var labelMapper = await res.data.map((item) => {
          return {
            label: item.name,
            id: item.id,
            value: item.id,
            color: this.MakeSenseColor(item.name),
          };
        });
        this.setState({ labelArr: labelMapper });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addLabel(labelNew) {
    console.log(labelNew);
    this.props
      .addProjectLabel({
        url: this.props.organizationData.url,
        uuid: this.props.match.params.slug,
        name: labelNew,
      })
      .then((res) => {
        console.log(res.data);

        let tmpLabel = res.data;
        tmpLabel.value = res.data.id;
        tmpLabel.label = res.data.name;
        console.log(tmpLabel);

        let labelTmpArr = this.state.labelArr;
        labelTmpArr.push(tmpLabel);

        this.setState(
          { labelSelected: [tmpLabel], labelArr: labelTmpArr },
          function () {
            console.log(this.state.labelSelected);
            console.log(this.state.labelArr);
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // handleUpdateAttachments(e) {
  //     console.log(e);
  //     var attachmentsArr = this.state.attachments;
  //     var idx = attachmentsArr.findIndex(d => d.id === e)

  //     console.log(idx);
  //     console.log(attachmentsArr);
  //     attachmentsArr.splice(idx, 1);
  //     console.log(attachmentsArr);
  //     this.setState({ attachments: attachmentsArr })
  // }

  handleFilesChange(e) {
    console.log(e);
    this.setState({ tmpFiles: e });
  }

  addComment(comment, cb) {
    this.props
      .addComment({ id: this.state.tmpCardForm.id, comment: comment })
      .then((res) => {
        if (res.code === 200) {
          this.setState((prevState) => ({
            comments: [res.data, ...prevState.comments],
          }));
        } else {
          alert("Something Error");
        }

        cb();
      })
      .catch((err) => {
        console.log("error");
        cb();
      });
  }

  handleChangeCheck(e, f) {
    if (f === false) {
      this.setState({
        tmpCardForm: {
          ...this.state.tmpCardForm,
          start_date: null,
          start_date_label: null,
          due_date: null,
          due_date_label: null,
        },
      });
    }

    let ctx = this;
    ctx.setState({
      tmpCardForm: {
        ...ctx.state.tmpCardForm,
        [e.target.name]: Boolean(f),
      },
    });
  }

  handleChange(e) {
    if (e.target.name !== "name") {
      this.setState({
        tmpCardForm: {
          ...this.state.tmpCardForm,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      this.setState({
        tmpCardForm: {
          ...this.state.tmpCardForm,
          [e.target.name]: e.target.value,
          title: e.target.value,
        },
      });
    }
  }

  handleQuillChange(value) {
    this.setState(prevState => ({
      tmpCardForm: {
        ...prevState.tmpCardForm,
        description: value,
      },
    }));
  }

  handleDateChangeStartDate(date, e, f, g) {
    if (date !== null) {
      let todayDate = new Date(date);
      this.setState({
        tmpCardForm: {
          ...this.state.tmpCardForm,
          start_date: todayDate,
          start_date_label: format(todayDate, "yyyy-MM-dd kk:mm:ss"),
        },
      });
    } else {
      this.setState({
        tmpCardForm: {
          ...this.state.tmpCardForm,
          start_date: null,
          start_date_label: null,
        },
      });
    }
  }

  handleDateChangeDueDate(date, e, f, g) {
    if (date !== null) {
      let todayDate = new Date(date);
      this.setState({
        tmpCardForm: {
          ...this.state.tmpCardForm,
          due_date: todayDate,
          due_date_label: format(todayDate, "yyyy-MM-dd kk:mm:ss"),
        },
      });
    } else {
      this.setState({
        tmpCardForm: {
          ...this.state.tmpCardForm,
          due_date: null,
          due_date_label: null,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openTaskDrawer !== this.props.openTaskDrawer) {
      if (this.props.openTaskDrawer !== false) {
        if (this.props.taskEditMode !== false) {
          this.getLabel();

          this.props
            .getDetailTask({ id: this.props.taskEditId })
            .then(async (res) => {
              if (res.data.start_date !== null || res.data.due_date !== null) {
                res.data.use_due_date = true;
                if (res.data.start_date !== null) {
                  res.data.start_date = new Date(res.data.start_date);
                }
                if (res.data.due_date !== null) {
                  res.data.due_date = new Date(res.data.due_date);
                }
              } else {
                res.data.use_due_date = false;
              }

              let labelMapped = await res.data.label.map((item) => {
                return {
                  label: item.name,
                  id: item.id,
                  value: item.id,
                  color: this.MakeSenseColor(item.name),
                };
              });

              this.setState(
                {
                  labelSelected: labelMapped,
                  tmpCardForm: res.data,
                  tmpAddSubtask: res.data.subtasks,
                  comments: res.data.comments,
                  attachments: res.data.attachments,
                },
                function () {
                  this.setState({ loadedData: true });
                }
              );
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.setState(
            {
              loadedData: true,
              tmpCardForm: {
                id: "tmp-lane-id",
              },
            },
            function () {
              this.setState({
                tmpCardForm: {
                  ...this.state.tmpCardForm,
                  laneId: this.props.taskLaneId,
                },
              });
            }
          );
        }
      }
    }
  }

  render() {
    if (this.state.loadedData !== true) {
      return <></>;
    }

    return (
      <Drawer
        anchor="right"
        open={this.props.openTaskDrawer}
        onClose={(e) => {
          this.setState({ loadedData: false }, function () {
            this.props.onClose();
          });
        }}
        className="drawer-panel"
      >
        <Box className="card-panel" role="presentation">
          <Box className="left-detail">
            <Box sx={{ position: "relative" }}>
              <Typography sx={{ marginBottom: "10px" }}>
                {this.props.taskEditMode ? "Detail Task" : "Add new Task"}
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={"10px"} sx={{ marginBottom: "0px" }}>
                <Grid item xs={6}>
                  <InputLabel className="form-label first">
                    Task Type
                  </InputLabel>
                  <Select
                    inputProps={{ style: { fontSize: "13px", outline: 0 } }}
                    sx={{
                      width: "100%",
                      fontSize: "13px",
                      ".MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                    size="small"
                    name="type"
                    value={this.state.tmpCardForm?.type || 1}
                    displayEmpty
                    onChange={this.handleChange}
                  >
                    <MenuItem sx={{ fontSize: "13px" }} value={1}>
                      <InfoIcon
                        size="small"
                        sx={{
                          position: "relative",
                          top: "-1px",
                          fontSize: "13px",
                          marginRight: "7px",
                        }}
                      />
                      Task
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={2}>
                      <StarsIcon
                        size="small"
                        sx={{
                          position: "relative",
                          top: "-1px",
                          fontSize: "13px",
                          marginRight: "7px",
                        }}
                      />{" "}
                      Story
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={3}>
                      <FlashOnIcon
                        size="small"
                        sx={{
                          position: "relative",
                          top: "-1px",
                          fontSize: "13px",
                          marginRight: "7px",
                        }}
                      />{" "}
                      Feature
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={4}>
                      <BugReportIcon
                        size="small"
                        sx={{
                          position: "relative",
                          top: "-1px",
                          fontSize: "13px",
                          marginRight: "7px",
                        }}
                      />{" "}
                      Bug
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="form-label first">Score</InputLabel>
                  <TextField
                    id="input-score"
                    // defaultValue={0}
                    value={this.state.tmpCardForm?.score || ""}
                    onChange={this.handleChange}
                    name="score"
                    type="number"
                    inputProps={{ style: { fontSize: "13px", outline: 0 } }}
                    sx={{
                      display: "block",
                      marginBottom: "5px",
                      outline: "none",
                    }}
                    fullWidth
                    size="small"
                    placeholder="Score"
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
                  <Box>
                    <InputLabel
                      className="form-label"
                      htmlFor="input-label"
                      sx={{ marginTop: "5px" }}
                    >
                      Label
                    </InputLabel>
                    <CreatableSelect
                      id="input-label"
                      isMulti
                      isClearable
                      styles={{
                        display: "block",
                        marginTop: "10px",
                        noOptionsMessage: (styles) => ({
                          ...styles,
                          fontFamily: "sans-serif",
                          fontSize: "13px",
                        }),
                        placeholder: (styles) => ({
                          ...styles,
                          fontFamily: "sans-serif",
                          fontSize: "13px",
                        }),
                        singleValue: (styles) => ({
                          ...styles,
                          fontFamily: "sans-serif",
                          fontSize: "13px",
                        }),
                        input: (styles) => ({
                          ...styles,
                          fontFamily: "sans-serif",
                          fontSize: "13px",
                        }),
                        multiValue: (styles) => ({
                          ...styles,
                          fontFamily: "sans-serif",
                          fontSize: "13px",
                          color: "#000",
                        }),
                        multiValueLabel: (styles, { data }) => ({
                          ...styles,
                          fontFamily: "sans-serif",
                          fontSize: "13px",
                          color: "#fff",
                          // backgroundColor: "#eaeaea",
                          textShadow: "1px 1px 2px " + data.color,
                          backgroundColor: data.color,
                          border: "1px solid " + data.color,
                          paddingRight: "7px",
                        }),
                        option: (styles, { data }) => ({
                          ...styles,
                          fontFamily: "sans-serif",
                          fontSize: "13px",
                          // color: "#000",
                          color: data.color,
                        }),
                      }}
                      placeholder="Select or Create Label"
                      options={this.state.labelArr}
                      onChange={(e, f) => {
                        if (f.action === "create-option") {
                          this.addLabel(f.option.label);
                        } else if (f.action === "clear") {
                          this.setState({ labelSelected: "" });
                        } else {
                          this.setState({ labelSelected: e });
                        }
                      }}
                      value={this.state.labelSelected || ""}
                      // onCreateOption={(e) => {
                      //     console.log(e);
                      //     var newlabelArr = this.state.labelArr;
                      //     newlabelArr.push({
                      //         label:e,
                      //         value:"3"
                      //     })
                      //     this.setState({labelArr:newlabelArr})
                      // }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <InputLabel
                className="form-label"
                htmlFor="input-name"
                sx={{ marginTop: "10px" }}
              >
                Name
              </InputLabel>
              <TextField
                id="input-name"
                autoFocus
                value={this.state.tmpCardForm?.name || ""}
                onChange={this.handleChange}
                name="name"
                inputProps={{ style: { fontSize: "13px", outline: 0 } }}
                sx={{ display: "block", marginBottom: "5px", outline: "none" }}
                fullWidth
                size="small"
                placeholder="Name"
              />

              <InputLabel className="form-label" htmlFor="input-description">
                Description
              </InputLabel>
              <RichTextComponent
                value={this.state.tmpCardForm?.description || ""}
                handleInput={this.handleQuillChange}
                style={{ width: "100%" }}
              />

              {/* <TextField
                id="input-description"
                value={this.state.tmpCardForm?.description || ""}
                onChange={this.handleChange}
                multiline
                rows={5}
                name="description"
                inputProps={{
                  style: {
                    fontSize: "14px",
                    outline: 0,
                    whiteSpace: "break-spaces",
                    lineHeight: "20px",
                    resize: "auto",
                  },
                }}
                sx={{ display: "block", outline: "none" }}
                fullWidth
                size="small"
                placeholder="Description"
              /> */}

              <Attachments
                handleFilesChange={this.handleFilesChange}
                attachments={this.state.attachments}
              />

              <InputSubtask
                tmpAddSubtask={this.state.tmpAddSubtask}
                handleSaveButton={(data) => {
                  this.setState({ tmpAddSubtask: data });
                }}
              />

              {/* <Links /> */}

              <Grid container spacing={"10px"} sx={{ marginBottom: "10px" }}>
                <Grid item xs={7}>
                  <InputLabel className="form-label" htmlFor="input-priority">
                    Date
                  </InputLabel>
                  <FormControlLabel
                    // padding: "5px", marginTop: "5px",
                    sx={{ marginBottom: "5px" }}
                    value={this.state.tmpCardForm?.use_due_date || false}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ position: "relative", top: "-3px" }}
                        checked={this.state.tmpCardForm?.use_due_date || false}
                        label="Set Due Date"
                        name="use_due_date"
                        onChange={this.handleChangeCheck}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={
                      <Typography
                        sx={{ position: "relative", top: "-2px" }}
                        variant="body2"
                        color="textSecondary"
                      >
                        Use Date
                      </Typography>
                    }
                    labelPlacement="end"
                  />

                  {this.state.tmpCardForm?.use_due_date ? (
                    <>
                      <InputLabel
                        className="form-label first"
                        htmlFor="task-datetime-picker"
                      >
                        Start Date
                      </InputLabel>
                      <DatePicker
                        isClearable
                        id="task-datetime-picker"
                        className="task-datetime-picker start"
                        inputProps={{
                          style: {
                            backgroundColor: "#00f",
                            fontSize: "13px",
                            outline: 0,
                          },
                        }}
                        style={{ padding: "10px", backgroundColor: "#f00" }}
                        selected={this.state.tmpCardForm?.start_date}
                        placeholderText="Click to select a start date"
                        timeInputLabel="Time : "
                        showTimeSelect
                        dateFormat="dd/MMM/yyyy kk:mm"
                        timeIntervals={60}
                        onChange={this.handleDateChangeStartDate}
                        name="start_date"
                      />

                      <InputLabel
                        className="form-label first"
                        htmlFor="task-datetime-picker"
                      >
                        Due Date
                      </InputLabel>
                      <DatePicker
                        isClearable
                        id="task-datetime-picker"
                        className="task-datetime-picker due_date_datetime-picker"
                        inputProps={{
                          style: {
                            backgroundColor: "#00f",
                            fontSize: "13px",
                            outline: 0,
                            marginBottom: "30px",
                            display: "block",
                          },
                        }}
                        style={{
                          padding: "10px",
                          backgroundColor: "#f00",
                          marginBottom: "30px",
                          display: "block",
                        }}
                        selected={this.state.tmpCardForm?.due_date}
                        placeholderText="Click to select a due date"
                        timeInputLabel="Time : "
                        showTimeSelect
                        dateFormat="dd/MMM/yyyy kk:mm"
                        timeIntervals={60}
                        onChange={this.handleDateChangeDueDate}
                        name="due_date"
                      />
                    </>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={5}>
                  <InputLabel className="form-label" htmlFor="input-priority">
                    Priority
                  </InputLabel>
                  <Select
                    id="input-priority"
                    inputProps={{ style: { fontSize: "13px", outline: 0 } }}
                    sx={{ fontSize: "13px", display: "block", width: "100%" }}
                    size="small"
                    name="priority"
                    value={this.state.tmpCardForm?.priority || ""}
                    displayEmpty
                    onChange={this.handleChange}
                  >
                    <MenuItem sx={{ fontSize: "13px" }} value="">
                      Select Priority
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={1}>
                      <Box className="priority-indicator low"></Box>Low
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={2}>
                      <Box className="priority-indicator medium"></Box>Medium
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={3}>
                      <Box className="priority-indicator high"></Box>High
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <InputLabel className="form-label" htmlFor="input-assignee" sx={{marginTop:"5px"}}>
                Assignee
                <Typography
                  className="noselect btn-little"
                  onClick={() => {
                    this.setState({
                      tmpCardForm: {
                        ...this.state.tmpCardForm,
                        assignee: this.props.userData.id,
                      },
                    });
                  }}
                >
                  Assign to me
                </Typography>
              </InputLabel>
              <Select
                id="input-assignee"
                inputProps={{ style: { fontSize: "13px", outline: 0 } }}
                sx={{ fontSize: "13px", display: "block" }}
                size="small"
                name="assignee"
                value={this.state.tmpCardForm?.assignee || ""}
                displayEmpty
                onChange={this.handleChange}
              >
                <MenuItem sx={{ fontSize: "13px" }} value="">
                  None
                </MenuItem>
                {this.props.members.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      sx={{ fontSize: "13px" }}
                      value={item.id}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>

              <InputLabel className="form-label" htmlFor="input-reporter">
                Reporter
                <Typography
                  className="noselect btn-little"
                  onClick={() => {
                    this.setState({
                      tmpCardForm: {
                        ...this.state.tmpCardForm,
                        reporter: this.props.userData.id,
                      },
                    });
                  }}
                >
                  Assign to me
                </Typography>
              </InputLabel>
              <Select
                id="input-reporter"
                inputProps={{ style: { fontSize: "13px", outline: 0 } }}
                sx={{
                  fontSize: "13px",
                  display: "block",
                  marginBottom: "10px",
                }}
                size="small"
                name="reporter"
                value={this.state.tmpCardForm?.reporter || ""}
                displayEmpty
                onChange={this.handleChange}
              >
                <MenuItem sx={{ fontSize: "13px" }} value="">
                  None
                </MenuItem>
                {this.props.members.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      sx={{ fontSize: "13px" }}
                      value={item.id}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>

            <Box>
              {this.props.taskEditMode ? (
                <Button
                  disableElevation
                  variant="contained"
                  size="small"
                  sx={{ marginRight: "5px", marginTop: "0px" }}
                  onClick={() => {
                    this.props.handleSaveButton(
                      this.state.tmpCardForm,
                      this.state.tmpAddSubtask,
                      this.state.tmpFiles,
                      this.state.labelSelected
                    );
                    setTimeout(() => {
                      this.setState({
                        loadedData: false,
                        tmpCardForm: {},
                        tmpAddSubtask: [],
                        tmpFiles: [],
                        labelSelected: "",
                      });
                    }, 100);
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  disableElevation
                  variant="contained"
                  size="small"
                  sx={{ marginRight: "5px", marginTop: "0px" }}
                  onClick={() => {
                    if (this.state.tmpCardForm?.name !== undefined) {
                      this.props.createLocalTask(
                        this.state.tmpCardForm,
                        this.props.taskLaneId,
                        this.state.tmpAddSubtask
                      );
                    } else {
                      alert("Name cannot be empty");
                    }
                  }}
                >
                  Create Task
                </Button>
              )}

              <Button
                disableElevation
                variant="contained"
                size="small"
                color="secondary"
                sx={{ marginRight: "5px", marginTop: "0px" }}
                onClick={(evt) => {
                  evt.preventDefault();
                  this.setState({ tmpAddSubtask: [], loadedData: false });
                  this.props.handleCloseNewCardForm();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>

          {this.props.taskEditMode ? (
            <Box className="right-detail">
              <Box>Activities</Box>
              <InputComment addComment={this.addComment} />
              <FeedsComment comments={this.state.comments} />
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Drawer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProjectLabel: (user) => dispatch(addProjectLabel(user)),
    getProjectLabel: (user) => dispatch(getProjectLabel(user)),
    getDetailTask: (user) => dispatch(getDetailTask(user)),
    addComment: (user) => dispatch(addComment(user)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddCardDrawer)
);
