import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    //Link,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {
    Stack,
    TextField,
    // InputLabel,
} from '@mui/material';

import {
    joinOrganization,
    searchOrganization
} from '../../redux/actions/organizations';

class SearchOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModalSearch: false,
            keyword: "",
            result: null,
            isJoining: false,
            err: null,
            errJoin: null,
        };

        this.handleJoin = this.handleJoin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    handleJoin(e) {
        e.preventDefault();
        this.setState({ isJoining: true });

        this.props.joinOrganization({ url: e.currentTarget.getAttribute("data-url") }).then((res) => {
            if (res.code !== 200) {
                this.setState({ isJoining: false, errJoin: res.message });
            } else {
                this.setState({ isJoining: false, errJoin: "Join Request Sent" });
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSearch(e) {
        e.preventDefault();
        this.setState({ err: null, errJoin: null });

        if (this.state.keyword !== "") {
            this.props.searchOrganization({ keyword: this.state.keyword }).then((res) => {
                this.setState({ result: res.data });
            }).catch((err) => {
                console.log(err);
            })
        } else {
            this.setState({ err: "url cannot be empty" });
        }
    }

    render() {
        return (
            <Modal
                open={this.props.openModalSearch}
                onClose={() => {
                    this.setState({
                        openModalSearch: false,
                        keyword: "",
                        result: null,
                        isJoining: false,
                        err: null,
                        errJoin: null
                    }, function () {
                        this.props.handleCloseModal()
                    })
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal" sx={{}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Search Organization
                    </Typography>
                    <Typography id="modal-modal-description" sx={{}}>
                        Make sure you know the url of the organization
                    </Typography>
                    <Stack direction={"row"} spacing="5px" sx={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "5px" }}>
                        <TextField value={this.state.handleChange} autoFocus name="keyword" size="small" type="text" placeholder='url'
                            onChange={this.handleChange}
                        />
                        <Button onClick={this.handleSearch} disableElevation variant="contained">Search</Button>
                    </Stack>
                    {this.state.err !== null ?
                        <Box sx={{ color: "#f00" }}>
                            {this.state.err}
                        </Box> : ""}

                    {this.state.result !== null ? <>
                        {this.state.result.length === 0 ?
                            <Box sx={{ marginTop: "20px" }}>
                                <Typography>Organization not Found</Typography>
                            </Box>
                            : <Box sx={{ marginTop: "20px" }}>
                                <Typography>Search Result : </Typography>
                                <Box>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        backgroundColor: "#d9d9d9",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        marginTop: "5px",
                                    }}
                                    >
                                        <Box>
                                            <Typography sx={{ fontWeight: "800" }}>{this.state.result[0].name}</Typography>
                                            <Typography>{this.state.result[0].description}</Typography>
                                        </Box>
                                        <Box sx={{ textAlign: "right" }}>
                                            <Button disabled={this.state.isJoining} onClick={this.handleJoin} data-url={this.state.result[0].url} color="primary" variant="contained">
                                                {this.state.isJoining ? "Joining" : "Request Join"}
                                            </Button>
                                            {this.state.errJoin !== null ?
                                                <Typography sx={{ marginTop: "10px", fontSize: "13px", color: "#f00" }}>{this.state.errJoin}</Typography> : ""}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>}
                    </> : ""}
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        searchOrganization: userData => dispatch(searchOrganization(userData)),
        joinOrganization: userData => dispatch(joinOrganization(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchOrganization));