import React, { Component } from 'react';
import { connect } from "react-redux";

// import { forceLogout } from "../../redux/actions/users";

import {
    withRouter
} from "react-router-dom";

import {
    Box,
    // Grid,
    // Drawer,
    Button,
    // Select,
    // Checkbox,
    // MenuItem,
    TextField,
    Typography,
    // InputLabel,
    // Autocomplete,
    // FormControlLabel,
} from '@mui/material';

// import { Mention, MentionsInput } from 'react-mentions'

class InputComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // account_type:0,
            // mobileMenu:false,
            // logoIcon:'',
            users: [
                {
                    id: 1,
                    display: 'Walter White',
                },
                {
                    id: 2,
                    display: 'White Pinkman',
                },
                {
                    id: 3,
                    display: 'Jessy White',
                },
                {
                    id: 4,
                    display: 'Jessy Pinkman',
                },
            ],
            mentions: "",
            commentText: "",
            err: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMentionChange = this.handleMentionChange.bind(this);
        this.renderUserSuggestion = this.renderUserSuggestion.bind(this);
    }

    renderUserSuggestion(e) {
        console.log(e);
        return (
            <Typography sx={{ padding:"5px", fontWeight: "800" }}>{e.display}</Typography>
        )
    }

    handleMentionChange(e) {
        console.log(e);
        this.setState({ mentions: e.target.value });
    }

    handleChange(e) {
        this.setState({ commentText: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        let ctx = this;
        //{ data: "data", comment: this.state.commentText }
        this.props.addComment(this.state.commentText, function () {
            ctx.setState({ commentText: "" });
        })
    }

    // componentDidMount() {
    //     console.log('header mounted');
    // }

    render() {
        return (
            <Box component="form" onSubmit={this.handleSubmit}>
                {/* <MentionsInput className='input-comment-mentionxxxxx' style={{padding:"10px"}} value={this.state.mentions} onChange={this.handleMentionChange} inputprops={{ style: { fontSize: "14px", outline: 0, whiteSpace: "break-spaces", lineHeight: "17px", resize: "auto" } }}>
                    <Mention
                        style={{padding:"10px"}}
                        trigger="@"
                        markup="@[__display__](__id__)"
                        data={this.state.users}
                        renderSuggestion={this.renderUserSuggestion}
                        appendSpaceOnAdd
                    />
                    <Mention
                        trigger="#"
                        data={this.requestTag}
                        renderSuggestion={this.renderTagSuggestion}
                    />
                </MentionsInput> */}

                {/* <InputLabel className='form-label' htmlFor="input-description">Description</InputLabel> */}
                <TextField required id="input-description" value={this.state.commentText || ""} onChange={this.handleChange} multiline rows={5} name="description" inputProps={{ style: { fontSize: "14px", outline: 0, whiteSpace: "break-spaces", lineHeight: "17px", resize: "auto" } }} sx={{ display: "block", marginBottom: "5px", outline: "none", marginTop: "15px" }} fullWidth size='small' placeholder="Your Comments" />
                <Button type="submit" disableElevation sx={{ marginLeft: "auto", display: "block" }} size='small' variant='contained' color='success'>Add Comment</Button>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // forceLogout: data => dispatch(forceLogout(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InputComment));