import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    //Link,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

// import {
//     Stack,
//     TextField,
//     InputLabel,
// } from '@mui/material';

import {
    acceptOrganization
} from '../../redux/actions/organizations';

class InvitationOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: null
        };

        this.handleAccept = this.handleAccept.bind(this);
    }

    handleAccept(e) {
        console.log("handleAccept");
        console.log(e.currentTarget.getAttribute('data-idinvitation'));

        this.props.acceptOrganization({ id: e.currentTarget.getAttribute('data-idinvitation') }).then((res) => {
            console.log(res);
            alert("Invitation Accepted");
            this.props.handleCloseInvitationModal();
        }).catch((err) => {
            console.log(err);
        })
    }

    componentDidMount() {
        console.log('InvitationOrganization mounted');
        console.log(this.props.invitations);
    }

    render() {
        return (
            <Modal
                open={this.props.openModalInvitation}
                onClose={this.props.handleCloseInvitationModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal" sx={{}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: "10px" }}>
                        Invitations
                    </Typography>

                    <Box>
                        {this.props.invitations?.map((item) => {
                            return (
                                <Box key={item.id} sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    border: "1px solid #aaaaaa",
                                    padding: "10px",
                                    borderRadius: "5px"
                                }}
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "16px", fontWeight: "800" }}>{item.organization.name}</Typography>
                                        <Typography>{item.organization.description}</Typography>
                                    </Box>
                                    <Box>
                                        <Button data-idinvitation={item.id} size="small" disableElevation variant="contained"
                                            onClick={this.handleAccept}
                                        >Accept Invitation</Button>
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                    {/* <Typography id="modal-modal-description" sx={{}}>
                    Make sure you know the url of the organization
                </Typography> */}
                    {/* <Stack direction={"row"} spacing="5px" sx={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "5px" }}> */}
                    {/* <Box>
                    <TextField size="small" type="text" placeholder='url' />
                    <Button disableElevation variant="contained">Create Organization</Button>
                </Box> */}
                    {/* </Stack> */}
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        acceptOrganization: userData => dispatch(acceptOrganization(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitationOrganization));