import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const updateProject = (payload) => {
  return (dispatch) => {
    let tkk = 'Bearer '+window.localStorage.getItem('app_tt');

    let data = {};
    data.name = payload.name || null;
    data.type = payload.type || null;
    data.phone = payload.phone || null;
    data.description = payload.description || null;
    data.instagramurl = payload.instagramurl || null;
    data.websiteurl = payload.websiteurl || null;
    data.status = payload.status || null;
    data.is_active = payload.is_active || null;

    // if(payload.description !== undefined){
    //   data.description = payload.description;
    // }else{
    //   data.description = null;
    // }

    return( axios.put(BASE_URL+BASE_VERSION+'project/'+payload.url+'/'+payload.uuid, data, {headers: { Authorization: tkk }, validateStatus: () => true})
      .then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload.error = error;
        return payload;
      })
    )
  }
}

export const createProject = (payload) => {
  return (dispatch) => {
    let tkk = 'Bearer '+window.localStorage.getItem('app_tt');

    let data = new FormData();
    data.append('type', payload.data.type || 1);
    data.append('name', payload.data.name);

    if(payload.data.description !== undefined){
      data.append('description', payload.data.description || null);
    }
    if(payload.data.finish_date !== undefined){
      data.append('finish_date', payload.data.finish_date);
    }
    if(payload.data.start_date !== undefined){
      data.append('start_date', payload.data.start_date || null);
    }

    return( axios.post(BASE_URL+BASE_VERSION+'project/'+payload.url, data, {headers: { Authorization: tkk }, validateStatus: () => true}).then(function (response) {
      if(response.data.code === 200){
        payload = response.data;
        dispatch({ type: 'APPEND_PROJECT', payload })
      }else{
        payload = {code:500, message:"Error"}
      }
        return payload;
      }).catch(function (error) {
        payload.error = error;
        return payload;
      })
    )
  }
}

export const getProjectLabel = (payload) => {
  return (dispatch) => {
    let tkk = 'Bearer '+window.localStorage.getItem('app_tt');
    return( axios.get(BASE_URL+BASE_VERSION+'project/label/'+payload.id, {headers: { Authorization: tkk }, validateStatus: () => true})
      .then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload.error = error;
        return payload;
      })
    )
  }
}

export const getActivityProject = (payload) => {
  return (dispatch) => {
    let tkk = 'Bearer '+window.localStorage.getItem('app_tt');
    return( axios.get(BASE_URL+BASE_VERSION+'project/activity/'+payload.id, {headers: { Authorization: tkk }, validateStatus: () => true})
      .then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload.error = error;
        return payload;
      })
    )
  }
}

export const getDetailProject = (payload) => {
  return (dispatch) => {
    let tkk = 'Bearer '+window.localStorage.getItem('app_tt');
    return( axios.get(BASE_URL+BASE_VERSION+'project/'+payload.url+'/'+payload.uuid, {headers: { Authorization: tkk }, validateStatus: () => true})
      .then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload.error = error;
        return payload;
      })
    )
  }
}

export const getProjectMember = (payload) => {
  return (dispatch) => {
    let tkk = 'Bearer '+window.localStorage.getItem('app_tt');
    return( axios.get(BASE_URL+BASE_VERSION+'project/'+payload.url+'/'+payload.uuid+'/member', {headers: { Authorization: tkk }, validateStatus: () => true})
      .then(function (response) {
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload.error = error;
        return payload;
      })
    )
  }
}

export const addProjectLabel = (payload) => {
  return (dispatch) => {
    let tkk = 'Bearer '+window.localStorage.getItem('app_tt');

    let data = {};
    data.name = payload.name

    return( axios.post(BASE_URL+BASE_VERSION+'project/'+payload.url+'/'+payload.uuid+'/label', data, {headers: { Authorization: tkk }, validateStatus: () => true}).then(function (response) {      
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload.error = error;
        return payload;
      })
    )
  }
}

export const addProjectMember = (payload) => {
  return (dispatch) => {
    let tkk = 'Bearer '+window.localStorage.getItem('app_tt');

    let data = {};
    data.iduser = payload.iduser

    return( axios.post(BASE_URL+BASE_VERSION+'project/'+payload.url+'/'+payload.uuid+'/member/add', data, {headers: { Authorization: tkk }, validateStatus: () => true}).then(function (response) {
      if(response.data.code === 200){
        payload = response.data;
      }else{
        payload = {code:500, message:"Error"}
      }
        return payload;
      }).catch(function (error) {
        payload.error = error;
        return payload;
      })
    )
  }
}

export const removeProjectMember = (payload) => {
  return (dispatch) => {
    let tkk = 'Bearer '+window.localStorage.getItem('app_tt');

    return( axios.delete(BASE_URL+BASE_VERSION+'project/'+payload.url+'/'+payload.uuid+'/member/remove/'+payload.iduser, {headers: { Authorization: tkk }, validateStatus: () => true}).then(function (response) {
        console.log(response.data);
        payload = response.data;
        return payload;
      }).catch(function (error) {
        payload.error = error;
        return payload;
      })
    )
  }
}

// export const getProduct = (payload) => {
//   return (dispatch) => {
//     let tkk = 'Bearer '+window.localStorage.getItem('app_tt');
//     return( axios.get(BASE_URL+BASE_VERSION+'product/'+payload.url+"/"+payload.slug, {headers: { Authorization: tkk }, validateStatus: () => true})
//       .then(function (response) {
//         payload = response.data;
//         console.log(payload);
//         return payload;
//       }).catch(function (error) {
//         payload.error = error;
//         return payload;
//       })
//     )
//   }
// }

// export const createProduct = (payload) => {
//   return (dispatch) => {
//     let tkk = 'Bearer '+window.localStorage.getItem('app_tt');

//     console.log(payload.url);
//     console.log(payload.product);
//     console.log(payload.variations);

//     let product = payload.product;
//     let variations = payload.variations;

//     let data = new FormData();
//     data.append('sku', product.sku);
//     data.append('name', product.name);
//     if(product.shortDescription !== null){data.append('short_name', product.shortDescription)}
//     if(product.description !== null){data.append('description', product.description)}
//     data.append('is_variation', product.is_variation);
//     data.append('price', product.price || 0);
//     data.append('is_sale', product.is_sale);
//     data.append('sale_price', product.sale_price);
//     data.append('is_instock', product.is_instock);
//     data.append('stock', product.stock);
//     data.append('is_preorder', product.is_preorder);
//     data.append('preorder_days', product.preorder_days);
//     data.append('width', product.width);
//     data.append('height', product.height);
//     data.append('length', product.length);
//     data.append('weight', product.weight);

//     console.log(variations);

//     variations.map((item)=>(
//       data.append('variations[]',  JSON.stringify(item))
//     ))

//     // data.append('variations[]', '{"attribute":"Hemat 10","is_instock":false, "stock":10, "is_sale":false, "price":90000, "sale_price":0, "is_active":true, "is_preorder":false, "preorder_days":0}');
//     // data.append('variations[]', '{"attribute":"Hemat 100","is_instock":false, "stock":10, "is_sale":false, "price":90000, "sale_price":0, "is_active":true, "is_preorder":false, "preorder_days":0}');
    
//     console.log(data);

//     return( axios.post(BASE_URL+BASE_VERSION+'product/'+payload.url, data, {headers: { Authorization: tkk }, validateStatus: () => true}).then(function (response) {
//         payload = response.data;
//         console.log(payload);
//         return payload;
//       }).catch(function (error) {
//         payload.error = error;
//         return payload;
//       })
//     )
//   }
// }

// export const checkLogin = (...payload) => {
//   let tkk = 'Bearer '+window.localStorage.getItem('app_tt');
//   return (dispatch) => {
//       return( axios.post(BASE_URL+BASE_VERSION+'user/me', null, {headers: { Authorization: tkk }, validateStatus: () => true})
//       .then(function (response) {
//         payload = response.data;

//         if(payload.code === 200){
//           payload = payload.data;
//           payload.code = 200;
//           payload.access_token = window.localStorage.getItem('app_tt');

//           dispatch({ type: 'SET_LOGIN', payload })
//         }else{
//           dispatch({ type: 'SET_LOGOUT', payload })
//           payload = {code:401, message: "Invalid credentials, please try again."}      
//         }
//         // if(response.data.code === 200){
//         //   if(response.data.user.id === 1 || response.data.user.id === 2){
//         //     payload.token = {access_token:window.localStorage.getItem('app_tt')};
//         //     dispatch({ type: 'SET_LOGIN', payload })
//         //   }else{
//         //     console.log('111');
//         //     dispatch({ type: 'SET_LOGOUT', payload })
//         //     payload = {code:401, message: "Invalid credentials, please try again."}
//         //   }
//         // }else{
//         //   console.log('222');
//         //   dispatch({ type: 'SET_LOGOUT', payload })
//         //   payload = {code:401, message: "Invalid credentials, please try again."}
//         // }
//         return payload;
//       }).catch(function (error) {
//         payload.error = error;
//         console.log(error);
//         console.log('333');
//         dispatch({ type: 'SET_LOGOUT', payload })
//         return payload;
//       })
//     )
//   }
// }

// export const forceLogout = (...payload) => {
//   return (dispatch) => {
//     dispatch({ type: 'SET_LOGOUT', payload })
//     payload = {code:200, message:"Success Logout"}
//     return payload;
//   }
// }