import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    //Link,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import {
    Stack,
    TextField,
    // InputLabel,
} from '@mui/material';

import {
    inviteOrganization,
    searchOrganization,
    joinOrganization 
} from '../../redux/actions/organizations';

import {
    validateEmail 
} from '../../utils/format';

class InviteOrganization extends Component {
    constructor(props) {
        super(props);
        this.emailInput = React.createRef();
        this.state = {
            openInviteModal: false,
            email: "",
            result: null,
            isJoining: false,
            err: null,
            errJoin: null,
        };

        this.handleJoin = this.handleJoin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleInvite = this.handleInvite.bind(this);
    }

    handleJoin(e) {
        e.preventDefault();
        this.setState({ isJoining: true });

        this.props.joinOrganization({ url: e.currentTarget.getAttribute("data-url") }).then((res) => {
            if (res.code !== 200) {
                this.setState({ isJoining: false, errJoin: res.message });
            } else {
                this.setState({ isJoining: false, errJoin: "Join Request Sent" });
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleInvite(e) {
        e.preventDefault();
        this.setState({ err: null, errJoin: null });

        if (this.state.email !== "") {
            if (validateEmail(this.state.email) !== null) {
                console.log(this.state.email);
                this.props.inviteOrganization({ url: this.props.organizationData.url, email: this.state.email }).then((res) => {
                    console.log(res);
                    if (res.code !== 200) {
                        this.setState({ err: res.message });
                    } else {
                        alert(res.message);
                        this.setState({
                            openInviteModal: false,
                            email: "",
                            result: null,
                            isJoining: false,
                            err: null,
                            errJoin: null,
                        }, function () {
                            this.emailInput.current.focus();
                        });
                    }
                }).catch((err) => {
                    console.log(err);
                })
            } else {
                this.setState({ err: "email is invalid" });
            }
        } else {
            this.setState({ err: "email cannot be empty" });
        }
    }

    // componentDidMount() {
    //     console.log(this.props.organizationData.url);
    // }

    render() {
        return (
            <Modal
                open={this.props.openInviteModal}
                onClose={() => {
                    this.setState({
                        openInviteModal: false,
                        email: "",
                        result: null,
                        isJoining: false,
                        err: null,
                        errJoin: null
                    }, function () {
                        this.props.handleCloseModal()
                    })
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Invite Member
                    </Typography>
                    <Typography id="modal-modal-description">
                        Make sure email is valid
                    </Typography>
                    <Stack direction={"row"} spacing="5px" sx={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "5px" }}>
                        <TextField inputRef={this.emailInput} value={this.state.email} autoFocus name="email" size="small" type="email" placeholder='user@email.com'
                            onChange={this.handleChange}
                        />
                        <Button onClick={this.handleInvite} disableElevation variant="contained">Invite</Button>
                    </Stack>
                    {this.state.err !== null ?
                        <Box sx={{ color: "#f00" }}>
                            {this.state.err}
                        </Box> : ""}

                    {/* {this.state.result !== null ? <>
                        {this.state.result.length === 0 ?
                            <Box sx={{ marginTop: "20px" }}>
                                <Typography>Organization not Found</Typography>
                            </Box>
                            : <Box sx={{ marginTop: "20px" }}>
                                <Typography>Search Result : </Typography>
                                <Box>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        backgroundColor: "#d9d9d9",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        marginTop: "5px",
                                    }}
                                    >
                                        <Box>
                                            <Typography sx={{ fontWeight: "800" }}>{this.state.result[0].name}</Typography>
                                            <Typography>{this.state.result[0].description}</Typography>
                                        </Box>
                                        <Box sx={{ textAlign: "right" }}>
                                            <Button disabled={this.state.isJoining} onClick={this.handleJoin} data-url={this.state.result[0].url} color="primary" variant="contained">
                                                {this.state.isJoining ? "Joining" : "Request Join"}
                                            </Button>
                                            {this.state.errJoin !== null ?
                                                <Typography sx={{ marginTop: "10px", fontSize: "13px", color: "#f00" }}>{this.state.errJoin}</Typography> : ""}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>}
                    </> : ""} */}
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        searchOrganization: userData => dispatch(searchOrganization(userData)),
        joinOrganization: userData => dispatch(joinOrganization(userData)),
        inviteOrganization: userData => dispatch(inviteOrganization(userData)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InviteOrganization));