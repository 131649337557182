import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    //Link,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

// import {
//     InputLabel,
//     Stack,
//     TextField 
// } from '@mui/material';

import {
    getOrganizationMembers
} from "../../redux/actions/organizations";

import {
    addProjectMember
} from "../../redux/actions/projects";

class MemberOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            err: null
        };

        this.loadData = this.loadData.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    handleAdd(e) {
        this.props.addProjectMember({
            url: this.props.organizationData.url,
            uuid: this.props.match.params.slug,
            iduser: e.currentTarget.getAttribute("data-iduser"),
        }).then((res) => {
            this.props.handleAdded();
        }).catch((err) => {
            console.log(err);
        })
    }

    loadData() {
        this.props.getOrganizationMembers({ id: this.props.organizationData.id }).then((res) => {
            var filteredUser = res.data.users.filter((user) => {
                let anu = this.props.members.find((item) => item.id === user.id)
                if (anu === undefined) {
                    return (user)
                } else {
                    return "";
                }
            });
            this.setState({ users: filteredUser })
        }).catch((err) => {
            console.log(err);
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.members !== this.props.members) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <Modal
                open={this.props.openModal}
                onClose={this.props.handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal" sx={{}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: "10px" }}>
                        Member Organization
                    </Typography>
                    <Box>
                        {this.state.users.map((item) => {
                            return (
                                <Box key={item.id}>
                                    <Box sx={{ backgroundColor: "#e3e3e3", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "5px", padding: "5px", borderRadius: "5px" }}>
                                        <Box>
                                            <Box sx={{ fontSize: "14px", fontWeight: "800" }}>{item.fullname}</Box>
                                            <Box sx={{ fontSize: "13px" }}>{item.organization_user_roles.roles}</Box>
                                        </Box>
                                        <Box>
                                            <Button
                                                sx={{ fontSize: "11px" }}
                                                size="small"
                                                color="secondary"
                                                variant="outlined"
                                                data-iduser={item.id}
                                                onClick={this.handleAdd}
                                            >Add to Project</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })}
                        {this.state.users.length === 0 ? <Typography sx={{ display: "block", marginTop: "10px", marginBottom:"5px" }}>All members joined</Typography> : ""}
                    </Box>
                </Box>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOrganizationMembers: userData => dispatch(getOrganizationMembers(userData)),
        addProjectMember: userData => dispatch(addProjectMember(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberOrganization));