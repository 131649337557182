import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    //Link,
    withRouter
} from "react-router-dom";

import { getOrganizationRoles, setOrganizationRole } from "../../../redux/actions/organizations";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import {
    // Stack,
    // TextField,
    // InputLabel,
    Select,
    MenuItem,
} from '@mui/material';

class ChangeRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            tmpRole: null,
            // url: "",
            // name: "",
            // description: "",
            // phone: "",
            // website: "",
            // instagram: "",
            err: null
        };

        // this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // handleChange(e) {
    //     this.setState({
    //         [e.target.name]: e.target.name === "url" ? e.target.value.toLowerCase().replace(/\s/g, '') : e.target.value
    //     });
    // }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.tmpRole !== null) {
            if (this.props.currentIdRole !== this.state.tmpRole) {
                this.props.setOrganizationRole({
                    url: this.props.organizationData.url,
                    iduser: this.props.currentIdUser,
                    role: this.state.tmpRole
                }).then((res) => {
                    if (res.code === 200) {
                        this.props.handleCloseModal();
                        window.location.reload();
                    }
                }).catch((err) => {
                    console.log(err);
                })
            } else {
                this.props.handleCloseModal()
            }
        } else {
            this.props.handleCloseModal()
        }
    }

    componentDidMount() {
        this.props.getOrganizationRoles({ url: this.props.organizationData.url }).then((res) => {
            this.setState({ roles: res.data });
        }).catch((err) => {
            console.log(err);
        })
    }

    render() {
        return (
            <Modal
                open={this.props.openModal}
                onClose={this.props.handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal">
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: "10px" }}>
                        Change Member Roles
                    </Typography>
                    <Box sx={{ display: "block" }} component="form" onSubmit={this.handleSubmit}>
                        <Select size="small" defaultValue={this.props.currentIdRole} displayEmpty
                            onChange={(e) => {
                                this.setState({ tmpRole: e.target.value });
                            }}
                        >
                            {this.state.roles.map((item) => {
                                return (
                                    <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
                                )
                            })}
                        </Select>

                        <Button className='submit' label="Submit" variant="contained" type="submit" sx={{ display: "block", marginTop: "20px" }}>Save</Button>
                    </Box>
                </Box>
            </Modal >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setOrganizationRole: data => dispatch(setOrganizationRole(data)),
        getOrganizationRoles: data => dispatch(getOrganizationRoles(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeRole));