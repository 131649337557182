import axios from 'axios';
import { BASE_URL, BASE_VERSION } from "../config";

export const getProvince = (...payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'shipping/province', { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const getCity = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'shipping/city/' + payload.idprovince, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const getSuburb = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'shipping/suburb/' + payload.idcity, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const getArea = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'shipping/area/' + payload.idsuburb, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}


export const calculateRate = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'shipping/rates', {
      type: payload.type,
      value: payload.value,
      suburb_destination: payload.suburb_destination,
      area_destination: payload.area_destination,
      suburb_origin: payload.suburb_origin,
      area_origin: payload.area_origin,
      weight:payload.weight,
      width:payload.width,
      length:payload.length,
      height:payload.height,
    }, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}