import React, { Component } from 'react';
import { connect } from "react-redux";

import { addAddress } from '../../../redux/actions/users';
import { getProvince, getCity, getSuburb, getArea } from '../../../redux/actions/addresses';

import {
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import InputAdornment from '@mui/material/InputAdornment';
import { Select, MenuItem } from '@mui/material';

class AddRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            recipient_name: null,
            phone: null,
            address: null,
            notes: null,
            address_2: null,
            provinces: [],
            idprovince: null,
            province: null,
            cities: [],
            idcity: null,
            city: null,
            suburbs: [],
            idsuburb: null,
            suburb: null,
            areas: [],
            idarea: null,
            area: null,
            postalcode: null,
            err: null
        };

        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.loadCity = this.loadCity.bind(this);
        this.loadSuburb = this.loadSuburb.bind(this);
        this.loadArea = this.loadArea.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChange(e){
        console.log(e.target.name);
        console.log(e.target.value);
        this.setState({
            [e.target.name]:e.target.value
        })

        // name: payload.name,
        // recipient_name: payload.recipient_name,
        // phone: payload.phone,
        // address: payload.address,
        // notes: payload.notes,
        // address_2: payload.address_2,

        // name: null,
        // recipient_name: null,
        // phone: null,
        // address: null,
        // notes: null,
        // address_2: null,
    }

    handleSubmit(e){
        e.preventDefault();
        console.log("SUBMIT!!!");

        this.props.addAddress({
            name:this.state.name,
            recipient_name:this.state.recipient_name,
            phone:this.state.phone,
            address:this.state.address,
            notes:this.state.notes,
            idprovince:this.state.idprovince,
            province:this.state.province,
            idcity:this.state.idcity,
            city:this.state.city,
            idsuburb:this.state.idsuburb,
            suburb:this.state.suburb,
            idarea:this.state.idarea,
            area:this.state.area,
            address_2:this.state.address_2,
            postalcode:this.state.postalcode
        }).then((res)=>{
            console.log(res);
            if(res.code === 200){
                alert("Congratulation! New Address Added");
                setTimeout(() => {
                    this.props.history.replace("/"+this.props.organizationData.url+"/address", "urlhistory");
                }, 200);
            }else{
                alert("Add Address Error");
            }
        }).catch((err)=>{
            console.log(err);
            alert("Add Address Error");
        })
    }

    loadCity(e) {
        this.props.getCity({ idprovince: e }).then((res) => {
            let result = res.data.data.sort(function (a, b) {
                var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                if (nameA < nameB)
                    return -1
                if (nameA > nameB)
                    return 1
                return 0
            })
            this.setState({ cities: result });
        })
    }

    loadSuburb(e) {
        this.props.getSuburb({ idcity: e }).then((res) => {
            console.log(res);
            let result = res.data.data.sort(function (a, b) {
                var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                if (nameA < nameB)
                    return -1
                if (nameA > nameB)
                    return 1
                return 0
            })
            this.setState({ suburbs: result });
        })
    }

    loadArea(e) {
        this.props.getArea({ idsuburb: e }).then((res) => {
            console.log(res);
            let result = res.data.data.sort(function (a, b) {
                var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                if (nameA < nameB)
                    return -1
                if (nameA > nameB)
                    return 1
                return 0
            })
            this.setState({ areas: result });
        })
    }

    onSelectChange(e) {
        if (e.target.name === "province") {
            let myIdx = this.state.provinces.findIndex(x => x.id === e.target.value);
            let nameProvince = this.state.provinces[myIdx].name;
            this.setState({ idprovince: e.target.value, province: nameProvince, idcity:null, idsuburb:null, idarea:null, postalcode:null, suburbs:[], areas:[]}, function () {
                this.loadCity(e.target.value);
            });
        } else if (e.target.name === "city") {
            let myIdx = this.state.cities.findIndex(x => x.id === e.target.value);
            let nameCity = this.state.cities[myIdx].name;
            this.setState({ idcity: e.target.value, city: nameCity, idsuburb:null, idarea:null, suburbs:[], areas:[]}, function () {
                this.loadSuburb(e.target.value);
            });
        } else if (e.target.name === "suburb") {
            let myIdx = this.state.suburbs.findIndex(x => x.id === e.target.value);
            let nameSuburb = this.state.suburbs[myIdx].name;
            this.setState({ idsuburb: e.target.value, suburb: nameSuburb, idarea:null, areas:[]}, function () {
                this.loadArea(e.target.value);
            });
        } else if (e.target.name === "area") {
            let myIdx = this.state.areas.findIndex(x => x.id === e.target.value);
            let nameArea = this.state.areas[myIdx].name;
            let postalCode = this.state.areas[myIdx].postcode;
            this.setState({ idarea: e.target.value, area: nameArea, postalcode: postalCode });
        }
    }

    componentDidMount() {
        console.log('add rule mounted');
        this.props.getProvince().then((res) => {
            let result = res.data.data.sort(function (a, b) {
                return a.name - b.name
            })
            this.setState({ provinces: result });
        }).catch((err) => {
            console.log(err);
        })
    }

    render() {
        return (
            <Box className="box-container" component="form" onSubmit={this.handleSubmit}>
                <Box className='paper-header'>
                    <Typography className='title'>Information Address</Typography>
                </Box>
                <Paper elevation={2} className='paper-container'>
                    <Grid className="form-row" container spacing={2}>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0px" }}>Nama Address *wajib</h4>
                            <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                                Deskripsikan nama alamat untuk memudahkan dalam pemilihan alamat saat pembelian
                            </p>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField required id="outlined-basic" name="name" onChange={this.onChange} placeholder="Nama Alamat" variant="outlined" size='small' helperText="Contoh : Kantor, Rumah" fullWidth />
                            {/* onChange={this.handleTextChange} value={this.state.product.name || ""}  */}
                        </Grid>
                    </Grid>

                    <Grid className="form-row" container spacing={2}>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0px" }}>Nama Penerima *wajib</h4>
                            <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                                Deskripsikan nama penerima untuk memudahkan dalam pemilihan alamat saat pembelian
                            </p>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField required id="outlined-basic" name="recipient_name" onChange={this.onChange} placeholder="Nama Penerima" variant="outlined" size='small' helperText="Contoh : Kantor, Rumah" fullWidth />
                            {/* onChange={this.handleTextChange} value={this.state.product.name || ""}  */}
                        </Grid>
                    </Grid>

                    <Grid className="form-row" container spacing={2}>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0px" }}>No telp. Penerima *wajib</h4>
                            <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                                Deskripsikan nomor telepon penerima untuk memudahkan dalam pemilihan alamat saat pembelian
                            </p>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField required id="outlined-basic" name="phone" onChange={this.onChange} placeholder="+628212111111" variant="outlined" size='small' helperText="Contoh : Kantor, Rumah" fullWidth />
                            {/* onChange={this.handleTextChange} value={this.state.product.name || ""}  */}
                        </Grid>
                    </Grid>

                    <Grid className="form-row" container spacing={2}>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0px" }}>Alamat *wajib</h4>
                            <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                                Pastikan deskripsi produk memuat penjelasan detail terkait produkmu agar pembeli mudah mengerti dan menemukan produkmu.
                            </p>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField multiline rows={2} required id="outlined-basic" name="address" onChange={this.onChange} placeholder="Seblak&#10;a&#10;Rasa\nOriginal" variant="outlined" size='small' helperText="Tips: Tulis deskripsi produkmu min. 260 karakter agar pembeli semakin mudah mengerti" fullWidth />
                            {/* value={this.state.product.description || ""} onChange={this.handleTextChange}  */}
                        </Grid>
                    </Grid>

                    <Grid className="form-row" container spacing={2}>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0px" }}>Notes</h4>
                            <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                                Tambahkan catatan terkait alamat jika ada, misalkan nomor gedung, lantai atau patokan
                            </p>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField multiline rows={2} id="outlined-basic" name="notes" onChange={this.onChange} placeholder="Seblak&#10;a&#10;Rasa\nOriginal" variant="outlined" size='small' helperText="Tips: Tambahkan nomor gedung atau patokan tambahan untuk kurir" fullWidth />
                            {/* value={this.state.product.description || ""} onChange={this.handleTextChange}  */}
                        </Grid>
                    </Grid>

                    <Grid className="form-row" container spacing={2}>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0px" }}>Provinsi *wajib</h4>
                            <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                                Potongan diskon persentase yang akan didapatkan oleh user setiap melakukan transaksi
                            </p>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                required
                                size='small'
                                name="province"
                                displayEmpty
                                onChange={this.onSelectChange}
                                value={this.state.idprovince || ""}
                            >
                                <MenuItem value="">Select Province</MenuItem>
                                {this.state.provinces.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid className="form-row" container spacing={2}>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0px" }}>Kota *wajib</h4>
                            <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                                Potongan diskon persentase yang akan didapatkan oleh user setiap melakukan transaksi
                            </p>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                required
                                size='small'
                                name="city"
                                displayEmpty
                                onChange={this.onSelectChange}
                                value={this.state.idcity || ""}
                            >
                                <MenuItem value="">Select City</MenuItem>
                                {this.state.cities.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid className="form-row" container spacing={2}>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0px" }}>Kecamatan *wajib</h4>
                            <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                                Potongan diskon persentase yang akan didapatkan oleh user setiap melakukan transaksi
                            </p>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                required
                                size='small'
                                name="suburb"
                                displayEmpty
                                onChange={this.onSelectChange}
                                value={this.state.idsuburb || ""}
                            >
                                <MenuItem value="">Select Suburb</MenuItem>
                                {this.state.suburbs.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid className="form-row" container spacing={2}>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0px" }}>Kelurahan *wajib</h4>
                            <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                                Potongan diskon persentase yang akan didapatkan oleh user setiap melakukan transaksi
                            </p>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                required
                                size='small'
                                name="area"
                                displayEmpty
                                onChange={this.onSelectChange}
                                value={this.state.idarea || ""}
                            >
                                <MenuItem value="">Select Area</MenuItem>
                                {this.state.areas.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                    </Grid>

                    {/* <Grid className="form-row" container spacing={2}>
                        <Grid item xs={4}>
                            <h4 style={{ margin: "0px" }}>Kodepos *wajib</h4>
                            <p style={{ fontSize: "12px", lineHeight: "20px" }}>
                                Kodepos
                            </p>
                        </Grid>
                        <Grid item xs={8}>
                            helperText="Tips: 40001"
                            <TextField disabled value={this.state.postalcode || ""} type="number" id="outlined-basic" name="discount_rate" placeholder="40001" variant="outlined" size='small' />
                            value={this.state.product.shortDescription || ""} onChange={this.handleTextChange} 
                        </Grid>
                    </Grid> */}
                </Paper>
                <Box className='paper-action'>
                    <Button className='submit' label="Cancel" variant="outlined" onClick={() => {
                        let exit = window.confirm("Yakin batal?");
                        if (exit) {
                            this.props.history.goBack();
                        }
                    }}>Cancel</Button>
                    <Button className='submit' label="Submit" variant="contained" type="submit">Submit</Button>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProvince: data => dispatch(getProvince(data)),
        getCity: data => dispatch(getCity(data)),
        getSuburb: data => dispatch(getSuburb(data)),
        getArea: data => dispatch(getArea(data)),
        addAddress: data => dispatch(addAddress(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRule));