import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    Box,
    Button,
    Select,
    Checkbox,
    MenuItem,
    TextField,
    Typography,
    FormControlLabel
} from '@mui/material';

import {
    format,
} from 'date-fns';

import InfoIcon from '@mui/icons-material/Info';
import StarsIcon from '@mui/icons-material/Stars';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import BugReportIcon from '@mui/icons-material/BugReport';

class NewCardFormInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tmpCardForm: null,
            err: null
        };

        this.handleChangeCheck = this.handleChangeCheck.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChangeStartDate = this.handleDateChangeStartDate.bind(this);
        this.handleDateChangeDueDate = this.handleDateChangeDueDate.bind(this);
    }

    handleChangeCheck(e, f, g, h) {
        let ctx = this;
        ctx.setState({
            tmpCardForm: {
                ...ctx.state.tmpCardForm,
                [e.target.name]: Boolean(f),
            }
        });
    }

    handleChange(e) {
        if (e.target.name !== "name") {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    [e.target.name]: e.target.value
                }
            });
        } else {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    [e.target.name]: e.target.value,
                    title: e.target.value,
                }
            });
        }
    }

    handleDateChangeStartDate(date, e, f, g) {
        if (date !== null) {
            let todayDate = new Date(date);
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    start_date: todayDate,
                    start_date_label: format(todayDate, "yyyy-MM-dd kk:mm:ss"),
                }
            });
        } else {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    start_date: null,
                    start_date_label: null,
                }
            });
        }
    }

    handleDateChangeDueDate(date, e, f, g) {
        if (date !== null) {
            let todayDate = new Date(date);
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    due_date: todayDate,
                    due_date_label: format(todayDate, "yyyy-MM-dd kk:mm:ss"),
                }
            });
        } else {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    due_date: null,
                    due_date_label: null,
                }
            });
        }
    }

    render() {
        return (
            <Box id="new-card-id" sx={{
                backgroundColor: "#f8f8f8",
                padding: "10px",
                borderRadius: "5px"
            }}>
                <Box sx={{ position: "relative" }}>
                    <Typography sx={{ marginBottom: "10px" }}>Add new Task</Typography>
                    <Typography className="create-card-btn-close" title="Cancel add card" sx={{ fontSize: "12px", cursor: "pointer", position: "absolute", top: "2px", right: "0px" }} onClick={(evt) => {
                        evt.preventDefault();
                        this.props.handleCloseNewCardForm();
                        this.props.e.onCancel();
                    }}>Cancel</Typography>
                </Box>
                <Box>
                    <Select
                        name="type"
                        size="small"
                        displayEmpty
                        inputProps={{ style: { fontSize: "13px", outline: 0, alignItems: "center" } }}
                        sx={{
                            fontSize: "13px",
                            marginBottom: "5px",
                            alignItems: "center",
                            ".MuiSelect-select": {
                                display: "flex",
                                alignItems: "center"
                            }
                        }}
                        value={this.state.tmpCardForm?.type || 1}
                        onChange={this.handleChange}
                    >
                        <MenuItem sx={{ fontSize: "13px" }} value={1}><InfoIcon size="small" sx={{ position: "relative", top: "-1px", fontSize: "13px", marginRight: "7px" }} />Task</MenuItem>
                        <MenuItem sx={{ fontSize: "13px" }} value={2}><StarsIcon size="small" sx={{ position: "relative", top: "-1px", fontSize: "13px", marginRight: "7px" }} /> Story</MenuItem>
                        <MenuItem sx={{ fontSize: "13px" }} value={3}><FlashOnIcon size="small" sx={{ position: "relative", top: "-1px", fontSize: "13px", marginRight: "7px" }} /> Feature</MenuItem>
                        <MenuItem sx={{ fontSize: "13px" }} value={4}><BugReportIcon size="small" sx={{ position: "relative", top: "-1px", fontSize: "13px", marginRight: "7px" }} /> Bug</MenuItem>
                    </Select>
                    <TextField autoFocus value={this.state.tmpCardForm?.name || ""} onChange={this.handleChange} name="name" inputProps={{ style: { fontSize: "13px", outline: 0 } }} sx={{ display: "block", marginBottom: "5px", outline: "none" }} fullWidth size='small' placeholder="Name" />

                    {/* <TextField value={this.state.tmpCardForm?.description || ""} onChange={this.handleChange} multiline rows={3} name="description" inputProps={{ style: { fontSize: "13px", outline: 0, whiteSpace: "break-spaces", lineHeight: "14px" } }} sx={{ display: "block", marginBottom: "5px", outline: "none", position: "relative" }} fullWidth size='small' placeholder="Description" /> */}

                    <Select
                        inputProps={{ style: { fontSize: "13px", outline: 0 } }}
                        sx={{ fontSize: "13px", display: "block" }}
                        size="small"
                        name="priority"
                        value={this.state.tmpCardForm?.priority || ""}
                        displayEmpty
                        onChange={this.handleChange}
                    >
                        <MenuItem sx={{ fontSize: "13px" }} value="">Select Priority</MenuItem>
                        <MenuItem sx={{ fontSize: "13px" }} value={1}>Low</MenuItem>
                        <MenuItem sx={{ fontSize: "13px" }} value={2}>Medium</MenuItem>
                        <MenuItem sx={{ fontSize: "13px" }} value={3}>High</MenuItem>
                    </Select>

                    <FormControlLabel
                        value={this.state.tmpCardForm?.use_due_date || false}
                        control={<Checkbox
                            checked={this.state.tmpCardForm?.use_due_date || false}
                            label="Set Due Date"
                            name="use_due_date"
                            onChange={this.handleChangeCheck}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />}
                        label={<Typography sx={{ position: "relative", top: "1px" }} variant="body2" color="textSecondary">Use Date</Typography>}
                        labelPlacement="end"
                    />

                    {this.state.tmpCardForm?.use_due_date ?
                        <>
                            <DatePicker
                                isClearable
                                id="task-datetime-picker"
                                className="task-datetime-picker start"
                                inputProps={{ style: { backgroundColor: "#00f", fontSize: "13px", outline: 0 } }}
                                style={{ position: "relative", padding: "10px", backgroundColor: "#f00" }}
                                selected={this.state.tmpCardForm?.start_date}
                                placeholderText="Click to select a start date"
                                timeInputLabel="Time : "
                                showTimeSelect
                                dateFormat="dd/MMM/yyyy kk:mm"
                                timeIntervals={60}
                                onChange={this.handleDateChangeStartDate}
                                name="start_date"
                            />
                            <DatePicker
                                isClearable
                                id="task-datetime-picker"
                                className="task-datetime-picker"
                                inputProps={{ style: { backgroundColor: "#00f", fontSize: "13px", outline: 0 } }}
                                style={{ padding: "10px", backgroundColor: "#f00" }}
                                selected={this.state.tmpCardForm?.due_date}
                                placeholderText="Click to select a due date"
                                timeInputLabel="Time : "
                                showTimeSelect
                                dateFormat="dd/MMM/yyyy kk:mm"
                                timeIntervals={60}
                                onChange={this.handleDateChangeDueDate}
                                name="due_date"
                            />
                        </>
                        : ""}
                </Box>
                <Box>
                    <Button variant="contained" size="small" sx={{ marginRight: "5px", marginTop: "10px" }} onClick={() => {
                        if (this.state.tmpCardForm?.name !== undefined) {
                            this.props.e.onAdd(this.state.tmpCardForm);
                        } else {
                            alert("Name cannot be empty");
                        }
                    }}>Add</Button>
                    <Button variant="contained" size="small" sx={{ marginRight: "5px", marginTop: "10px" }} onClick={(evt) => {
                        evt.preventDefault();
                        this.props.handleCloseNewCardForm();
                        this.props.e.onCancel();
                    }}>Cancel</Button>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewCardFormInput));