import axios from 'axios';
import { BASE_URL, BASE_VERSION } from "../config";

export const getUserReport = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  // let ids = payload.ids;
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'user/report/' + payload.id, {
      iduser: payload.iduser
    }, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const getMyReport = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'user/myreport/' + payload.id, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      // dispatch({ type: 'NOTIFICATION_LOADED', payload })
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const getMyNotification = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'user/mynotification/' + payload.id, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      dispatch({ type: 'NOTIFICATION_LOADED', payload })
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const getMyActivity = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'user/myactivity/' + payload.id, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const getMyTask = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'user/mytask/' + payload.id, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const readNotification = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  let ids = payload.ids;
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'user/readnotification', {
      ids: payload.ids
    }, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      dispatch({ type: 'NOTIFICATION_UPDATED', ids })
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}


export const readAllNotification = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'user/readallnotification', null, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      dispatch({ type: 'NOTIFICATION_UPDATED', ids: [] })
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const setpassword = (payload) => {
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'setpassword', {
      code: payload.code,
      email: payload.email,
      password: payload.password,
      c_password: payload.c_password,
    }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const forgotpassword = (payload) => {
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'forgotpassword', {
      email: payload.email,
    }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const register = (payload) => {
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'signup', {
      username: payload.username,
      fullname: payload.fullname,
      phone: payload.phone,
      email: payload.email,
      password: payload.password,
      c_password: payload.c_password
    }).then(function (response) {
      payload = response.data;
      if (response.data.code === 200) {
        payload = payload.data;
        // console.log(payload);
        // dispatch({ type: 'SET_LOGIN', payload })
        // if(response.data.user.id === 1 || response.data.user.id === 2){
        //   dispatch({ type: 'SET_LOGIN', payload })
        // }else{
        //   payload = {code:401, message: "Invalid credentials, please try again."}
        // }
      } else {
        // dispatch({ type: 'SET_LOGOUT', payload })
      }
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const signinWithGoogle = (payload) => {
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'signin/google', {
      credential: payload.credential
    }).then(function (response) {
      payload = response.data;
      console.log(payload);

      if (response.data.code === 200) {
        payload = payload.data;
        // console.log(payload);

        dispatch({ type: 'SET_LOGIN', payload })

        // if(response.data.user.id === 1 || response.data.user.id === 2){
        //   dispatch({ type: 'SET_LOGIN', payload })
        // }else{
        //   payload = {code:401, message: "Invalid credentials, please try again."}
        // }
      } else {
        dispatch({ type: 'SET_LOGOUT', payload })
      }
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const login = (payload) => {
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'signin', {
      email: payload.email,
      password: payload.password
    }).then(function (response) {
      payload = response.data;
      if (response.data.code === 200) {
        payload = payload.data;
        // console.log(payload);

        dispatch({ type: 'SET_LOGIN', payload })

        // if(response.data.user.id === 1 || response.data.user.id === 2){
        //   dispatch({ type: 'SET_LOGIN', payload })
        // }else{
        //   payload = {code:401, message: "Invalid credentials, please try again."}
        // }
      } else {
        dispatch({ type: 'SET_LOGOUT', payload })
      }
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const checkLogin = (...payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'user/me', null, { headers: { Authorization: tkk }, validateStatus: () => true })
      .then(function (response) {
        payload = response.data;

        if (payload.code === 200) {
          payload = payload.data;
          payload.code = 200;
          payload.access_token = window.localStorage.getItem('app_tt');

          dispatch({ type: 'SET_LOGIN', payload })
        } else {
          dispatch({ type: 'SET_LOGOUT', payload })
          payload = { code: 401, message: "Invalid credentials, please try again." }
        }
        // if(response.data.code === 200){
        //   if(response.data.user.id === 1 || response.data.user.id === 2){
        //     payload.token = {access_token:window.localStorage.getItem('app_tt')};
        //     dispatch({ type: 'SET_LOGIN', payload })
        //   }else{
        //     console.log('111');
        //     dispatch({ type: 'SET_LOGOUT', payload })
        //     payload = {code:401, message: "Invalid credentials, please try again."}
        //   }
        // }else{
        //   console.log('222');
        //   dispatch({ type: 'SET_LOGOUT', payload })
        //   payload = {code:401, message: "Invalid credentials, please try again."}
        // }
        return payload;
      }).catch(function (error) {
        payload.error = error;
        console.log(error);
        // console.log('333');
        dispatch({ type: 'SET_LOGOUT', payload })
        return payload;
      })
    )
  }
}

export const forceLogout = (...payload) => {
  return (dispatch) => {
    dispatch({ type: 'SET_LOGOUT', payload })
    payload = { code: 200, message: "Success Logout" }
    return payload;
  }
}

export const addAddress = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'user/address', {
      name: payload.name,
      recipient_name: payload.recipient_name,
      phone: payload.phone,
      address: payload.address,
      notes: payload.notes,
      idprovince: payload.idprovince,
      province: payload.province,
      idcity: payload.idcity,
      city: payload.city,
      idsuburb: payload.idsuburb,
      suburb: payload.suburb,
      idarea: payload.idarea,
      area: payload.area,
      address_2: payload.address_2,
      postalcode: payload.postalcode,
    }, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const getAddress = (...payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'user/address', { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const getCart = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.get(BASE_URL + BASE_VERSION + 'cart/' + payload.url, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

export const addToCart = (payload) => {
  let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
  return (dispatch) => {
    return (axios.post(BASE_URL + BASE_VERSION + 'cart/' + payload.url, {
      idproduct: payload.idproduct,
      qty: payload.qty,
      is_variation: payload.is_variation,
      idvariation: payload.idvariation,
    }, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
      payload = response.data;
      return payload;
    }).catch(function (error) {
      payload.error = error;
      return payload;
    })
    )
  }
}

// export const getProvince = (...payload) => {
//   let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
//   return (dispatch) => {
//     return (axios.get(BASE_URL + BASE_VERSION + 'shipping/province', { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
//       payload = response.data;
//       return payload;
//     }).catch(function (error) {
//       payload.error = error;
//       return payload;
//     })
//     )
//   }
// }

// export const getCity = (payload) => {
//   let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
//   return (dispatch) => {
//     return (axios.get(BASE_URL + BASE_VERSION + 'shipping/city/' + payload.idprovince, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
//       payload = response.data;
//       return payload;
//     }).catch(function (error) {
//       payload.error = error;
//       return payload;
//     })
//     )
//   }
// }

// export const getSuburb = (payload) => {
//   let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
//   return (dispatch) => {
//     return (axios.get(BASE_URL + BASE_VERSION + 'shipping/suburb/' + payload.idcity, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
//       payload = response.data;
//       return payload;
//     }).catch(function (error) {
//       payload.error = error;
//       return payload;
//     })
//     )
//   }
// }

// export const getArea = (payload) => {
//   let tkk = 'Bearer ' + window.localStorage.getItem('app_tt');
//   return (dispatch) => {
//     return (axios.get(BASE_URL + BASE_VERSION + 'shipping/area/' + payload.idsuburb, { headers: { Authorization: tkk }, validateStatus: () => true }).then(function (response) {
//       payload = response.data;
//       return payload;
//     }).catch(function (error) {
//       payload.error = error;
//       return payload;
//     })
//     )
//   }
// }