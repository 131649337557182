import React, { Component } from 'react';
import { connect } from "react-redux";
import { getMyTask } from "../../../redux/actions/users";
import { updateTask } from "../../../redux/actions/tasks";
import { withRouter } from "react-router-dom";
import { Box, Select, MenuItem, Typography, Button, Modal, IconButton, Link } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';

class MyTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mytasks: [],
            aggregatedTask: [],
            err: null,
            open: false,
            selectedTask: null
        };
    }

    componentDidMount() {
        this.props.getMyTask({ id: this.props.organizationData.id }).then((res) => {
            if (res.code === 200) {
                let projectsArr = [];
                res.data.forEach((item, index) => {
                    if (index !== 0) {
                        if (item.project !== null) {
                            let projectsArrIdx = projectsArr.findIndex((obj) => obj.projectname === item.project.name);
                            if (projectsArrIdx === -1) {
                                projectsArr.push({
                                    projectname: item.project.name,
                                    task: [item]
                                });
                            } else {
                                projectsArr[projectsArrIdx].task = [...projectsArr[projectsArrIdx].task, item];
                            }
                        }
                    } else {
                        projectsArr.push({
                            projectname: item.project.name,
                            task: [item]
                        });
                    }
                });
                this.setState({ aggregatedTask: projectsArr, mytasks: res.data });
            } else {
                alert("Something Error");
            }
        });
    }

    handleOpen = (task) => {
        this.setState({ open: true, selectedTask: task });
    };

    handleClose = () => {
        this.setState({ open: false, selectedTask: null });
    };

    render() {
        const { open, selectedTask } = this.state;

        return (
            <Box className="panel home-panel">
                <Box sx={{ padding: "10px" }}>
                    <Typography sx={{ fontSize: "20px", fontWeight: "800", marginBottom: "8px" }}>My Task</Typography>
                    {this.state.aggregatedTask.length !== 0 ? (
                        <Box>
                            {this.state.aggregatedTask.map((item) => (
                                <Box key={item.projectname}>
                                    <Typography
                                        sx={{ fontWeight: "800", marginBottom: "5px", cursor: "pointer" }}
                                        onClick={() => {
                                            this.props.history.push("./p/" + item.task[0].project.uuid + "/board");
                                        }}
                                    >
                                        {item.projectname}
                                    </Typography>
                                    <Box>
                                        {item.task.map((task) => (
                                            <Box key={task.id} className="aggregated-task-item noselect">
                                                <Box className="aggregated-task-name noselect">
                                                    <Box>
                                                        {task.name}
                                                        {task.score !== 0 && (
                                                            <Typography
                                                                className='score-label'
                                                                style={{
                                                                    padding: '6px',
                                                                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                                                    display: 'inline-block',
                                                                    margin: '5px',
                                                                    borderRadius: '5px'
                                                                }}
                                                            >
                                                                <strong>Score point: {task.score}</strong>
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                    {task.task_priority !== null ? (
                                                        <Typography
                                                            className="aggregated-task-item-priority"
                                                            sx={{ backgroundColor: task?.task_priority?.color || "#fea12a" }}
                                                        >
                                                            Priority: {task.task_priority.name}
                                                        </Typography>
                                                    ) : ""}

                                                    <Link to="#" onClick={() => this.handleOpen(task)}>
                                                        Preview Task
                                                    </Link>
                                                </Box>
                                                <Select
                                                    sx={{ ".MuiSelect-select": { padding: "2px", paddingLeft: "14px", fontSize: "13px" } }}
                                                    size='small'
                                                    defaultValue={task?.project_section?.id}
                                                    onChange={(e) => {
                                                        this.props.updateTask({
                                                            id: parseInt(task.id),
                                                            idproject: task.project.id,
                                                            idsection: parseInt(e.target.value),
                                                            reorder: true
                                                        }).then((res) => {
                                                            console.log("updateTask");
                                                            console.log(res);
                                                            console.log(this.state.aggregatedTask);
                                                        }).catch((err) => {
                                                            console.log(err);
                                                        });
                                                    }}
                                                >
                                                    {task.project.sections.map((section) => (
                                                        <MenuItem key={section.id} className="aggregated-task-item-section" value={section.id}>
                                                            {section.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    ) : <>No Task Found</>}
                </Box>

                <Modal open={open} onClose={this.handleClose}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 4,
                    }}>
                        {selectedTask && (
                            <Box>
                                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                    {selectedTask.name}
                                </Typography>
                                <Box sx={{ display: 'flex', marginBottom: 2 }}>
                                    <Typography sx={{ marginBottom: 2, marginRight: 2 }}>
                                        <strong>Priority:</strong> {selectedTask.task_priority?.name}
                                    </Typography>
                                    <Typography sx={{ marginBottom: 2 }}>
                                        <strong>Score:</strong> {selectedTask.score}
                                    </Typography>
                                </Box>
                                <Typography sx={{ marginBottom: 2 }}>
                                    <strong>Description:</strong>
                                </Typography>
                                <Typography sx={{ marginBottom: 2 }} dangerouslySetInnerHTML={{ __html: selectedTask.description }} />
                            </Box>
                        )}
                    </Box>
                </Modal>

            </Box>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getMyTask: (data) => dispatch(getMyTask(data)),
    updateTask: (data) => dispatch(updateTask(data))
});

const mapStateToProps = (state) => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyTask));
