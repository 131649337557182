import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter,
    Redirect,
    Switch,
    Route,
    // Link,
    // NavLink
} from "react-router-dom";

// Card, Chip,
import { Box, Typography, CircularProgress } from '@mui/material';

import { getOrganization, getMyOrganizationRoles } from '../redux/actions/organizations';
import { getMyNotification } from "../redux/actions/users";

import SideMenu from '../components/sideMenu'

// import Home from './dashboard/home'
// import Sales from './dashboard/sales'
// import Artist from './dashboard/artist'
// import User from './dashboard/user'
// import Withdraw from './dashboard/withdraw'
// import Analytic from './dashboard/analytic'
// import Content from './dashboard/content'
import Setting from './dashboard/setting'
import Board from './dashboard/board'

// import AdminOrder from './dashboard/orders'
// import AddProduct from './dashboard/products/addProduct'
// import AllProduct from './dashboard/products/allProducts'

import Cart from './user/carts'
import Order from './user/orders'

import ListProduct from './user/products/listProduct'
import DetailProduct from './user/products/detailProduct';

import ListAddress from './user/addresses/listAddress'
import AddAddress from './user/addresses/addAddress';
import Checkout from './user/checkout';

import OrganizationListAddress from './dashboard/addresses/listAddress'
import OrganizationAddAddress from './dashboard/addresses/addAddress';
import AddProject from './dashboard/projects/addProject';

// import AllRules from './dashboard/members/allRules'
// import AddRule from './dashboard/members/addRule';
import AllMember from './dashboard/members/allMembers'
import AllRequest from './dashboard/members/allRequests'
import MemberActivity from './dashboard/members/activities'

import Knowledge from './dashboard/knowledge'

import Summary from './dashboard/summary'
import Activity from './dashboard/activity'
import MyTask from './dashboard/tasks/mytask'
import Notification from './dashboard/notification'

// import '../styles/login.css';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoaded: false,
            data: null,
            err: null
        };

        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.replace("/selectorganization", "urlhistory");
    }

    componentDidMount() {
        this.props.getMyOrganizationRoles({ url: this.props.match.params.url }).then((res) => {
            // console.log(res);
            // console.log("getMyOrganizationRoles");
        }).catch((err) => {
            console.log(err);
        })

        this.props.getOrganization({ url: this.props.match.params.url }).then((res) => {
            if (res.code === 200) {
                this.setState({ data: res.data });
                this.props.getMyNotification({ id: this.props.organizationData.id }).then((res) => {
                    // console.log("Notification")
                    // console.log(res.data);
                    // this.setState({ notifications: res.data });
                }).catch((err) => {
                    console.log(err);
                })
            } else if (res.code === 404) {
                this.setState({ err: 404 });
            } else if (res.code === 401) {
                this.setState({ err: 401 });
            }

            this.setState({ isLoaded: true });
        }).catch((err) => {
            console.log(err);
            this.setState({ isLoaded: true });
        })
    }

    render() {
        const { data, err, isLoaded, isLoading } = this.state;

        const url = this.props.match.params.url;
        const snapUrl = this.props.location.pathname.split('/')[2];

        if (isLoaded !== true) {
            return (
                <Box className='screen-loading'><CircularProgress /></Box>
            )
        } else {
            if (err !== null) {
                return (
                    <Box className='screen-error'>
                        <Box className='screen-error-container'>
                            <span className='error-title'>
                                {(err === 401) ? "Access Denied" : "Organization not Found"}
                            </span>
                            <span className='error-button' onClick={this.goBack}>Go Back</span>
                        </Box>
                    </Box>
                )
            } else {
                return (
                    <Box className='dashboard-container fullheight'>
                        {/* {(this.props.isLogin === true)?<Redirect to="/dashboard/home"></Redirect>:''} */}
                        {(snapUrl === "add-products") ?
                            "" : <Box className="side-left">
                                <SideMenu />
                            </Box>}
                        {(isLoading !== true) ?
                            <Box className="side-right">
                                <Switch>
                                    <Route path={`/${url}/home`}>
                                        <Summary data={this.state.data} />
                                    </Route>

                                    <Route path={`/${url}/knowledge`}>
                                        <Knowledge />
                                    </Route>                                    

                                    <Route path={`/${url}/add-project`}>
                                        <AddProject />
                                    </Route>

                                    <Route path={`/${url}/p/:slug/board`}>
                                        <Board />
                                    </Route>

                                    <Route path={`/${url}/mytask`}>
                                        <MyTask />
                                        {/* <>
                                            <Box sx={{ padding: "10px" }}>
                                                <Typography sx={{ fontSize: "20px", fontWeight: "800" }}>Tasks</Typography>
                                            </Box>
                                            <Box>
                                                {this.props.userData?.tasks.map((item) => {
                                                    return (
                                                        <Card key={item.id} elevation={2} sx={{ margin: "5px", padding: "10px" }}>
                                                            <Typography>{item.name}</Typography>
                                                            {item.project ?
                                                                <Box sx={{ fontSize: "13px" }}>
                                                                    <Box>Project : {item.project?.name}</Box>
                                                                    <Chip sx={{ marginTop: "5px", marginBottom: "5px" }} size="small" label={<Typography sx={{ fontSize: "12px" }} >{item.project_section?.name}</Typography>} />
                                                                    {item.project_section !== null ?
                                                                        ""
                                                                        : ""}</Box>
                                                                : ""}
                                                            {item.priority ?
                                                                <Typography sx={{
                                                                    backgroundColor: item.task_priority.color,
                                                                    color: "#fff",
                                                                    width: "fit-content",
                                                                    borderRadius: "10px",
                                                                    paddingTop: "2px",
                                                                    paddingBottom: "2px",
                                                                    paddingLeft: "10px",
                                                                    paddingRight: "10px",
                                                                    fontSize: "12px"
                                                                }}>Priority : {item.task_priority.name}</Typography> : ""}
                                                            {item.due_date ?
                                                                <Typography>Due Date : {item.due_date}</Typography> : ""}
                                                        </Card>
                                                    )
                                                })}
                                            </Box>
                                        </> */}
                                    </Route>
                                    <Route path={`/${url}/notification`}>
                                        <Notification />
                                        {/* <>
                                            <Box sx={{ padding: "10px" }}>
                                                <Typography sx={{ fontSize: "20px", fontWeight: "800" }}>Activities</Typography>
                                            </Box>
                                        </> */}
                                    </Route>
                                    <Route path={`/${url}/activity`}>
                                        <Activity />
                                        {/* <>
                                            <Box sx={{ padding: "10px" }}>
                                                <Typography sx={{ fontSize: "20px", fontWeight: "800" }}>Activities</Typography>
                                            </Box>
                                        </> */}
                                    </Route>
                                    <Route path={`/${url}/all-projects`}>
                                        <>
                                            <Box sx={{ padding: "10px" }}>
                                                <Typography sx={{ fontSize: "20px", fontWeight: "800" }}>All Projects</Typography>
                                            </Box>
                                        </>
                                    </Route>
                                    <Route path={`/${url}/all-members`}>
                                        <AllMember />
                                    </Route>
                                    <Route path={`/${url}/activity-members`}>
                                        <MemberActivity />
                                    </Route>
                                    <Route path={`/${url}/setting`}>
                                        <Setting />
                                    </Route>

                                    {(data?.roles?.roles === "admin") ? <>
                                        <Route path={`/${url}/add-member`}>
                                            <>Add Members</>
                                        </Route>
                                        <Route path={`/${url}/waiting-members`}>
                                            <AllRequest />
                                        </Route>
                                        <Route path={`/${url}/roles`}>
                                            <>Roles Members</>
                                        </Route>
                                        {/* <Route path={`/${url}/all-rules`}>
                                            <AllRules />
                                        </Route> */}
                                        {/* <Route path={`/${url}/add-rule`}>
                                            <AddRule />
                                        </Route> */}
                                        <Route path={`/${url}/regions`}>
                                            <>Regions Members</>
                                        </Route>
                                        <Route path={`/${url}/feeds`}>
                                            <>Feeds</>
                                        </Route>
                                        <Route path={`/${url}/forums`}>
                                            <>Forums</>
                                        </Route>
                                        <Route path={`/${url}/mediacenter`}>
                                            <>Media Center</>
                                        </Route>
                                        <Route path={`/${url}/statistics`}>
                                            <>Statistics</>
                                        </Route>
                                        <Route path={`/${url}/reports`}>
                                            <>Reports</>
                                        </Route>
                                        <Route path={`/${url}/businessintelegent`}>
                                            <>Business Intelegent</>
                                        </Route>
                                        <Route path={`/${url}/address`}>
                                            <OrganizationListAddress />
                                        </Route>
                                        <Route path={`/${url}/add-address`}>
                                            <OrganizationAddAddress />
                                        </Route>
                                        {/* brand owner */}
                                    </>
                                        : <>
                                            {/* reseller */}
                                            <Route path={`/${url}/carts`}>
                                                <Cart />
                                            </Route>
                                            <Route path={`/${url}/product/:slug`}>
                                                <DetailProduct />
                                            </Route>
                                            <Route path={`/${url}/products`}>
                                                <ListProduct />
                                            </Route>
                                            <Route path={`/${url}/orders`}>
                                                <Order />
                                            </Route>
                                            <Route path={`/${url}/checkout`}>
                                                <Checkout />
                                            </Route>
                                            <Route path={`/${url}/address`}>
                                                <ListAddress />
                                            </Route>
                                            <Route path={`/${url}/add-address`}>
                                                <AddAddress />
                                            </Route>
                                            {/* reseller */}
                                        </>
                                    }
                                    <Route path={`/${url}/logout`}>
                                        <>Logout</>
                                    </Route>

                                    <Route exact path={`${url}`}>
                                        <Redirect to={`${url}/home`} />
                                    </Route>
                                </Switch>
                            </Box> : 'Loading'}
                    </Box>
                )
            }
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOrganization: data => dispatch(getOrganization(data)),
        getMyOrganizationRoles: data => dispatch(getMyOrganizationRoles(data)),
        getMyNotification: data => dispatch(getMyNotification(data)),
        // getProvince: data => dispatch(getProvince(data)),
        // getDashboard: data => dispatch(getDashboard(data)),
        // getSales: data => dispatch(getSales(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));