import React, { Component } from 'react';
import { connect } from "react-redux";

import { getMyReport } from "../../redux/actions/users";

import {
    withRouter
} from "react-router-dom";

import {
    Box,
    // Card,
    // Chip,
    // Button,
    // Select,
    // Checkbox,
    // MenuItem,
    // TextField,
    Typography,
    // FormControlLabel
} from '@mui/material';

// import { format } from "date-fns";
import { LineChart, Line, Tooltip, Legend, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';

class Summary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // account_type:0,
            // mobileMenu:false,
            // logoIcon:'',
            data: null,
            err: null
        };

        // this.onLogout = this.onLogout.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // onLogout(params) {
    //     console.log('onLogout');
    //     if (this.props.forceLogout().status === 200) {
    //         this.props.history.push("/");
    //     }
    // }

    // toggleMenu(params) { }

    componentDidMount() {
        this.props.getMyReport({ id: this.props.organizationData.id }).then((res) => {
            // console.log('summary mounted');
            // console.log(res);
            this.setState({ data: res.data });
        }).catch((err) => {
            console.log(err);
        })
    }

    render() {
        return (
            <Box sx={{ padding: "10px" }}>
                <Typography sx={{ fontSize: "20px", fontWeight: "800" }}>Home</Typography>
                <Typography>Welcome {this.props.userData?.fullname} - {this.props.userData?.email}</Typography>
                <Typography>As {this.props.data?.roles?.roles}</Typography>

                {/* <Box className="panel home-panel" >
                    <h1>Summary</h1>
                </Box> */}
                <Box sx={{ width: "100%", height: "350px", paddingBottom: "50px", marginTop: "20px" }}>
                    <ResponsiveContainer>
                        <LineChart
                            width={800}
                            height={300}
                            data={this.state.data}
                            margin={{ top: 5, right: 100, bottom: 5, left: -20, marginLeft: "auto", marginRight: "auto" }}
                        >
                            {/* <Line type="monotone" strokeWidth={2} dataKey="count" stroke="#8884d8" /> */}
                            <Line type="monotone" strokeWidth={2} dataKey="finish" stroke="#007600" />
                            <Line type="monotone" strokeWidth={2} dataKey="done" stroke="#0000ff" />
                            <Line type="monotone" strokeWidth={2} dataKey="redoing" stroke="#909009" />
                            <Line type="monotone" strokeWidth={2} dataKey="start" stroke="#f17600" />
                            <Line type="monotone" strokeWidth={2} dataKey="create" stroke="#ff0090" />
                            <Line type="monotone" strokeWidth={2} dataKey="hold" stroke="#000000" />
                            <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
                            <Tooltip />
                            <Legend wrapperStyle={{ position: "relative", right: "0px", marginLeft: "auto", marginRight: "auto" }} />
                            <XAxis dataKey="label" />
                            <YAxis yAxisId="left" />
                            {/* <YAxis yAxisId="right" orientation="right" /> */}
                            {/* <XAxis dataKey="finish" /> */}
                            <YAxis />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
                {/* 
                <LineChart width={600} height={300} data={this.state.data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                    <Line type="monotone" dataKey="finish" stroke="#8884d8" />
                    <Line type="monotone" dataKey="done" stroke="#8884d8" />
                    <Line type="monotone" dataKey="create" stroke="#8884d8" />
                    <Line type="monotone" dataKey="start" stroke="#8884d8" />
                    <Line type="monotone" dataKey="hold" stroke="#8884d8" />
                    <Line type="monotone" dataKey="redoing" stroke="#8884d8" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Tooltip />
                    <Legend />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <XAxis dataKey="finish" />
                    <YAxis />
                </LineChart> 
                */}
            </Box >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMyReport: data => dispatch(getMyReport(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Summary));