import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import {
    Box,
    Stack,
    Button,
    Checkbox,
    InputBase,
    TextField,
    InputLabel,
    IconButton
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

class InputSubtask extends Component {
    constructor(props) {
        super(props);
        this.ItemInput = React.createRef();
        this.newItemInput = React.createRef();
        this.state = {
            tmpAddSubtask: [], //{ id: 12, name: "aaa" }
            addSubtaskMode: false,
            tmpItemName: "",
            tmpItemStatus: false,
            tmpItemDueDate: null,
            err: null
        };
    }

    componentDidMount() {
        this.setState({ tmpAddSubtask: this.props.tmpAddSubtask })
    }

    render() {
        return (
            <Box>
                {/* sx={{ }} */}
                {/* marginTop: "25px", marginBottom: "20px"  */}
                <InputLabel className='form-label' htmlFor="input-transparent">Subtask</InputLabel>
                {this.state.tmpAddSubtask.length !== 0 ?
                    this.state.tmpAddSubtask.map((item, index) => {
                        return (
                            <Box key={item.name + index + index} sx={{ marginBottom: "20px" }}>
                                <Box className="subtask-header">
                                    <Box sx={{ width: "50%", fontSize: "14px", fontWeight: "800" }}>
                                        <InputBase inputRef={this.ItemInput} defaultValue={item.name} id="input-transparent" className="input-transparent" size="small" placeholder='Sub Task Name' fullWidth color='transparent'
                                            inputProps={{ "data-id": item.id || null, "data-idtask": item.idtask || null, style: { fontSize: "14px", padding: "5px", fontWeight: "800" } }}
                                            onChange={(e) => {
                                                let tmpSubtask = this.state.tmpAddSubtask;
                                                tmpSubtask[index].name = e.target.value;

                                                if (e.target.getAttribute("data-idtask") !== null) {
                                                    tmpSubtask[index].idsubtask = parseInt(e.target.getAttribute("data-id"));
                                                    tmpSubtask[index].id = parseInt(e.target.getAttribute("data-id"));
                                                    tmpSubtask[index].idtask = parseInt(e.target.getAttribute("data-idtask"));
                                                }

                                                this.setState({
                                                    tmpAddSubtask: tmpSubtask
                                                }, function () {
                                                    this.ItemInput.current.focus();
                                                })
                                            }}
                                        />
                                    </Box>
                                    {/* <Box sx={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({
                                            tmpAddSubtask: [],
                                            addSubtaskMode: false,
                                            tmpItemName: "",
                                            tmpItemStatus: false,
                                            tmpItemDueDate: null,
                                        })
                                    }}>Delete</Box> */}
                                </Box>

                                <Box>
                                    {this.state.tmpAddSubtask[index].items.map((item, indexItem) => {
                                        return (
                                            <Box key={item + indexItem} sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                                {/* // <Box key={item + indexItem}>{item?.name}</Box> */}
                                                <Checkbox checked={Boolean(item?.status)} onChange={(e, f) => {
                                                    let tmpSubtaskItem = this.state.tmpAddSubtask[index].items;
                                                    tmpSubtaskItem[indexItem].status = f

                                                    this.setState({
                                                        tmpAddSubtask: [
                                                            {
                                                                // id: this.state.tmpAddSubtask[index].id  || null,
                                                                // idtask: this.state.tmpAddSubtask[index].id  || null,
                                                                id: this.state.tmpAddSubtask[index].id || null,
                                                                idsubtask: this.state.tmpAddSubtask[index].idsubtask || null,
                                                                idtask: this.state.tmpAddSubtask[index].idtask || null,
                                                                name: this.state.tmpAddSubtask[index].name,
                                                                items: tmpSubtaskItem
                                                            }
                                                        ],
                                                        // addSubtaskMode: false,
                                                        // tmpItemName: "", tmpItemStatus: false, tmpItemDueDate: null
                                                    }, function () {
                                                        this.props.handleSaveButton(this.state.tmpAddSubtask)
                                                    })
                                                }} />
                                                <TextField value={item?.name} inputProps={{ style: { fontSize: "12px", outline: 0 } }} sx={{ padding: "2px", display: "block", outline: "none" }} fullWidth size='small' placeholder="Name"
                                                    onChange={(e) => {
                                                        let tmpSubtaskItem = this.state.tmpAddSubtask[index].items;
                                                        tmpSubtaskItem[indexItem].name = e.target.value

                                                        this.setState({
                                                            tmpAddSubtask: [
                                                                {
                                                                    id: this.state.tmpAddSubtask[index].id || null,
                                                                    idsubtask: this.state.tmpAddSubtask[index].idsubtask || null,
                                                                    idtask: this.state.tmpAddSubtask[index].idtask || null,
                                                                    name: this.state.tmpAddSubtask[index].name,
                                                                    items: tmpSubtaskItem,
                                                                    // id: this.state.tmpAddSubtask[index].id || null,
                                                                    // idtask: this.state.tmpAddSubtask[index].id || null,
                                                                }
                                                            ],
                                                        }, function () {
                                                            this.props.handleSaveButton(this.state.tmpAddSubtask)
                                                            // addSubtaskMode: false,
                                                            // tmpItemName: "", tmpItemStatus: false, tmpItemDueDate: null
                                                        })
                                                    }}
                                                />
                                                <IconButton data-indexitem={indexItem} color="secondary" aria-label="upload picture" component="label"
                                                    onClick={(e) => {
                                                        let indexItem = parseInt(e.currentTarget.getAttribute('data-indexitem'));
                                                        let tmpItems = this.state.tmpAddSubtask[index];
                                                        tmpItems.items.splice(indexItem, 1)

                                                        let tmpSubtaskItem = tmpItems.items;
                                                        this.setState({
                                                            tmpAddSubtask: [
                                                                {
                                                                    id: this.state.tmpAddSubtask[index].id || null,
                                                                    idsubtask: this.state.tmpAddSubtask[index].idsubtask || null,
                                                                    idtask: this.state.tmpAddSubtask[index].idtask || null,
                                                                    name: this.state.tmpAddSubtask[index].name,
                                                                    items: tmpSubtaskItem
                                                                }
                                                            ],
                                                            tmpItemName: "", tmpItemStatus: false, tmpItemDueDate: null
                                                        }, function () {
                                                            this.props.handleSaveButton(this.state.tmpAddSubtask);
                                                            // addSubtaskMode: false, 
                                                            // this.newItemInput.current.focus();
                                                        })
                                                    }}
                                                ><DeleteIcon /></IconButton>
                                                {/* <Button variant='contained' size='small' sx={{width:"50px"}} color='secondary'><DeleteIcon /></Button> */}
                                            </Box>
                                        )
                                    })}
                                </Box>

                                {!this.state.addSubtaskMode ?
                                    <Button variant="contained" size="small" color="secondary" sx={{ fontSize: "10px", padding: "4px", marginRight: "5px", marginTop: "0px" }} onClick={() => {
                                        this.setState({ addSubtaskMode: true }, function () {
                                            this.newItemInput.current.focus()
                                        });
                                    }
                                    }>Add Item</Button>
                                    :
                                    <Box>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox defaultChecked={false} onChange={(e, f) => {
                                                this.setState({
                                                    tmpItemStatus: f
                                                })
                                            }} />
                                            <TextField inputRef={this.newItemInput} value={this.state.tmpItemName} inputProps={{ style: { fontSize: "12px", outline: 0 } }} sx={{ padding: "2px", display: "block", marginBottom: "5px", outline: "none" }} fullWidth size='small' placeholder="Name"
                                                onChange={(e) => {
                                                    this.setState({
                                                        tmpItemName: e.target.value
                                                    })
                                                }}
                                            />
                                        </Box>
                                        <Stack direction={"row"} spacing={"5px"}>
                                            <Button disabled={this.state.tmpItemName !== "" ? false : true} variant="contained" size="small" onClick={() => {
                                                let tmpSubtaskItem = this.state.tmpAddSubtask[index].items;
                                                //harus
                                                tmpSubtaskItem.push({
                                                    idsubtask: this.state.tmpAddSubtask[index].id,
                                                    name: this.state.tmpItemName,
                                                    status: this.state.tmpItemStatus,
                                                    due_date: null
                                                })

                                                this.setState({
                                                    tmpAddSubtask: [
                                                        {
                                                            id: this.state.tmpAddSubtask[index].id || null,
                                                            idsubtask: this.state.tmpAddSubtask[index].idsubtask || null,
                                                            idtask: this.state.tmpAddSubtask[index].idtask || null,
                                                            name: this.state.tmpAddSubtask[index].name,
                                                            items: tmpSubtaskItem
                                                        }
                                                    ],
                                                    tmpItemName: "", tmpItemStatus: false, tmpItemDueDate: null
                                                }, function () {
                                                    this.props.handleSaveButton(this.state.tmpAddSubtask);
                                                    this.newItemInput.current.focus();
                                                })
                                            }}>Save</Button>
                                            <Button size="small" color='error' onClick={() => {
                                                this.setState({ addSubtaskMode: false });
                                            }}>Close</Button>
                                        </Stack>
                                    </Box>
                                }
                            </Box>
                        )
                    })
                    : <Button disableElevation color="action" variant="contained" size="small" sx={{ marginRight: "5px", marginTop: "0px" }} onClick={() => {
                        this.setState({
                            tmpAddSubtask: [...this.state.tmpAddSubtask, { name: "Checklist", items: [] }] //id: 0, 
                        }, function () {
                            this.ItemInput.current.focus();
                        })
                        // let tamie = Math.random() * 1000;
                    }
                    }>Add Subtask</Button>}
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InputSubtask));