import React, { Component } from 'react';
import { connect } from "react-redux";

// import { forceLogout } from "../../redux/actions/users";

import {
    withRouter
} from "react-router-dom";

import {
    Box,
    // Drawer,
    Button,
    // Avatar,
    Stack,
    // Select,
    // Checkbox,
    Menu,
    MenuItem,
    // TextField,
    // Typography,
    // FormControlLabel 
} from '@mui/material';

// import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

class LaneHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
            anchorEl: null,
            err: null
        };

        this.handleClose = this.handleClose.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    handleClose(params) {
        console.log('handleClose');
        this.setState({ openMenu: false })
    }

    // toggleMenu(params){

    // }

    // componentDidMount(){
    //     console.log('header mounted');
    // }

    render() {
        return (
            <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between" }}>
                <Box>
                    <Box title={"Section title " + this.props.e.label} sx={{ fontWeight: "800" }}>{this.props.e.title}</Box>
                    <Box title={"Section type " + this.props.e.label} className="lane-label">{this.props.e.label}</Box>
                </Box>
                <Stack direction="row" spacing="2px">
                    {/* <Button onClick={(evt) => {
                        evt.preventDefault();
                        this.props.ctx.addNewCardForm(this.props.e.id);
                    }} sx={{ aspectRatio: "1/1", minWidth: "0px !important", padding: "5px" }} size="small" variant="outlined" color="secondary"><AddIcon sx={{ fontSize: "18px", fontWeight: "800" }} /></Button> */}
                    <Button onClick={(evt) => {
                        this.setState({ openMenu:true, anchorEl: evt.currentTarget });
                    }} sx={{ aspectRatio: "1/1", minWidth: "0px !important", padding: "5px" }} size="small" variant="outlined" color="secondary"><MoreHorizIcon sx={{ fontSize: "18px", fontWeight: "800" }} /></Button>

                    <Menu
                        id="basic-menu"
                        anchorEl={this.state.anchorEl}
                        open={this.state.openMenu}
                        onClose={this.handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={()=>{
                            console.log("Edit Lane");
                            this.setState({ openMenu:false });
                            console.log("Lane ID",this.props.e.id);
                            
                            this.props.openEditLane(this.props.e.id);
                        }}>Edit</MenuItem>
                        <MenuItem onClick={()=>{
                            console.log("Delete Lane");
                            this.setState({ openMenu:false });
                            console.log("Lane ID",this.props.e.id);
                            this.props.handleDeleteButton(this.props.e.id);
                        }}>Delete</MenuItem>
                    </Menu>

                </Stack>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // forceLogout: data => dispatch(forceLogout(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LaneHeader));