import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkLogin } from "./redux/actions/users";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import CookieConsent from "react-cookie-consent";

import Header from "./components/header"

import About from "./pages/about"
import Login from "./pages/login"
import Landing from "./pages/landing"
import Register from "./pages/register"
import Dashboard from "./pages/dashboard"
import SetPassword from "./pages/setpassword"
import ForgotPassword from "./pages/forgotpassword"
import SelectOrganization from "./pages/selectOrganization"

import PrivacyPolicy from "./pages/privacyPolicy"
import TermsAndConditions from "./pages/termsAndConditions"

import CircularProgress from '@mui/material/CircularProgress';

// import Pusher from 'pusher-js';

import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";

import './index.css';
import './App.css';

// import { Typography } from '@mui/material';

const theme = createTheme({
  palette: {
    // primary: {
    //   main: purple[500],
    // },
    secondary: {
      main: '#4a4a4a',
    },
    action: {
      main: '#19d299',
      light: '#757ce8',
      dark: '#108561',
      contrastText: '#fff',
    },
    transparent: {
      main: 'transparent',
    },
  },
  components: {
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        padding: "40px",
        disableRipple: true,
      },
      styleOverrides: {
        // valueLabel: ({ ownerState, theme }) => ({
        //   //   ...(ownerState.orientation === 'vertical' && {
        //   backgroundColor: '#fff',
        //   color: theme.palette.grey[500],
        //   // }),
        // }),
      }
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      isLoaded: false,
      err: {
        message: '',
        code: 0,
      }
    };
  }

  componentDidMount() {
    // console.log("App.js mounted");
    // Pusher.log = (msg) => {
    //   console.log(msg);
    // };
    // const pusher = new Pusher("fe7141a6fd954dd984ba", {
    //   cluster: "ap1",
    // });
    // const channel = pusher.subscribe('user_1');
    // channel.bind('notification', function (data) {
    //   console.log(data);
    // });

    if (window.localStorage.getItem('app_tt') !== null) {
      this.props.checkLogin().then((res) => {
        // if(res.status === 200){          
        //   //console.log(this.props.isLogin);
        // }
        this.setState({ isLoaded: true });
      })
    } else {
      this.setState({ isLoaded: true });
    }
  }

  render() {
    if (this.state.isLoaded !== true) {
      return (
        <div className='screen-loading'><CircularProgress /></div>
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <div className='world-container'>
          <Router basename="/">
            <Header />
            <div className='body-container fullheight'>
              <Switch>
                <Route exact path="/login">
                  <Login></Login>
                </Route>
                <Route exact path="/register">
                  <Register></Register>
                </Route>
                <Route exact path="/forgotpassword">
                  <ForgotPassword />
                </Route>
                <Route exact path="/setpassword">
                  <SetPassword />
                </Route>

                <Route exact path="/about">
                  <About />
                </Route>
                <Route exact path="/privacy-policy">
                  <PrivacyPolicy />
                </Route>
                <Route exact path="/terms-and-conditions">
                  <TermsAndConditions />
                </Route>

                <PrivateRoute loginState={this.props.isLogin} path="/selectorganization">
                  <SelectOrganization></SelectOrganization>
                </PrivateRoute>
                <PrivateRoute loginState={this.props.isLogin} path="/:url">
                  <Dashboard />
                </PrivateRoute>
                <Route exact path="/">
                  <Landing />
                  {/* <>Landing Page</> */}
                </Route>
                {/* <Route>
                  <>Bor</>
                </Route> */}
              </Switch>
              <CookieConsent
                location="bottom"
                buttonText="Accept all cookies"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#000000", fontSize: "13px", display: "flex", alignItems: "center", flexDirection: "row" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px", padding: "10px", minWidth: "120px", borderRadius: "5px" }}
                expires={150}
              >
                <span>We use cookies to ensure you have the best experience on our site, to analyze traffic, and enhance our marketing activities.</span>
                <a href="./privacy-policy" alt="Privacy Policy Hasler" style={{
                  display: "inline-block",
                  marginLeft: "5px",
                  textDecoration: "underline"
                }}>Learn more</a>
                {/* This website uses cookies to enhance the user experience.{" "}
                <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
              </CookieConsent>
            </div>
            {/* <Footer /> */}
          </Router>
        </div >
      </ThemeProvider >
    );
  }
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (rest.loginState !== false) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = state => ({
  ...state
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    checkLogin
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App);