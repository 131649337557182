import React from 'react';
import { Chip } from "@mui/material";

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const fetchVariation = (payload) => {
    var tmpVar = '';
    payload.map((item, index) => {
        return (
            (index === 0) ? tmpVar += item.attribute : tmpVar += ", " + item.attribute
        )
    });
    return "Variation : " + tmpVar;
}

export const fetchVariationProductPrice = (payload) => {
    var min = Math.min(...payload.map(item => item.price));
    var max = Math.max(...payload.map(item => item.price));

    if (min === max) {
        return formatMoney(max);
    } else {
        return formatMoney(min) + " - " + formatMoney(max);
    }
}

export const formatMoney = (payload) => {
    return 'Rp' + payload.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");;
}

export const stock = (payload) => {
    if (payload === 0) {
        return <Chip size="small" color="error" label="Out of stock" />
    } else {
        return payload.toString() + " pcs";
    }
}