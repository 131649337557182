import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import React, { Component } from 'react';
import { connect } from "react-redux";

import {
    withRouter
} from "react-router-dom";

class Knowledge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // account_type:0,
            // mobileMenu:false,
            // logoIcon:'',
            err: null
        };

        // this.onLogout = this.onLogout.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // onLogout(params) {
    //     console.log('onLogout');
    //     if(this.props.forceLogout().status === 200){
    //         this.props.history.push("/");
    //     }
    // }

    // toggleMenu(params){

    // }

    // componentDidMount() {
    //     console.log('Landing');
    // }

    render() {
        return (
            <Box className="panel home-panel">
                <Box sx={{ padding: "10px" }}>
                    <Typography sx={{ fontSize: "20px", fontWeight: "800", marginBottom: "8px" }}>Knowledge</Typography>
                </Box>
                <Box sx={{ padding: "10px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                        <TextField type="area" placeholder='what do you thing?' multiline rows={2} sx={{ width: "100%" }}></TextField>
                        <Button variant="contained" color="primary" size="small" sx={{ width: "120px", marginTop: "10px" }}>Post!</Button>
                    </Box>
                    <Divider sx={{ marginTop: "10px", marginBottom: "20px" }} />
                    <Box>
                        <Box sx={{ display: "block", marginBottom: "10px" }}>
                            <Typography variant='h5'>Task Score <span aria-label="celebrations" role="img">🎉🎉🎉</span></Typography>
                            <Typography variant='subtitle2' sx={{ marginTop: "0px", marginBottom: "10px" }}>13:00 Monday, 15th August 2024</Typography>
                            <Typography variant='body1'>Score untuk setiap task yang ada sudah ready, tinggalkan kosong untuk tanpa score atau isi angka untuk menerapkan score pada sebuah task.</Typography>
                            <Button color="secondary" size="small" sx={{ padding: "0px", marginTop: "10px" }} disabled>Read More</Button>
                            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                        </Box>
                        <Box sx={{ display: "block", marginBottom: "10px" }}>
                            <Typography variant='h5'>My Task Feature, and Task Label available! <span aria-label="celebrations" role="img">🎉🎉🎉</span></Typography>
                            <Typography variant='subtitle2' sx={{ marginTop: "0px", marginBottom: "10px" }}>13:00 Monday, 21st August 2023</Typography>
                            <Typography variant='body1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                            <Button color="secondary" size="small" sx={{ padding: "0px", marginTop: "10px" }} disabled>Read More</Button>
                            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                        </Box>
                        <Box sx={{ display: "block", marginBottom: "10px" }}>
                            <Typography variant='h5'>User Roles Setup <span aria-label="celebrations" role="img">🎉🎉🎉</span></Typography>
                            <Typography variant='subtitle2' sx={{ marginTop: "0px", marginBottom: "10px" }}>13:00 Monday, 21st August 2023</Typography>
                            <Typography variant='body1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                            <Button color="secondary" size="small" sx={{ padding: "0px", marginTop: "10px" }} disabled>Read More</Button>
                            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                        </Box>
                        <Box sx={{ display: "block", marginBottom: "10px" }}>
                            <Typography variant='h5'>Forgot Password Feature</Typography>
                            <Typography variant='subtitle2' sx={{ marginTop: "0px", marginBottom: "10px" }}>13:00 Monday, 21st August 2023</Typography>
                            <Typography variant='body1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                            <Button color="secondary" size="small" sx={{ padding: "0px", marginTop: "10px" }} disabled>Read More</Button>
                            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            // <Box sx={{ display: "flex", backgroundColor: "#c8ffc4" }}>
            //     <Box>
            //         <Typography variant="h3">Knowledge</Typography>
            //         <Typography variant="body1">
            //             Knowledge Hasler                        
            //         </Typography>
            //     </Box>
            // </Box >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // forceLogout: data => dispatch(forceLogout(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Knowledge));