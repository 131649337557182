import React, { Component } from 'react';
import { connect } from "react-redux";

import { getOrganizationMembers } from "../../../redux/actions/organizations";

import {
    withRouter
} from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
// import TablePagination from '@mui/material/TablePagination';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import InviteOrganization from '../../../components/modal/inviteOrganization';
import ChangeRole from '../../../components/modal/members/changeRole';
import DetailMember from '../../../components/modal/members/detailMember';
import DeleteMember from '../../../components/modal/members/deleteMember';

import { Box, Paper, Typography } from '@mui/material';

class AllMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIdUser: null,
            currentIdRole: null,
            organizationRoles: [],
            openInviteModal: false,
            openMenuAction: false,
            openRoleModel: false,
            openDeleteMember: false,
            openDetailMember: false,
            anchorEl: null,
            members: [],
            err: null
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu(params) {
        console.log(parseInt(params.currentTarget.getAttribute("data-iduser")));

        this.setState({ anchorEl: params.currentTarget, currentIdUser: parseInt(params.currentTarget.getAttribute("data-iduser")), currentIdRole: params.currentTarget.getAttribute("data-idrole") }, function () {
            this.setState({ openMenuAction: true })
        })
    }

    componentDidMount() {
        this.props.getOrganizationMembers({ id: this.props.organizationData.id }).then((res) => {
            this.setState({ members: res.data.users });
        }).catch((err) => {
            console.log(err);
        })
    }

    render() {
        return (
            <Box className="box-container">
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <Box sx={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box><Typography sx={{ fontSize: "20px", fontWeight: "800" }}>All Members</Typography></Box>
                        {(this.props.userDataRoles === "admin" || this.props.userDataRoles === "moderator") ? <Box><Button size="small" variant="contained" onClick={() => {
                            this.setState({ openInviteModal: true });
                        }}>Add Members</Button></Box> : ""}
                    </Box>
                    <TableContainer>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                            <TableHead>
                                <TableRow hover role="checkbox" tabIndex={-1} className="header-all-product-table">
                                    <TableCell component="th" align="center"><span style={{ color: "#fff" }}>No.</span></TableCell>
                                    <TableCell component="th" align="left"><span style={{ color: "#fff" }}>Name</span></TableCell>
                                    <TableCell component="th" align="left"><span style={{ color: "#fff" }}>Email</span></TableCell>
                                    <TableCell component="th" align="left"><span style={{ color: "#fff" }}>Project</span></TableCell>
                                    <TableCell component="th" align="center"><span style={{ color: "#fff" }}>Role</span></TableCell>
                                    <TableCell component="th" align="center"><span style={{ color: "#fff" }}>Action</span></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody className='body-all-product-table'>
                                {this.state.members.map((item, index) => {
                                    let odd = "odd";
                                    if (index % 2 === 0) {
                                        odd = "even"
                                    }

                                    return (
                                        <TableRow className={odd} key={item.id}>
                                            <TableCell align="center">{index + 1}</TableCell>
                                            <TableCell align="left">{item.fullname}</TableCell>
                                            <TableCell align="left">{item.email}</TableCell>
                                            <TableCell align="left">{item.projects.length > 0 ? item.projects.length + " Projects" : item.projects.length + " Project"}</TableCell>
                                            <TableCell align="center">{item.organization_user_roles.roles}</TableCell>
                                            <TableCell align="center">
                                                <Box>
                                                    <Button
                                                        // disabled
                                                        id="demo-positioned-button"
                                                        aria-controls={this.state.openMenuAction ? "demo-positioned-menu" : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={this.state.openMenuAction ? "true" : undefined}
                                                        data-iduser={item.id}
                                                        data-idrole={item.organization_user_roles.roles}
                                                        onClick={this.toggleMenu}
                                                        variant="contained"
                                                        label="Action"
                                                        size="small"
                                                    >
                                                        Action
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={this.state.anchorEl}
                        open={this.state.openMenuAction}
                        onClose={() => {
                            this.setState({ openMenuAction: false, anchorEl: null })
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box>
                            <MenuItem className='btn-detail-member' onClick={(e) => {
                                this.setState({ openDetailMember: true }, function () {
                                    this.setState({ openMenuAction: false })
                                })
                            }}>User Detail</MenuItem>
                            <MenuItem disabled={(this.props.userDataRoles === "admin" || this.props.userDataRoles === "moderator") ? false : true} className='btn-detail-member' onClick={(e) => {
                                this.setState({ openRoleModel: true }, function () {
                                    this.setState({ openMenuAction: false })
                                })
                            }}>Change Role</MenuItem>
                            <MenuItem disabled={(this.props.userDataRoles === "admin" || this.props.userDataRoles === "moderator") ? false : true} className='btn-detail-member' onClick={(e) => {
                                this.setState({ openDeleteMember: true }, function () {
                                    this.setState({ openMenuAction: false })
                                })
                            }}>Delete Member</MenuItem>
                        </Box>
                    </Menu>

                    {this.state.openRoleModel === true ?
                        <ChangeRole openModal={this.state.openRoleModel} currentIdUser={this.state.currentIdUser} currentIdRole={this.state.currentIdRole} data={this.state.organizationRoles} handleCloseModal={(e) => {
                            this.setState({ openRoleModel: false, currentIdUser: null, currentIdRole: null })
                        }} /> : ""}

                    <DeleteMember openModal={this.state.openDeleteMember} handleCloseModal={(e) => {
                        this.setState({ openDeleteMember: false })
                    }} />
                    {this.state.openDetailMember === true ?
                        <DetailMember openModal={this.state.openDetailMember} currentIdUser={this.state.currentIdUser} handleCloseModal={(e) => {
                            this.setState({ openDetailMember: false })
                        }} /> : ""}

                    {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.state.members.length}
                        rowsPerPage={10}
                        page={0}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page',
                            },
                            native: true,
                        }}
                        onPageChange={() => {
                            console.log("page change")
                        }}
                        onRowsPerPageChange={() => {
                            console.log("rows perpage change")
                        }}
                    /> */}
                </Paper>

                <InviteOrganization openInviteModal={this.state.openInviteModal} handleCloseModal={() => {
                    this.setState({ openInviteModal: false })
                }} />
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOrganizationMembers: data => dispatch(getOrganizationMembers(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllMember));