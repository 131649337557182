import React, { Component } from 'react';
import { connect } from "react-redux";

import { getProduct } from "../../../redux/actions/products";
import { addToCart } from "../../../redux/actions/users";
import { formatMoney, fetchVariation, fetchVariationProductPrice } from "../../../utils/format";

import {
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
// import Input from '@mui/material/Input';
// import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { Select, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

class ListProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product:null,
            productVariation:null,
            selectedVariationData:false,
            selectedVariationAttribute:"",
            selectedVariation:null,
            qty:1,
            err:null
        };

        this.addToCartLocal = this.addToCartLocal.bind(this);
        this.getDetailVariation = this.getDetailVariation.bind(this);
    }

    getDetailVariation(e){
        if(e !== null){
            let myIdx = this.state.productVariation.findIndex(x => x.id === e);
            return(this.state.productVariation[myIdx]);            
        }else{
            return false;
        }
    }

    addToCartLocal(params){
        params.url = this.props.organizationData.url;
        this.props.addToCart(params).then((res)=>{
            console.log(res);
            alert("Product added to cart");
        }).catch((err)=>{
            console.log(err);
        })
    }

    componentDidMount(){
        this.props.getProduct({url:"lelesegar", slug:this.props.match.params.slug}).then((res)=>{
            if(res.code === 200){
                this.setState({product:res.data, productVariation:res.data.variation});
            }else{
                alert("Error");
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    render() {
        const { product } = this.state;
        return (
            <Box className="products-container" sx={{padding:"10px"}}>
                <Box sx={{marginTop:"10px", marginBottom:"20px"}}>
                    <Typography className='title' sx={{fontSize:"18px", fontWeight:"800"}}>Detail Product</Typography>
                </Box>

                <Box component={"form"}>
                    <Typography>{product?.name || "Product Name"}</Typography>
                    <Typography>{product?.description || ""}</Typography>
                    <Typography>Berat : {product?.weight || ""} gram</Typography>
                    <Typography>Panjang : {product?.length || ""}cm | Lebar : {product?.width || ""}cm | Tinggi : {product?.height || ""}cm</Typography>
                    {(product?.is_variation)?
                    <>
                        <Typography>Harga : {fetchVariationProductPrice(product?.variation)}</Typography>
                        <Typography>Variasi : {fetchVariation(product?.variation)}</Typography>
                        <Box>
                            <Select 
                                sx={{width:"200px", display:"block"}} 
                                size="small" 
                                displayEmpty
                                value={this.state.selectedVariationAttribute} 
                                onChange={(item, data)=>{
                                    if(item.target.value !== ""){
                                        this.setState({selectedVariationAttribute:item.target.value, selectedVariationData:true});
                                    }else{
                                        this.setState({selectedVariationAttribute:"", selectedVariationData:false});
                                    }
                                }}
                            >
                                <MenuItem value="">Select Variation</MenuItem>                                
                                {product?.variation.map((variation)=>{
                                    return(
                                        <MenuItem key={variation.id} value={variation.id}>{variation.attribute}</MenuItem>
                                    )
                                })}
                            </Select>
                            {this.state.selectedVariationAttribute !== ""?
                            <Box>
                                <Typography>Available Stock : {this.getDetailVariation(this.state.selectedVariationAttribute)?.stock} pcs</Typography>
                                <Typography>Price : {this.getDetailVariation(this.state.selectedVariationAttribute)?.final_price}</Typography>
                            </Box>:""}
                            <TextField placeholder='Qty' onChange={(e)=>{
                                this.setState({qty:e.target.value});
                            }} type='number' variant='outlined' size='small' defaultValue={this.state.qty}></TextField>
                            <Button variant="contained" disabled={!this.state.selectedVariationData} onClick={(e)=>{
                                this.addToCartLocal({idproduct:product.id, is_variation:true, idvariation:this.state.selectedVariationAttribute, qty:parseInt(this.state.qty)});
                            }}>Add to Cart</Button>
                        </Box>
                    </>:
                    <>
                        <Typography>Stock : {product?.stock || "0"} pcs</Typography>                        
                        <Typography>Harga :&nbsp;
                        {(product?.is_sale)?
                            <><strike style={{fontSize:"14px"}}>{formatMoney(product?.price || "Loading...")}</strike> </>:""}
                            <span style={{fontSize:"14px"}}>{formatMoney(product?.final_price || "Loading...")}</span>
                        </Typography>
                        <Box>
                            <TextField sx={{width:"200px"}} onChange={(e)=>{
                                this.setState({qty:e.target.value});
                            }} placeholder='Qty' type='number' variant='outlined' size='small' defaultValue={this.state.qty} InputProps={{ inputProps: { type: 'number', min: 0, max: product?.stock } }}></TextField>
                            <Button variant="contained" onClick={(e)=>{
                                this.addToCartLocal({idproduct:product.id, is_variation:false, idvariation:null, qty:parseInt(this.state.qty)});
                            }}>Add to Cart</Button>
                        </Box>
                    </>
                    }
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProduct: data => dispatch(getProduct(data)),
        addToCart: data => dispatch(addToCart(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListProduct));