import { Box, Typography } from '@mui/material';
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { forceLogout } from "../../redux/actions/users";

import {
    withRouter
} from "react-router-dom";

// import backgroundLeft from '../assets/velizar-ivanov-xZjiUR7SWIQ-unsplash.jpg';
import backgroundLeft from '../assets/annie-spratt-dWYU3i-mqEo-unsplash.jpg';

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // account_type:0,
            // mobileMenu:false,
            // logoIcon:'',
            err: null
        };

        // this.onLogout = this.onLogout.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // onLogout(params) {
    //     console.log('onLogout');
    //     if(this.props.forceLogout().status === 200){
    //         this.props.history.push("/");
    //     }
    // }

    // toggleMenu(params){

    // }

    componentDidMount() {
        console.log('Landing');
    }

    render() {
        return (
            <Box sx={{
                display: "flex", backgroundColor: "#c8ffc4", height: "calc(100vh - 50px)"
            }}>
                <Box className="landingBG" sx={{
                    width: "100%",
                    backgroundImage: "url(" + backgroundLeft + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                }}>
                    {/* <img src={backgroundLeft} style={{width:"100%"}}/> */}
                    <Typography className="noselect" sx={{ fontSize: "5em", fontWeight: "800", zIndex: 1, color: "#fff" }}>HASLER</Typography>
                    {/* textShadow: "2px 2px 3px #fff"  */}
                    {/* <Typography className="noselect" sx={{ fontSize: "14px", textShadow: "1px 1px 3px #fff" }}>Manage project menjadi mudah!</Typography> */}
                </Box>
                {/* <Box sx={{ width: "50%" }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        height: "100%"
                    }}>
                        Kanan
                    </Box>
                </Box> */}
            </Box >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // forceLogout: data => dispatch(forceLogout(data))
        // logout: data => dispatch(logout(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Landing));