import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import {
    Box,
    // Drawer,
    Button,
    // Avatar,
    // Stack,
    Select,
    // Checkbox,
    MenuItem,
    TextField,
    Typography,
    // FormControlLabel
} from '@mui/material';

// import { format, intervalToDuration, formatDistanceToNow } from 'date-fns';

class NewLaneFormInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tmpCardForm: {
                id: "tmp-lane-id"
            },
            err: null
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        if (e.target.name !== "name") {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    [e.target.name]: e.target.value
                }
            });
        } else {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    [e.target.name]: e.target.value,
                    title: e.target.value,
                }
            });
        }
    }

    // componentDidMount() {
    //     console.log('component form mounted');
    // }

    render() {
        return (
            <Box className="section-newsection-form" sx={{ padding: "10px", width: "270px" }}>
                <Typography sx={{ marginBottom: "10px", fontWeight: "800" }}>Add Section</Typography>
                <TextField autoFocus value={this.state.tmpCardForm?.name || ""} onChange={this.handleChange} name="name" inputProps={{ style: { fontSize: "13px", outline: 0 } }} sx={{ display: "block", marginBottom: "5px", outline: "none" }} fullWidth size='small' placeholder="Name" />
                <Select
                    inputProps={{ style: { fontSize: "13px", outline: 0 } }}
                    sx={{ fontSize: "13px", marginBottom: "5px" }}
                    size="small"
                    name="type"
                    value={this.state.tmpCardForm?.type || 1}
                    displayEmpty
                    onChange={this.handleChange}
                >
                    <MenuItem sx={{ fontSize: "13px" }} value={1}>To Do</MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={2}>Doing</MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={3}>Done</MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={4}>Information</MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={5}>Goal</MenuItem>
                    <MenuItem sx={{ fontSize: "13px" }} value={6}>Archive</MenuItem>
                </Select>
                <TextField value={this.state.tmpCardForm?.description || ""} onChange={this.handleChange} multiline rows={2} name="description" inputProps={{ style: { fontSize: "13px", outline: 0 } }} sx={{ display: "block", marginBottom: "5px", outline: "none" }} fullWidth size='small' placeholder="Description" />
                <Box>
                    <Button variant="contained" size="small" sx={{ marginRight: "5px", marginTop: "10px" }} onClick={() => {
                        if (this.state.tmpCardForm?.name !== undefined) {
                            this.props.e.onAdd(this.state.tmpCardForm);
                        } else {
                            alert("Name cannot be empty");
                        }
                    }}>Add</Button>
                    <Button variant="contained" size="small" sx={{ marginRight: "5px", marginTop: "10px" }} onClick={() => {
                        this.props.e.onCancel()
                    }}>Cancel</Button>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewLaneFormInput));