import React, { Component } from 'react';
import { connect } from "react-redux";

import { getCart } from "../../redux/actions/users";
import { formatMoney } from "../../utils/format";

import {
    NavLink,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: null,
            subtotal:0,
            total:0,
            err: null
        };
    }

    componentDidMount() {
        this.props.getCart({ url: this.props.organizationData.url }).then(async (res) => {            
            let totalTmp = 0;        
            await Promise.all(
                res.data.products.map((item)=>{               
                    totalTmp += (item.products_detail.final_price * item.quantity)
                    console.log(item.products_detail.final_price)
                    return totalTmp;
                })
            )
            this.setState({ total:totalTmp, cart: res.data });
        }).catch((err) => {
            console.log(err);
        })
    }

    render() {
        return (
            <Box className="products-container" sx={{ padding: "10px" }}>
                <Box sx={{ marginTop: "10px", marginBottom: "20px" }}>
                    <Typography className='title' sx={{ fontSize: "20px", fontWeight: "800", textAlign: "center" }}>Cart</Typography>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        {this.state.cart?.products?.map((item, index) => {
                            console.log(item.products_detail);
                            return (
                                <Grid item xs={12} key={index} style={{ display: "block" }}>
                                    <Paper>
                                        <Typography>
                                            {item.products_detail.name}
                                            {(item.products_detail.is_variation)?<> - {item.products_detail?.variant?.attribute}</>:""}
                                        </Typography>
                                        <Typography>{formatMoney(item.products_detail.final_price)} x {item.quantity} = {formatMoney(item.products_detail.final_price * item.quantity)}</Typography>
                                    </Paper>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
                <Box sx={{ marginTop: "20px", textAlign: "center" }}>
                    <Typography>{formatMoney(this.state.total)}</Typography>
                    <Button variant="contained" size="medium" component={NavLink} to={"./checkout"}>Checkout</Button>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCart: data => dispatch(getCart(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));