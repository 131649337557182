import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

// task/comment
export const addComment = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    let data = {};
    data.comment = payload.comment;

    return axios
      .post(BASE_URL + BASE_VERSION + "task/comment/" + payload.id, data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const createSection = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    let data = {};
    data.name = payload.data.name;
    data.description = payload.data.description || null;
    data.type = payload.data.type || null; // 1 task | 2 story | 3 feature | 4 bug
    data.order = payload.data.order;

    return axios
      .post(
        BASE_URL +
          BASE_VERSION +
          "project/" +
          payload.url +
          "/" +
          payload.uuid +
          "/section",
        data,
        { headers: { Authorization: tkk }, validateStatus: () => true }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const updateSection = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    let data = {};
    data.name = payload.name || null;
    data.type = payload.type;

    if (payload.description !== undefined) {
      data.description = payload.description;
    } else {
      data.description = null;
    }

    return axios
      .put(
        BASE_URL +
          BASE_VERSION +
          "project/" +
          payload.url +
          "/" +
          payload.uuid +
          "/section/" +
          payload.id,
        data,
        { headers: { Authorization: tkk }, validateStatus: () => true }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const deleteSection = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");
    return axios
      .delete(
        BASE_URL +
          BASE_VERSION +
          "project/" +
          payload.url +
          "/" +
          payload.uuid +
          "/section/" +
          payload.id,
        { headers: { Authorization: tkk }, validateStatus: () => true }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const getDetailSection = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    return axios
      .get(BASE_URL + BASE_VERSION + "project/section/" + payload.id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const getDetailTask = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    return axios
      .get(BASE_URL + BASE_VERSION + "task/" + payload.id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const createTask = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    let data = {};
    data.idorganization = payload.idorganization;
    data.idproject = payload.idproject;
    data.idsection = payload.idsection;
    data.user_assignee = payload.assignee || null;
    data.user_reporter = payload.reporter || null;
    data.name = payload.name;
    data.description = payload.description || null;
    data.status = payload.status || null; // 0 not done | 1 done
    data.type = payload.type || null; // 1 task | 2 story | 3 feature | 4 bug
    data.priority = payload.priority || null; // null | 1 low | 2 mid | 3 high
    data.start_date = payload.start_date || null;
    data.due_date = payload.due_date || null;
    data.order = payload.order;

    if (payload.subtask !== undefined) {
      if (payload.subtask.length !== 0) {
        data.subtask = JSON.stringify(payload.subtask) || null;
      } else {
        data.subtask = null;
      }
    } else {
      data.subtask = null;
    }

    return axios
      .post(BASE_URL + BASE_VERSION + "task", data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const updateTask = (payload) => {
  console.log("updateTask");

  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    let data = new FormData();
    if (payload.idproject !== undefined)
      data.append("idproject", payload.idproject);
    if (payload.idsection !== undefined)
      data.append("idsection", payload.idsection);

    if (payload.assignee === "") {
    } else if (payload.assignee === undefined) {
      data.append("user_assignee", null);
    } else if (payload.assignee !== null) {
      data.append("user_assignee", payload.assignee);
    }

    if (payload.reporter === "") {
    } else if (payload.reporter === undefined) {
      data.append("user_reporter", null);
    } else if (payload.reporter !== null) {
      data.append("user_reporter", payload.reporter);
    }

    //change line
    if (payload.reorder === undefined) {
      if (payload.name !== undefined) data.append("name", payload.name);
      if (payload.description !== undefined) {
        if (payload.description !== null) {
          data.append("description", payload.description);
        }
      }

      if (payload.labels !== undefined)
        data.append(
          "labels",
          payload.labels !== "" ? JSON.stringify(payload.labels) : ""
        );

      if (payload.status !== undefined) data.append("status", payload.status); // 0 not done | 1 done
      if (payload.type !== undefined) data.append("type", payload.type); // 1 task | 2 story | 3 feature | 4 bug
      if (payload.priority !== undefined)
        data.append("priority", payload.priority); // null | 1 low | 2 mid | 3 high
      if (payload.order !== undefined) data.append("order", payload.order);
      console.log("SCORE!");
      console.log(payload.score);
      if (payload.score !== undefined) {
        if(payload.score === ""){
          data.append("score", 0)
        }else{
          data.append("score", payload.score)
        }
      }

      if (payload.start_date !== null) {
        data.append("start_date", payload.start_date);
      } else {
        data.append("start_date", null);
      }

      if (payload.due_date !== null) {
        data.append("due_date", payload.due_date);
      } else {
        data.append("due_date", null);
      }

      if (payload.files !== undefined) {
        if (payload.files?.length !== 0) {
          for (let i = 0; i < payload.files.length; i++) {
            data.append("attachments", payload.files[i].file);
          }
        }
      }

      if (payload.subtask !== undefined) {
        if (payload.subtask.length !== 0) {
          data.append("subtask", JSON.stringify(payload.subtask));
        }
      }
    }

    return axios
      .put(BASE_URL + BASE_VERSION + "task/" + payload.id, data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const reorderCard = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    return axios
      .post(
        BASE_URL +
          BASE_VERSION +
          "project/" +
          payload.url +
          "/" +
          payload.uuid +
          "/reorder",
        { data: JSON.stringify(payload.data) },
        { headers: { Authorization: tkk }, validateStatus: () => true }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const reorderTask = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");

    return axios
      .post(
        BASE_URL +
          BASE_VERSION +
          "project/" +
          payload.url +
          "/" +
          payload.uuid +
          "/section/reorder",
        { data: JSON.stringify(payload.data) },
        { headers: { Authorization: tkk }, validateStatus: () => true }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const deleteAttachment = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");
    return axios
      .delete(BASE_URL + BASE_VERSION + "task/attachment/" + payload.id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const deleteTask = (payload) => {
  return (dispatch) => {
    let tkk = "Bearer " + window.localStorage.getItem("app_tt");
    return axios
      .delete(BASE_URL + BASE_VERSION + "task/" + payload.id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        // console.log(payload);
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

// export const getProduct = (payload) => {
//   return (dispatch) => {
//     let tkk = 'Bearer '+window.localStorage.getItem('app_tt');

//     return( axios.get(BASE_URL+BASE_VERSION+'product/'+payload.url+"/"+payload.slug, {headers: { Authorization: tkk }, validateStatus: () => true})
//       .then(function (response) {
//         payload = response.data;
//         console.log(payload);
//         return payload;
//       }).catch(function (error) {
//         payload.error = error;
//         return payload;
//       })
//     )
//   }
// }

// export const createProduct = (payload) => {
//   return (dispatch) => {
//     let tkk = 'Bearer '+window.localStorage.getItem('app_tt');

//     console.log(payload.url);
//     console.log(payload.product);
//     console.log(payload.variations);

//     let product = payload.product;
//     let variations = payload.variations;

//     let data = new FormData();
//     data.append('sku', product.sku);
//     data.append('name', product.name);
//     if(product.shortDescription !== null){data.append('short_name', product.shortDescription)}
//     if(product.description !== null){data.append('description', product.description)}
//     data.append('is_variation', product.is_variation);
//     data.append('price', product.price || 0);
//     data.append('is_sale', product.is_sale);
//     data.append('sale_price', product.sale_price);
//     data.append('is_instock', product.is_instock);
//     data.append('stock', product.stock);
//     data.append('is_preorder', product.is_preorder);
//     data.append('preorder_days', product.preorder_days);
//     data.append('width', product.width);
//     data.append('height', product.height);
//     data.append('length', product.length);
//     data.append('weight', product.weight);

//     console.log(variations);

//     variations.map((item)=>(
//       data.append('variations[]',  JSON.stringify(item))
//     ))

//     // data.append('variations[]', '{"attribute":"Hemat 10","is_instock":false, "stock":10, "is_sale":false, "price":90000, "sale_price":0, "is_active":true, "is_preorder":false, "preorder_days":0}');
//     // data.append('variations[]', '{"attribute":"Hemat 100","is_instock":false, "stock":10, "is_sale":false, "price":90000, "sale_price":0, "is_active":true, "is_preorder":false, "preorder_days":0}');

//     console.log(data);

//     return( axios.post(BASE_URL+BASE_VERSION+'product/'+payload.url, data, {headers: { Authorization: tkk }, validateStatus: () => true}).then(function (response) {
//         payload = response.data;
//         console.log(payload);
//         return payload;
//       }).catch(function (error) {
//         payload.error = error;
//         return payload;
//       })
//     )
//   }
// }

// export const checkLogin = (...payload) => {
//   let tkk = 'Bearer '+window.localStorage.getItem('app_tt');
//   return (dispatch) => {
//       return( axios.post(BASE_URL+BASE_VERSION+'user/me', null, {headers: { Authorization: tkk }, validateStatus: () => true})
//       .then(function (response) {
//         payload = response.data;

//         if(payload.code === 200){
//           payload = payload.data;
//           payload.code = 200;
//           payload.access_token = window.localStorage.getItem('app_tt');

//           dispatch({ type: 'SET_LOGIN', payload })
//         }else{
//           dispatch({ type: 'SET_LOGOUT', payload })
//           payload = {code:401, message: "Invalid credentials, please try again."}
//         }
//         // if(response.data.code === 200){
//         //   if(response.data.user.id === 1 || response.data.user.id === 2){
//         //     payload.token = {access_token:window.localStorage.getItem('app_tt')};
//         //     dispatch({ type: 'SET_LOGIN', payload })
//         //   }else{
//         //     console.log('111');
//         //     dispatch({ type: 'SET_LOGOUT', payload })
//         //     payload = {code:401, message: "Invalid credentials, please try again."}
//         //   }
//         // }else{
//         //   console.log('222');
//         //   dispatch({ type: 'SET_LOGOUT', payload })
//         //   payload = {code:401, message: "Invalid credentials, please try again."}
//         // }
//         return payload;
//       }).catch(function (error) {
//         payload.error = error;
//         console.log(error);
//         console.log('333');
//         dispatch({ type: 'SET_LOGOUT', payload })
//         return payload;
//       })
//     )
//   }
// }

// export const forceLogout = (...payload) => {
//   return (dispatch) => {
//     dispatch({ type: 'SET_LOGOUT', payload })
//     payload = {code:200, message:"Success Logout"}
//     return payload;
//   }
// }
