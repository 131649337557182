import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Link,
    Redirect,
    withRouter,
} from "react-router-dom";

import { login, register, signinWithGoogle } from '../redux/actions/users'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

// import jwt_decode from "jwt-decode";
import { GoogleLogin } from '@react-oauth/google';

import '../styles/login.css';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            fullname: '',
            phone: null,
            email: '',
            password: '',
            c_password: '',
            isLoading: false,
            err: ''
        };

        this.onLogin = this.onLogin.bind(this);
        this.onRegister = this.onRegister.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // this.onLogout = this.onLogout.bind(this);        
    }

    onRegister(event) {
        this.setState({ isLoading: true, err: '' });
        event.preventDefault();
        console.log("Register");

        this.props.register({
            username: this.state.username,
            fullname: this.state.fullname,
            phone: this.state.phone,
            email: this.state.email,
            password: this.state.password,
            c_password: this.state.c_password
        }).then((res) => {
            if (res.code === 200) {
                console.log(res);
                // this.setState({ isLoading: false, err: res.message });
                alert("Register success");
            } else {
                console.log(res);
                this.setState({ isLoading: false, err: res.message });
            }
        }).catch((err) => {
            console.log(err);
            alert("Register error");
        })
    }

    onLogin(event) {
        this.setState({ isLoading: true, err: '' });
        event.preventDefault();

        const { email, password } = this.state;
        this.props.login({ email, password }).then((res) => {
            if (res.status === 200) {
                console.log(res.data);
            } else {
                this.setState({ isLoading: false, err: res.message });
            }
        })
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    render() {
        const { username, fullname, email, password, c_password, err, isLoading } = this.state;

        return (
            <Box className='login-container fullheight'>
                {(this.props.isLogin !== true) ? '' : <Redirect to="/selectorganization"></Redirect>}
                <Box className='login-paper verticalalign'>
                    <h1 style={{ marginTop: "15px" }}>Register</h1>
                    <Box sx={{ marginTop: "10px", marginBottom: "15px" }}>
                        <GoogleLogin
                            text="signup_with"
                            locale="en"
                            onSuccess={credentialResponse => {
                                this.props.signinWithGoogle({ credential: credentialResponse.credential }).then((res) => {
                                    console.log(res);
                                }).catch((err) => {
                                    console.log(err);
                                })
                            }}
                            onError={() => {
                                alert("Login with Google Failed");
                                console.log('Login Failed');
                            }}
                        />
                    </Box>

                    <Divider sx={{ width: "240px", marginBottom: "15px" }}>or</Divider>
                    <form onSubmit={this.onRegister}>
                        <span className="inputWrapper">
                            <label htmlFor="username">Username: </label>
                            <input autoComplete="username" required id="username" type="text" placeholder="username" value={username} onChange={this.handleChange}></input>
                        </span>
                        <span className="inputWrapper">
                            <label htmlFor="fullname">Full Name: </label>
                            <input autoComplete="full name" required id="fullname" type="text" placeholder="Full Name" value={fullname} onChange={this.handleChange}></input>
                        </span>
                        <span className="inputWrapper">
                            <label htmlFor="email">Email: </label>
                            <input autoComplete="email" required id="email" type="email" placeholder="email@email.com" value={email} onChange={this.handleChange}></input>
                        </span>
                        {/* <span className="inputWrapper">
                            <label htmlFor="phone">Phone: </label>
                            <input autoComplete="full name" required id="phone" type="phone" placeholder="+6282120911219" value={phone} onChange={this.handleChange}></input>
                        </span> */}
                        <span className="inputWrapper">
                            <label htmlFor="password">Password: </label>
                            <input autoComplete="current-password" required id="password" type="password" placeholder="password" value={password} onChange={this.handleChange}></input>
                        </span>
                        <span className="inputWrapper">
                            <label htmlFor="c_password">Password: </label>
                            <input autoComplete="confirm-password" required id="c_password" type="password" placeholder="confirm password" value={c_password} onChange={this.handleChange}></input>
                        </span>
                        <Box className="error-message">
                            {(err !== '') ? err : ''}
                        </Box>
                        {(isLoading === false) ? <span className="inputWrapper">
                            <Button type="submit" className="login-btn" variant="contained" color="primary">Register</Button>
                        </span> : <span className="loading-message">Please Wait</span>}
                    </form>
                    <Link style={{ cursor: "pointer", fontSize: "14px", marginTop: "10px", marginBottom: "10px" }} to="./login">Have an account? Login Here</Link>
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: userData => dispatch(login(userData)),
        register: userData => dispatch(register(userData)),
        signinWithGoogle: userData => dispatch(signinWithGoogle(userData)),
        
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));