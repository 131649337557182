import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    //Link,
    withRouter
} from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { getActivityProject } from "../../redux/actions/projects";

import { format } from "date-fns";

class PanelActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: [],
            formattedActivities: [],
            err: null
        };

        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    // toggleMenu(params){ }

    componentDidMount() {
        // console.log('Panel Activity mounted');
        // console.log(this.props.data);

        this.props.getActivityProject({ id: this.props.data.id }).then((res) => {
            // console.log(res);
            let lateDate = "";
            let groupDateArr = [];
            let tmpArr = [];
            
            // console.log(res.data);

            for (let i = 0; i < res.data.length; i++) {
                if (i === 0) {
                    tmpArr.push(res.data[i]);
                    lateDate = format(new Date(res.data[i]?.date || res.data[i]?.updated_at), "dd MMM yyyy");
                } else {
                    if (lateDate !== format(new Date(res.data[i]?.date || res.data[i]?.updated_at), "dd MMM yyyy")) {
                        groupDateArr.push(tmpArr);
                        tmpArr = []
                        lateDate = format(new Date(res.data[i]?.date || res.data[i]?.updated_at), "dd MMM yyyy");
                    } else {
                        tmpArr.push(res.data[i]);
                    }
                }
            }

            if (groupDateArr.length === 0) {
                groupDateArr.push(tmpArr);
            }

            this.setState({ formattedActivities: groupDateArr, activities: res.data });
        }).catch((err) => {
            console.log(err);
        })

        // this.props.getMyActivity().then((res) => {
        //     if (res.code === 200) {
        //         // this.setState({ activities: res.data }, function () {
        //         let lateDate = "";
        //         let groupDateArr = [];
        //         let tmpArr = [];
        //         for (let i = 0; i < res.data.length; i++) {
        //             if (i === 0) {
        //                 tmpArr.push(res.data[i]);
        //                 lateDate = format(new Date(res.data[i].date), "dd MMM yyyy");
        //             } else {
        //                 if (lateDate !== format(new Date(res.data[i].date), "dd MMM yyyy")) {
        //                     groupDateArr.push(tmpArr);
        //                     tmpArr = []
        //                     lateDate = format(new Date(res.data[i].date), "dd MMM yyyy");
        //                 } else {
        //                     tmpArr.push(res.data[i]);
        //                 }
        //             }
        //         }

        //         if(groupDateArr.length === 0){
        //             groupDateArr.push(tmpArr);
        //         }

        //         this.setState({ formattedActivities: groupDateArr })
        //         // });
        //     } else {
        //         alert("Error fetch activities")
        //     }
        // }).catch((err) => {
        //     console.log(err);
        // })
    }

    render() {
        return (
            <Box sx={{ padding: "10px", height: "calc(100vh - 170px)", overflowY: "scroll" }}>
                <Typography sx={{ fontWeight: "800", fontSize: "17px", color: "#414141", marginBottom: "10px" }}>Activity</Typography>

                {this.state.formattedActivities.map((item, index) => {
                    if (item.length !== 0) {
                        return (
                            <Box key={index}>
                                <Box sx={{ fontSize: "18px", fontWeight: "800", marginTop: "10px", marginBottom: "10px" }}>{format(new Date(item[0]?.date || item[0]?.updated_at), "EEEE, dd MMM yyyy")}</Box>
                                <Box>
                                    {item.map((child) => {
                                        return (
                                            <Box key={child._id} sx={{ height: "30px", display: "flex", alignItems: "center" }}>
                                                {format(new Date(child?.date || child?.updated_at), "HH:mm")} - {child.userDetail?.fullname} : {child.description}
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        )
                    } else {
                        return "";
                    }
                })}
                
                {/* {this.state.activities.length !== 0 ?
                    <Box>
                        {this.state.activities.map((item) => {
                            return (
                                <Box key={item._id} sx={{ height: "30px", display: "flex", alignItems: "center" }}>
                                    {format(new Date(item.date), "dd MMM yyyy HH:mm")} - {item.userDetail?.fullname} : {item.description}
                                </Box>
                            )
                        })}
                    </Box> : <Box>No Activities</Box>} */}
            </Box >
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getActivityProject: data => dispatch(getActivityProject(data)),
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelActivity));