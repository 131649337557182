import React, { Component } from 'react';
import { connect } from "react-redux";

import { createProject } from "../../../redux/actions/projects";

import {
    withRouter
} from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    Box,
    // Drawer,
    Button,
    // Avatar,
    // Stack,
    Select,
    Checkbox,
    MenuItem,
    TextField,
    Typography,
    FormControlLabel
} from '@mui/material';

import {
    format,
    // intervalToDuration,
    // formatDistanceToNow 
} from 'date-fns';

class AddProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tmpCardForm: null,
            err: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheck = this.handleChangeCheck.bind(this);
        this.handleDateChangeStartDate = this.handleDateChangeStartDate.bind(this);
        this.handleDateChangeDueDate = this.handleDateChangeDueDate.bind(this);
        this.createProjectLocal = this.createProjectLocal.bind(this);
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    createProjectLocal() {
        this.props.createProject({ data: this.state.tmpCardForm, url: this.props.organizationData.url }).then((res) => {
            if (res.code === 200) {
                alert("Create Project Success")
                this.props.history.replace("./p/"+res.data.uuid+"/board");
            } else {
                alert("Something Error")
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    handleChangeCheck(e, f, g, h) {
        let ctx = this;
        ctx.setState({
            tmpCardForm: {
                ...ctx.state.tmpCardForm,
                [e.target.name]: Boolean(f),
            }
        });
    }

    handleChange(e) {
        if (e.target.name !== "name") {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    [e.target.name]: e.target.value
                }
            });
        } else {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    [e.target.name]: e.target.value,
                    title: e.target.value,
                }
            });
        }
    }

    handleDateChangeStartDate(date, e, f, g) {
        if (date !== null) {
            let todayDate = new Date(date);
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    start_date: todayDate,
                    start_date_label: format(todayDate, "yyyy-MM-dd kk:mm:ss"),
                }
            });
        } else {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    start_date: null,
                    start_date_label: null,
                }
            });
        }
    }

    handleDateChangeDueDate(date, e, f, g) {
        if (date !== null) {
            let todayDate = new Date(date);
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    finish_date: todayDate,
                    finish_date_label: format(todayDate, "yyyy-MM-dd kk:mm:ss"),
                }
            });
        } else {
            this.setState({
                tmpCardForm: {
                    ...this.state.tmpCardForm,
                    finish_date: null,
                    finish_date_label: null,
                }
            });
        }
    }

    componentDidMount() {
        console.log('Add Project mounted');
        // setTimeout(() => {
        //     this.props.history.replace("./home");
        // }, 2000);
    }

    render() {
        return (
            <Box className="panel home-panel" sx={{ padding: "10px", height: "calc(100vh - 220px)" }}>
                <Typography sx={{ marginBottom: "10px", fontSize: "20px", fontWeight: "800" }}>Add Project</Typography>
                <Box>
                    <Select
                        inputProps={{ style: { fontSize: "13px", outline: 0 } }}
                        sx={{ fontSize: "13px", marginBottom: "5px" }}
                        size="small"
                        name="type"
                        value={this.state.tmpCardForm?.type || 1}
                        displayEmpty
                        onChange={this.handleChange}
                    >
                        <MenuItem sx={{ fontSize: "13px" }} value={1}>Public</MenuItem>
                        <MenuItem sx={{ fontSize: "13px" }} value={2}>Private</MenuItem>
                    </Select>
                    <TextField autoFocus value={this.state.tmpCardForm?.name || ""} onChange={this.handleChange} name="name" inputProps={{ style: { fontSize: "13px", outline: 0 } }} sx={{ display: "block", marginBottom: "5px", outline: "none" }} fullWidth size='small' placeholder="Name" />
                    <TextField value={this.state.tmpCardForm?.description || ""} onChange={this.handleChange} multiline rows={2} name="description" inputProps={{ style: { fontSize: "13px", outline: 0 } }} sx={{ display: "block", marginBottom: "5px", outline: "none" }} fullWidth size='small' placeholder="Description" />

                    <FormControlLabel
                        value={this.state.tmpCardForm?.use_finish_date || false}
                        control={<Checkbox
                            checked={this.state.tmpCardForm?.use_finish_date || false}
                            label="Set Due Date"
                            name="use_finish_date"
                            onChange={this.handleChangeCheck}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />}
                        label={<Typography sx={{ position: "relative", top: "1px" }} variant="body2" color="textSecondary">Use Date</Typography>}
                        labelPlacement="end"
                    />
                    {this.state.tmpCardForm?.use_finish_date ?
                        <>
                            <DatePicker
                                isClearable
                                id="task-datetime-picker"
                                className="task-datetime-picker start"
                                inputProps={{ style: { backgroundColor: "#00f", fontSize: "13px", outline: 0 } }}
                                style={{ padding: "10px", backgroundColor: "#f00" }}
                                selected={this.state.tmpCardForm?.start_date}
                                placeholderText="Click to select a start date"
                                timeInputLabel="Time : "
                                showTimeSelect
                                dateFormat="dd/MMM/yyyy kk:mm"
                                timeIntervals={60}
                                onChange={this.handleDateChangeStartDate}
                                name="start_date"
                            />
                            <DatePicker
                                isClearable
                                id="task-datetime-picker"
                                className="task-datetime-picker"
                                inputProps={{ style: { backgroundColor: "#00f", fontSize: "13px", outline: 0 } }}
                                style={{ padding: "10px", backgroundColor: "#f00" }}
                                selected={this.state.tmpCardForm?.finish_date}
                                placeholderText="Click to select a due date"
                                timeInputLabel="Time : "
                                showTimeSelect
                                dateFormat="dd/MMM/yyyy kk:mm"
                                timeIntervals={60}
                                onChange={this.handleDateChangeDueDate}
                                name="finish_date"
                            />
                        </>
                        : ""}
                </Box>
                <Box>
                    <Button variant="contained" size="small" sx={{ marginRight: "5px", marginTop: "10px" }} onClick={() => {
                        if (this.state.tmpCardForm?.name !== undefined) {
                            this.createProjectLocal();
                        } else {
                            alert("Name cannot be empty");
                        }
                    }}>Create Project</Button>
                    {/* <Button variant="contained" size="small" sx={{ marginRight: "5px", marginTop: "10px" }} onClick={(evt) => {
                        evt.preventDefault();
                        this.props.handleCloseNewCardForm();
                        this.props.e.onCancel();
                    }}>Cancel</Button> */}
                </Box>
            </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createProject: data => dispatch(createProject(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddProject));